/* eslint-disable react/display-name */
import React from "react";

type Props = object;

// const useGetRulerDimensions = () => {
//     const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

//     useEffect(() => {
//         const element = document.getElementById("ruler");
//         if (!element) return;
//         const onResize = () => {
//             setDimensions({ height: element.clientHeight, width: element.clientWidth });
//         };
//         onResize();
//         window.addEventListener("resize", onResize);
//         return () => {
//             window.removeEventListener("resize", onResize);
//         };
//     }, []);
//     return dimensions;
// };

export const Ruler = React.forwardRef<HTMLInputElement, Props>(() => {
    // const renderRuler = () => {
    //     const videoDuration = videoRef.current?.duration ?? 0;
    //     if ()
    // };

    return (
        <div id="ruler" className="media-controls__timeline__ruler">
            {/* {Array.from(new Array(89)).map((_, index) => {
                    return <div key={index} className="media-controls__timeline__ruler__dash"></div>;
                })} */}
        </div>
    );
});
