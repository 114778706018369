import { ActionButtons } from "./ActionButtons";
import { Audio } from "./Audio";
import { MediaData } from "./MediaData";
import { isMediaVideo, useMedia } from "../../../hooks/useMedia";

export const Controls = () => {
    const [media] = useMedia();

    if (!media.isLoading && !isMediaVideo(media)) return null;

    return (
        <div className="media-controls__controls">
            <Audio />
            <ActionButtons />
            <MediaData />
        </div>
    );
};
