import React, { CSSProperties } from "react";
import "../../static/scss/checkbox.scss";

export interface ICheckbox {
    isChecked?: boolean;
    onClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    labelText?: string;
    isRequired?: boolean;
    isDisabled?: boolean;
    customClass?: string;
    checkboxLabelStyle?: CSSProperties;
    checkMarkStyle?: CSSProperties;
    labelTextStyle?: CSSProperties;
    inputRef?: React.MutableRefObject<HTMLInputElement | null>;
}

export const Checkbox = ({
    isChecked,
    isDisabled,
    onClick,
    value,
    labelText,
    isRequired,
    customClass,
    checkboxLabelStyle,
    checkMarkStyle,
    labelTextStyle,
    inputRef
}: ICheckbox) => {
    return (
        <label
            htmlFor={`checkbox-${value}`}
            className={`checkbox-container ${isDisabled ? "disabled" : ""} ${customClass}`}
            style={checkboxLabelStyle}
        >
            <input
                id={`checkbox-${value}`}
                ref={inputRef}
                value={value}
                checked={isChecked}
                className="my-checkbox"
                type="checkbox"
                onChange={onClick}
                disabled={isDisabled}
            />
            <span className="checkmark" style={checkMarkStyle}></span>
            {labelText && (
                <span className="label-text" style={labelTextStyle}>
                    {labelText}
                    {isRequired && <sup>*</sup>}
                </span>
            )}
        </label>
    );
};
