import { getData } from "../services/axios.services";
import { GetMediaMetadataRequest, GetMediaMetadataResponse } from "../types";
import { getAuthCookie } from "./authApi";

export const getMediaMetadata = async (params: GetMediaMetadataRequest) => {
    return (
        await getData<GetMediaMetadataResponse>({
            url: `/storage/${params.filename}/media_infos`
        })
    ).data;
};

export const getStorageMediaUrl = (params: { filename: string }) => {
    const auth = getAuthCookie();
    if (!auth) throw new Error("Auth error");
    return `${process.env.REACT_APP_API_URL}/storage/${params.filename}?token=${auth.token}&timestamp=${new Date().toISOString()}`;
};
