import React, { useState, useEffect } from "react";

import { Form, Formik } from "formik";
import { withCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

import { setAuthCookie } from "../../../api/authApi";
import CustomButton from "../../../components/button/button";
import Input from "../../../components/input/Input";
import RoundedLoader from "../../../components/loader/RoundedLoaderAnimated";
import { getError } from "../../../services/errorsService";
import { useAxios } from "../../../services/hook/requestsService";
import { useQuery } from "../../../services/hook/useQuery";
import { isStrEmpty } from "../../../services/tools";

const initialValues = {
    password: "",
    passwordConfirmation: ""
};

const Instructions: React.FC<{ errors: any; values: any }> = ({ errors, values }) => {
    const { t } = useTranslation();

    const instructions = [
        "atLeast8Char",
        "atLeast1Lowercase",
        "atLeast1Uppercase",
        "atLeast1Number",
        "atList1SpecialChar",
        "passwordSimilar"
    ];

    const isValid = (instruction: string) => {
        if (
            Object.keys(errors).length === 0 &&
            isStrEmpty(values.password) &&
            isStrEmpty(values.passwordConfirmation)
        ) {
            return "invalid-text";
        }
        if (Object.keys(errors).length > 0) {
            const errorsPassword = JSON.parse(errors?.password);
            if (errorsPassword.includes(instruction)) {
                return "invalid-text";
            }
        }
        return "text-success";
    };

    return (
        <div id="validation-container" className="mb-3">
            {instructions.map((instruction, index) => (
                <p key={`${instruction}-${index}`} className={`mb-1 ${isValid(instruction)}`}>
                    {t(`register-password.instructionsPassword.${instruction}`)}
                </p>
            ))}
        </div>
    );
};

const RegisterPassword: React.FC = () => {
    const { t } = useTranslation();
    const { loading, getData, postData } = useAxios(); // attention regression token ?
    const [error, setError] = useState<boolean | string>(false);
    const [response, setResponse] = useState(null);
    const axiosActions = useAxios();
    const { push } = useHistory();

    //get code from url
    const query = useQuery();
    const code = query.get("code");

    useEffect(() => {
        if (code && !response) {
            (async () => {
                const { data, error } = await getData(null, "password_reset", {
                    params: { code }
                });
                setResponse(data);
                if (data) {
                    setError(false);
                } else {
                    if (error?.response?.status === 400) {
                        setError(t("register-password.error.tokenInvalid") as string);
                    } else {
                        setError(t("error.internalError") as string);
                    }
                }
            })();
        } else {
            //si pas de code set un token invalid
            setError(t("register-password.error.tokenInvalid") as string);
        }
        return () => {
            setResponse(null);
            setError(false);
        };
    }, [code, t]);

    //Check if password and password confirmation have the good validation rules
    const validationSchema = yup.lazy((values) => {
        return yup.object().shape({
            password: yup.mixed().test("password", "", function (value: any) {
                const errors = [];
                if (isStrEmpty(value) || value.length < 8) {
                    errors.push("atLeast8Char");
                }
                if (isStrEmpty(value) || !/\d/.test(value)) {
                    errors.push("atLeast1Number");
                }
                if (isStrEmpty(value) || !/[A-Z]/.test(value)) {
                    errors.push("atLeast1Uppercase");
                }
                if (isStrEmpty(value) || !/[a-z]/.test(value)) {
                    errors.push("atLeast1Lowercase");
                }
                if (isStrEmpty(value) || !/[#?!@$%^&*\-+().]/.test(value)) {
                    errors.push("atList1SpecialChar");
                }
                if (
                    isStrEmpty(values.passwordConfirmation) ||
                    isStrEmpty(values.password) ||
                    values?.passwordConfirmation !== values?.password
                ) {
                    errors.push("passwordSimilar");
                }
                return errors.length > 0
                    ? this.createError({
                          path: this.path,
                          message: JSON.stringify(errors)
                      })
                    : true;
            })
        });
    });

    //Check if the form is valid to disable or bot the submit button
    const isFormValid = (errors: any, values: any) =>
        Object.keys(errors).length > 0 ||
        isStrEmpty(values.password) ||
        isStrEmpty(values.passwordConfirmation);

    //Send new password to api
    const submitPassword = async ({
        password,
        passwordConfirmation
    }: {
        password: string;
        passwordConfirmation: string;
    }) => {
        try {
            const { data }: any = await postData(null, `password_reset/?code=${code}`, {
                password,
                passwordConfirmation
            });
            if (data) {
                setAuthCookie(data);

                const { data: client } = await axiosActions.getData(null, "client");
                if (client.licenses && client.licenses.length > 0) {
                    push("/plugin/ressources");
                } else {
                    push("/dashboard");
                }
            }
        } catch (e: any) {
            setError(e);
        }
    };

    if (loading && !error && !response) {
        return <RoundedLoader isLoading={loading} />;
    }

    return !error ? (
        <Formik
            initialValues={initialValues}
            onSubmit={submitPassword}
            validationSchema={validationSchema}
        >
            {({ values, handleChange, errors }) => {
                return (
                    <Form className="d-flex flex-column justify-content-between">
                        <Input
                            id="password"
                            name="password"
                            icon="password"
                            value={values.password}
                            placeholder={t("register-password.passwordPlaceholder")}
                            isRequired
                            onChange={handleChange as any}
                            inputType="password"
                            inputClassName="rounded"
                            labelTranslation={t("register-password.passwordLabel")}
                            maskable={true}
                        />
                        <Input
                            id="passwordConfirmation"
                            name="passwordConfirmation"
                            icon="password"
                            value={values.passwordConfirmation}
                            placeholder={t("register-password.passwordConfirmationPlaceholder")}
                            isRequired
                            onChange={handleChange as any}
                            inputType="password"
                            inputClassName="rounded"
                            labelTranslation={t("register-password.passwordConfirmationLabel")}
                            maskable={true}
                        />
                        {
                            <div className="d-flex justify-content-center mb-3">
                                {loading ? (
                                    <RoundedLoader isLoading={loading} />
                                ) : (
                                    <CustomButton
                                        disabled={isFormValid(errors, values)}
                                        buttonText={t("login.signIn")}
                                        classNameType="main"
                                    />
                                )}
                            </div>
                        }
                        <Instructions errors={errors} values={values} />
                    </Form>
                );
            }}
        </Formik>
    ) : (
        <div className="d-flex flex-column align-items-center">
            <div className="credential-error mb-4">
                {getError(error, t, t("register-password.error.tokenInvalid")).message}
            </div>
            <CustomButton
                onClick={() => push("/forgotten-password")}
                buttonText={t("register-password.error.goBackButton")}
                classNameType="main"
            />
        </div>
    );
};

export default withCookies(RegisterPassword) as any;
