import moment from "moment";

import { convertToMo, isObjectEmpty, convertToMb } from "./tools";

/**
 * returns activated services with this format:
 * @param clientServices
 * @param allServices
 * @returns
 * [
 *  {
 *    serviceAnagram: string,
 *    sdk: boolean,
 *    api: boolean,
 *    onPremise: boolean,
 *  }
 * ]
 */
export const formatActiveServices = (clientServices: any, allServices: any) => {
    let allServicesPerName: any[] = [];
    allServicesPerName = allServices.reduce((acc: any, current: any) => {
        const searchKeyAnagram = current.searchKey.split("_");

        const serviceKey = searchKeyAnagram[1] === "BLIT" ? "ANM" : searchKeyAnagram[1]; // wrong naming in base so we have to force rename client side

        if (!acc[serviceKey]) {
            acc[serviceKey] = {};
        }

        const obj = isObjectEmpty(acc[serviceKey])
            ? {
                  onPremise: false,
                  sdk: false,
                  api: false
              }
            : { ...acc[serviceKey] };

        const csWithSameSearchKey = clientServices.filter(
            (cs: any) => cs.service.searchKey === current.searchKey
        );

        if (csWithSameSearchKey.length > 0) {
            const srvExtension = searchKeyAnagram[2];
            if (
                srvExtension === "SDK" &&
                csWithSameSearchKey[0].service.searchKey.includes("SDK")
            ) {
                obj.sdk = true;
            }
            if (
                srvExtension === "PREMISE" &&
                csWithSameSearchKey[0].service.searchKey.includes("PREMISE")
            ) {
                obj.onPremise = true;
            }
            if (
                srvExtension &&
                srvExtension !== "SDK" &&
                srvExtension !== "PREMISE" &&
                csWithSameSearchKey[0].service.searchKey === "SRV_VHC_PDS_DETECT"
            ) {
                obj.api = true;
            }
            if (!srvExtension && csWithSameSearchKey[0].service.searchKey === current.searchKey) {
                obj.api = true;
            }
        }

        acc[serviceKey] = {
            serviceAnagram: serviceKey,
            ...obj
        };
        return acc;
    }, {});

    return Object.keys(allServicesPerName).map((srv: any) => ({ ...allServicesPerName[srv] }));
};

/**
 * returns data for consumptions and api calls in cards
 * @param thisDayData
 * @param thisMonthData
 * @param clientServices
 * @param selected
 */
export const formatApiCallsAndConsumption = (
    thisDayData: any,
    thisMonthData: any,
    clientServices: any,
    selected: any
) => {
    const totalConsumption = _getConsumption(thisDayData?.totalCounts || []);
    const thisDayConsumption = _getConsumption(thisDayData?.totalCountsPerPeriod || []);
    const thisMonthConsumption = _getConsumption(thisMonthData?.totalCountsPerPeriod || []);

    const isActive = clientServices.find((cs: any) => cs.service.searchKey === selected);
    return {
        isActive: isActive && selected !== "all" ? true : false,
        totalConsumption: convertToMo(totalConsumption.totalConsumption),
        totalConsumptionThisMonth: convertToMo(thisMonthConsumption.totalConsumption),
        totalConsumptionThisDay: convertToMo(thisDayConsumption.totalConsumption),
        totalCall: totalConsumption.totalApiCall,
        totalCallThisMonth: thisMonthConsumption.totalApiCall,
        totalCallThisDay: thisDayConsumption.totalApiCall
    };
};

export const formatTotalApiCallsAndConsumptionGraph = (data: any, year: any) => {
    const xAxisCategories = moment.months();
    const yearApiCalls: number[] = [];
    const yearConsumption: number[] = [];

    xAxisCategories.forEach((month: any) => {
        const graphMonthDate = moment(year).month(month).format("YYYY-MM");
        const dataThisMonth = data.filter((d: any) => d.date === graphMonthDate);
        let apiCallCount = 0;
        let sizeCount = 0;

        if (dataThisMonth.length > 0) {
            const { totalApiCall, totalConsumption } = _getConsumption(dataThisMonth);
            apiCallCount = totalApiCall;
            sizeCount = convertToMb(totalConsumption);
        }

        yearApiCalls.push(apiCallCount);
        yearConsumption.push(sizeCount);
    });

    return { yearApiCalls, yearConsumption };
};

export const formatDataPerMonthGraph = (data: any, currentYearAndMonth: string) => {
    const numberOfDays = moment(currentYearAndMonth).daysInMonth();
    const xAxisCategories = [];
    const monthConsumption: number[] = [];
    const monthApiCall: number[] = [];
    for (let i = 1; i <= numberOfDays; i++) {
        const currentDay = moment(currentYearAndMonth).date(i);
        let apiCallCount = 0;
        let sizeCount = 0;

        const dataThisDay = data?.filter((d: any) => d.date === currentDay.format("YYYY-MM-DD"));
        if (dataThisDay.length > 0) {
            const { totalApiCall, totalConsumption } = _getConsumption(dataThisDay);
            apiCallCount = totalApiCall;
            sizeCount = convertToMb(totalConsumption);
        }

        monthApiCall.push(sizeCount);
        monthConsumption.push(apiCallCount);
        xAxisCategories.push(currentDay.format("dddd").replace("day", ""));
    }
    return { monthConsumption, monthApiCall, xAxisCategories };
};

/********************************
 *      PRIVATE FUNCTIONS
 *******************************/
const _getConsumption = (totalCounts: any) => {
    let totalApiCall = 0;
    let totalConsumption = 0;

    totalCounts.forEach((elem: any) => {
        totalApiCall += parseInt(elem.totalCount);
        totalConsumption += parseInt(elem.size);
    });

    return { totalApiCall, totalConsumption };
};
