import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { getMediaMetadata } from "../../api";
import { GetMediaMetadataRequest, GetMediaMetadataResponse } from "../../types";

export const useGetMediaMetadata = (
    params: GetMediaMetadataRequest,
    enabled: boolean = false
): UseQueryResult<GetMediaMetadataResponse> => {
    return useQuery({
        queryKey: ["storageMediaMetadata", params],
        queryFn: () => getMediaMetadata(params),
        enabled,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true
    });
};
