import React from "react";

import { TFunction } from "i18next";
import moment from "moment";

import { DownloadButton } from "../utils/downloadButton";

export const formatTable = (t: TFunction, data: any[]) => {
    const baseName = "account-management.invoices.";

    const head = [
        {
            title: t(`${baseName}table-head.year`),
            type: "text",
            name: "year",
            style: {
                width: "20%"
            }
        },
        {
            title: t(`${baseName}table-head.month`),
            type: "text",
            name: "month",
            align: "left",
            style: {
                width: "25%"
            }
        },
        {
            title: t(`${baseName}table-head.document`),
            type: "text",
            name: "document",
            align: "left",
            style: {
                width: "45%"
            }
        },
        {
            style: {
                width: "10%"
            }
        }
    ];

    const body = data?.map((element) => {
        return {
            year: {
                value: moment(element.invoiceDate).year(),
                type: "text"
            },
            month: {
                value: moment(element.invoiceDate).month(element.invoiceDate).format("MMMM"),
                type: "text",
                align: "left"
            },
            document: {
                value:
                    element.originalName ||
                    `${element.invoiceDate}.${element.fileName.split(".").pop()}`,
                type: "text",
                align: "left"
            },
            invoice: {
                value: (
                    <DownloadButton
                        url={`${process.env.REACT_APP_API_URL}/invoice/${element.uuid}`}
                        filename={
                            element.originalName ||
                            `${element.invoiceDate}.${element.fileName.split(".").pop()}`
                        }
                        buttonType="image"
                    />
                ),
                type: "text",
                align: "right"
            }
        };
    });

    return {
        head,
        body
    };
};
