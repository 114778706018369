import { useRef } from "react";

import { ProgressBar } from "./ProgressBar";
import { Ruler } from "./Ruler";
import { TrackEditor } from "./TrackEditor";
import { MediaVideo } from "../../../hooks/useMedia";

export const Timeline: React.FC<{ media: MediaVideo }> = ({ media }) => {
    const progressBarRef = useRef<HTMLInputElement>(null!);

    return (
        <div className="media-controls__timeline">
            <Ruler />
            <div className="media-controls__timeline__sliders">
                <ProgressBar ref={progressBarRef} media={media} />
                <TrackEditor />
            </div>
        </div>
    );
};
