import React from "react";

// COMMON
import { CookiesProvider } from "react-cookie";
import { BrowserRouter, Route, RouteComponentProps, Switch } from "react-router-dom";

// LAYOUTS

import { Plugin } from "./pluginRouter";
import PrivateRoute from "./privateRoute";
import TaskManagerDetail from "../components/mobile/task-manager-detail/TaskManagerDetail";
import { TableProvider } from "../components/table/customTable/context/tableContext";
import { ClientServicesProvider } from "../context/clientServicesContext";
import { ConsumptionProvider } from "../context/consumptionContext";
import { ServicesProvider } from "../context/servicesContext";
import AccountLayout from "../layouts/accountLayout";
import AuthLayout from "../layouts/authLayout";
import ServicesManagementLayout from "../layouts/servicesManagementLayout";
import DashboardLayout from "../layouts/dashboardLayout";
// CONTEXTS
import { ServiceManagementProvider } from "../context/ServicesManagementContext";
import { DashboardProvider } from "../context/DashboardContext";
import { AuthLayoutProvider } from "../context/authLayoutContext";
import { socketContext, SocketProvider } from "../context/socketContext";

// ROUTING

/***********
 *  PAGES   *
 ***********/

// AUTH
import ForgottenPassword from "../pages/auth/forgottenPassword/forgottenPassword";
import Login from "../pages/auth/login/login";
import LoginPlugin from "../pages/auth/plugin/loginPlugin";
import RegisterPassword from "../pages/auth/registerPassword/registerPassword";

// PLUGIN
import LoginPluginSuccess from "../pages/auth/plugin/loginPluginSuccess";

// DASHBOARD
import Dashboard from "../pages/dashboard/dashboard";

// SERVICES MANAGEMENT
import { Developer } from "../pages/developer/developer";
import ServiceLayout from "../pages/servicesManagement/serviceLayout";

// ACCOUNT
import MyAccount from "../pages/account/myAccount";
import MyInvoices from "../pages/account/myInvoices";
import EditAccountFactory from "../pages/account/editAccountFactory";

// TASK MANAGER
import TaskManager from "../pages/taskManager/taksManager";
import { HistoryCallProvider } from "../context/historyCallContext";
import { TaskManagementProvider } from "../context/TaskManagementContext";
import TaskFactory from "../pages/taskManager/taskFactory";

// SCROLL TO TOP
import ScrollToTop from "../services/ScrollToTop";
import { Redirection } from "../pages/auth/redirection";
import { ClientProvider } from "../context/clientContext";
import { LicensesProvider } from "../context/licensesContext";
import PluginPageLayout from "../layouts/pluginPageLayout";
import { Studio } from "../pages/taskManager/pages/studio/Studio";
import { RefsProvider } from "../pages/taskManager/pages/studio/contexts/RefsContexts";
import { RecoilRoot } from "recoil";
import RootRedirect from "./rootRedirect";
import { PlansComponent } from "../pages/plans/plansComponent";
import { UpdateTrial } from "../pages/plans/updateTrial";

const Routes: React.FC = () => {
    return (
        <CookiesProvider>
            {/** @ts-ignore */}
            <BrowserRouter>
                <ScrollToTop />
                <ClientServicesProvider>
                    <ServicesProvider>
                        <ConsumptionProvider>
                            <SocketProvider>
                                <HistoryCallProvider>
                                    <Switch>
                                        <Route
                                            exact
                                            path="/login"
                                            component={(props: RouteComponentProps) => (
                                                <AuthLayoutProvider>
                                                    <AuthLayout {...props}>
                                                        <Login />
                                                    </AuthLayout>
                                                </AuthLayoutProvider>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/login/plugin"
                                            component={() => <LoginPlugin />}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/login/plugin/success"
                                            layout={null}
                                            component={() => <LoginPluginSuccess />}
                                        />
                                        <Route
                                            exact
                                            path="/redirection"
                                            component={() => <Redirection />}
                                        />
                                        <Route
                                            exact
                                            path="/forgotten-password"
                                            component={(props: RouteComponentProps) => (
                                                <AuthLayoutProvider>
                                                    <AuthLayout {...props}>
                                                        <ForgottenPassword />
                                                    </AuthLayout>
                                                </AuthLayoutProvider>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/register-password"
                                            component={(props: RouteComponentProps) => (
                                                <AuthLayoutProvider>
                                                    <AuthLayout {...props}>
                                                        <RegisterPassword />
                                                    </AuthLayout>
                                                </AuthLayoutProvider>
                                            )}
                                        />
                                        <Route exact path="/" component={RootRedirect} />
                                        {/* DASHBOARD */}
                                        <PrivateRoute
                                            exact
                                            path="/dashboard"
                                            providers={[ClientProvider, DashboardProvider]}
                                            component={(props: RouteComponentProps) => (
                                                <DashboardLayout {...props}>
                                                    {<Dashboard />}
                                                </DashboardLayout>
                                            )}
                                        />
                                        {/* ACCOUNT */}
                                        <PrivateRoute
                                            exact
                                            path={[
                                                "/account",
                                                "/account/update-payment-method-success"
                                            ]}
                                            component={(props: RouteComponentProps) => (
                                                <AccountLayout {...props}>
                                                    {<MyAccount />}
                                                </AccountLayout>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/account/invoice"
                                            providers={[ClientProvider, TableProvider]}
                                            component={(props: RouteComponentProps) => (
                                                <AccountLayout {...props}>
                                                    {<MyInvoices />}
                                                </AccountLayout>
                                            )}
                                        />
                                        <PrivateRoute
                                            path="/account/services"
                                            exact
                                            component={(props: RouteComponentProps) => (
                                                <AccountLayout {...props}>
                                                    {
                                                        <ServicesManagementLayout {...props}>
                                                            {<ServiceLayout />}
                                                        </ServicesManagementLayout>
                                                    }
                                                </AccountLayout>
                                            )}
                                            providers={[ClientProvider, ServiceManagementProvider]}
                                        />
                                        <PrivateRoute
                                            path="/account/services/:service"
                                            exact
                                            component={(props: RouteComponentProps) => (
                                                <AccountLayout {...props}>
                                                    {
                                                        <ServicesManagementLayout {...props}>
                                                            {<ServiceLayout />}
                                                        </ServicesManagementLayout>
                                                    }
                                                </AccountLayout>
                                            )}
                                            providers={[ClientProvider, ServiceManagementProvider]}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/account/edit/:editKey"
                                            component={EditAccountFactory}
                                        />
                                        {/* TASK MANAGER */}
                                        <PrivateRoute
                                            exact
                                            path="/task-manager"
                                            providers={[
                                                ClientProvider,
                                                TableProvider,
                                                TaskManagementProvider
                                            ]}
                                            component={TaskManager}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/task-manager/:searchKey"
                                            providers={[ClientProvider, TaskManagementProvider]}
                                            component={TaskFactory}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/task-manager/anonymization/edit/:jobId"
                                            render={() => (
                                                <RefsProvider>
                                                    <RecoilRoot override={true}>
                                                        <Studio />
                                                    </RecoilRoot>
                                                </RefsProvider>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/task-manager/:jobId/detail"
                                            providers={[ClientProvider, TaskManagementProvider]}
                                            component={TaskManagerDetail}
                                        />
                                        {/** PLANS */}
                                        <PrivateRoute
                                            exact
                                            path="/account/plan"
                                            component={PlansComponent}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/account/plan/trial"
                                            component={UpdateTrial}
                                        />
                                        {/** DEVELOPER */}
                                        <PrivateRoute
                                            exact
                                            path="/developer"
                                            component={Developer}
                                        />

                                        {/** PLUGIN NESTED ROUTER */}
                                        <PrivateRoute
                                            path="/plugin"
                                            component={Plugin}
                                            providers={[ClientProvider, LicensesProvider]}
                                            layout={PluginPageLayout}
                                        />
                                    </Switch>
                                </HistoryCallProvider>
                            </SocketProvider>
                        </ConsumptionProvider>
                    </ServicesProvider>
                </ClientServicesProvider>
            </BrowserRouter>
        </CookiesProvider>
    );
};

export default Routes;
