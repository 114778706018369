import React, { useEffect, useState } from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";

import { formatTable } from "./utils/tableService";
import FilterInvoices from "../../components/filter/filterInvoices";
import CustomTable from "../../components/table/customTable/CustomTable";
import useTable from "../../components/table/customTable/hook/useTable";
import Pagination from "../../components/table/customTable/pagination/Pagination";
import { useAxios } from "../../services/hook/requestsService";

type Option = {
    label: number | string;
    value: number | null;
};

type Invoice = {
    fileName: string;
    invoiceDate: string;
    originalName: string;
    uuid: string;
};

const MyInvoices = () => {
    const { t } = useTranslation();
    const { initTable } = useTable();
    const { getData } = useAxios();
    const [paginationState, handlePaginationState] = useState({
        page: 1,
        elementsPerPage: 10
    });
    const [sortState, setSortState] = useState<{ sortBy: string; sortOrder: "ASC" | "DESC" }>({
        sortBy: "year",
        sortOrder: "ASC"
    });
    const [invoicesData, setInvoicesData] = useState<any>(null);
    const [optionSelected, setOptionSelected] = useState<Option | null>(null);
    const [options, setOptions] = useState<Option[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    // Populate "year" field in select with actual years in array of invoices
    const getYearOptions = (invoices: Invoice[]) => {
        const unique = Array.from(new Set(invoices.map((item) => moment(item.invoiceDate).year())));
        const options: Option[] = unique.map((year) => ({ label: year, value: year }));
        options.length && options.unshift({ label: "all", value: null });
        return options;
    };

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const { data, loading } = await getData(null, "invoice", {
                params: { ...paginationState, year: optionSelected?.value }
            });

            setIsLoading(loading);
            setOptions(getYearOptions(data.elements));
            initTable(formatTable(t, data?.elements));
            setInvoicesData(data);
        })();
    }, [paginationState, optionSelected]);

    const handlePage = (action: string) => {
        handlePaginationState({
            ...paginationState,
            page: action === "+" ? paginationState?.page + 1 : paginationState?.page - 1
        });
    };

    const handleElementsPerPage = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
        handlePaginationState({ ...paginationState, elementsPerPage: parseInt(target.value) });
    };

    const handleChangeSelect = (data: any) => {
        setOptionSelected(data);
    };

    const handleSort = (e: any) => {
        setSortState({
            sortBy: e.target.name,
            sortOrder: sortState.sortOrder === "ASC" ? "DESC" : "ASC"
        });
    };

    return (
        <>
            <div className="mb-4">
                <FilterInvoices
                    title={t("account-management.invoices.filter-label")}
                    selectProps={{
                        options,
                        value: optionSelected,
                        onChange: handleChangeSelect,
                        placeholder: t("account-management.invoices.filter-label")
                    }}
                    loading={isLoading}
                />
            </div>
            <div className="middle-container">
                <CustomTable handleSort={handleSort} sortState={sortState} />
                {invoicesData && (
                    <Pagination
                        handlePage={handlePage}
                        handleElementsPerPage={handleElementsPerPage}
                        paginationMeta={{
                            page: paginationState.page,
                            pagesCount: invoicesData.totalPage
                        }}
                    />
                )}
            </div>
        </>
    );
};

export default MyInvoices;
