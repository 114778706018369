import React, { useContext, useState } from "react";

import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import CustomButton from "../../../components/button/button";
import Input from "../../../components/input/Input";
import RoundedLoader from "../../../components/loader/RoundedLoaderAnimated";
import { authLayoutContext } from "../../../context/authLayoutContext";
import { getError } from "../../../services/errorsService";
import { useAxios } from "../../../services/hook/requestsService";
import { isStrEmpty } from "../../../services/tools";

const ForgottenPassword: React.FC = () => {
    const { t } = useTranslation();
    const { postData, loading, error: isError } = useAxios(); // attention regression token ?
    const [error, setError] = useState(null);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const authStore = useContext(authLayoutContext);

    //initial values form
    const initialValues = {
        email: ""
    };

    const submitForgottenPassword = async ({ email }: { email: string }) => {
        try {
            const { data } = await postData(null, "password_reset/token", {
                email,
                origin: "blurit_app"
            });
            if (data) {
                setIsEmailSent(true);
                authStore.handleDisplayLaius(false);
            }
        } catch (e: any) {
            setError(e);
            authStore.handleDisplayLaius(false);
        }
    };

    //check if form is valid to disable button
    const isFormInValid = (errors: any, values: { email: string }): boolean => {
        return Object.keys(errors).length > 0 || isStrEmpty(values.email);
    };

    //validation schema
    const validationSchema = yup.object().shape({
        email: yup.string().email()
    });

    return !isEmailSent ? (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitForgottenPassword}
        >
            {({ values, handleChange, errors }) => {
                return (
                    <Form className="d-flex flex-column justify-content-between">
                        <div className="mb-4">
                            <Input
                                id="email"
                                name="email"
                                icon="email"
                                value={values.email}
                                placeholder={t("forgotten-password.emailPlaceholder")}
                                isRequired
                                onChange={handleChange as any}
                                inputType="email"
                                inputClassName="rounded"
                                labelTranslation={t("forgotten-password.emailLabel")}
                            />
                        </div>
                        {loading ? (
                            <div className="mb-4 d-flex justify-content-center">
                                <RoundedLoader isLoading={loading} />
                            </div>
                        ) : (
                            <div className="mb-4 d-flex justify-content-center">
                                <CustomButton
                                    disabled={isFormInValid(errors, values)}
                                    buttonText={t("forgotten-password.submit")}
                                    classNameType="main"
                                />
                            </div>
                        )}
                        {isError && error && (
                            <div className="credential-error">{getError(error, t).message}</div>
                        )}
                    </Form>
                );
            }}
        </Formik>
    ) : (
        <p>{t("forgotten-password.emailSubmitted")}</p>
    );
};

export default ForgottenPassword;
