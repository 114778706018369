import { selectorFamily, useRecoilValue } from "recoil";

import { CurrentFrameDetectionState, useCurrentFrameDetections } from "./useCurrentFrameDetections";
import { trackPreviewAtom } from "./useTrackPreview";
import { mediaAtom } from "../hooks/useMedia";

const currentFramePreviewSelector = selectorFamily({
    key: "currentFramePreviewSelector",
    get:
        (currentFrameDetections: CurrentFrameDetectionState[]) =>
        ({ get }) => {
            const trackPreviewState = get(trackPreviewAtom);
            const { currentFrame } = get(mediaAtom);
            if (
                trackPreviewState &&
                (currentFrame < trackPreviewState.frameStart ||
                    currentFrame > trackPreviewState.frameEnd)
            ) {
                return undefined;
            }
            const rangeIndex = trackPreviewState?.initialRange.find(
                (range) => currentFrame >= range.frameStart && currentFrame <= range.frameEnd
            );
            // if detection has already been updated in range we return it
            if (rangeIndex) {
                return {
                    box: rangeIndex.box,
                    ldm: rangeIndex.ldm
                };
            }
            // if preview detection is in this frame
            const detection = currentFrameDetections.find(
                (dtc) => dtc.trackId === trackPreviewState?.trackId
            );
            if (detection) {
                return {
                    box: detection.box,
                    ldm: detection.ldm
                };
            }
            return undefined;
        }
});

export const useCurrentFramePreviewDetection = () => {
    const currentFrameDetections = useCurrentFrameDetections(true);
    const currentFrameDetectionState = useRecoilValue(
        currentFramePreviewSelector(currentFrameDetections)
    );
    return currentFrameDetectionState;
};
