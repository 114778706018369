import { useEffect, useState } from "react";

import { Card } from "./hook/cards.atom";
import { useCards } from "./hook/useCards";

const CardAnimated: React.FC<Card & { children: React.ReactNode }> = (props) => {
    const { children, open, id, onClose } = props;
    const [, { removeCard }] = useCards();
    const [classOpen, setClassOpen] = useState<boolean>(true);

    useEffect(() => {
        if (!open) {
            setClassOpen(false);
        }
    }, [open]);

    const handleAnimationEnd = () => {
        if (!classOpen) {
            if (onClose) onClose();
            removeCard(id);
        }
    };

    return (
        <div
            onAnimationEnd={handleAnimationEnd}
            className={`card-animated ${open && classOpen ? "open" : "close"}`}
        >
            {children}
        </div>
    );
};

export const CardsContainer: React.FC = () => {
    const [cards] = useCards();

    return (
        <>
            {cards.map((data) => {
                return (
                    <CardAnimated key={data.id} {...data}>
                        {data.element}
                    </CardAnimated>
                );
            })}
        </>
    );
};
