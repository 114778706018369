import React, { useState, useEffect, useContext } from "react";

import moment from "moment";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

import { fakeData2 as data } from "./graphData";
import { clientContext } from "../../context/clientContext";
import useWindowDimensions from "../../hook/useWindowDimensions";
import { formatDataPerMonthGraph } from "../../services/dashboardService";
import { useAxios } from "../../services/hook/requestsService";
import CustomSelect from "../select/CustomSelect";

interface IDataPerMonthGraph {
    service: string;
    currentYear: string;
}

type Option = {
    label: string;
    value: string;
};

const DataPerMonthGraph: React.FC<IDataPerMonthGraph> = ({ service, currentYear }) => {
    const options: Option[] = moment
        .months()
        .map((month) => ({ label: month, value: moment().month(month).format("MM") }));

    const currentMonth = { label: moment().format("MMMM"), value: moment().format("MM") };

    const [selected, setSelected] = useState(currentMonth);
    const [isLoading, setIsLoading] = useState(true);
    const [graphData, setGraphData] = useState<any | null>(null);
    const clientState = useContext(clientContext);
    const { getData } = useAxios();
    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    // TODO: REFAIRE AVEC UN CONTEXT WALLAH
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const month = `${currentYear}-${selected?.value}`;

            const perMonthData = await getData(null, "history-call/consumption", {
                params: {
                    service: service !== "all" ? service : undefined,
                    period: "day",
                    dateStart: moment(month).startOf("month").format("YYYY-MM-DDTHH:mm:ss"),
                    dateEnd: moment(month).endOf("month").format("YYYY-MM-DDTHH:mm:ss"),
                    isTrial: clientState.dataClient?.isTrial
                }
            });
            const { monthConsumption, monthApiCall, xAxisCategories } = formatDataPerMonthGraph(
                perMonthData.data?.totalCountsPerPeriod,
                month
            );
            const optionsTmp = { ...data };
            optionsTmp.series[0].data = monthConsumption;
            optionsTmp.series[1].data = monthApiCall;
            optionsTmp.options.xaxis.categories = xAxisCategories;
            setGraphData(optionsTmp);

            setIsLoading(false);
        })();
        return () => {
            setGraphData(null);
            setIsLoading(false);
        };
    }, [service, currentYear, selected]);

    const handleSelect = (selected: any) => {
        setSelected(selected);
    };

    return (
        <div className="h-100 w-100">
            <div className="d-flex justify-content-between mx-4 mb-3">
                <div className="d-none d-lg-flex align-items-center mx-4 mb-3">
                    <h2 className="mb-0 mr-1">{t("dashboard.total-per-month-graph.title")}</h2>
                </div>

                <div style={{ minWidth: "20%" }} className="w-50-mobile ms-auto">
                    <CustomSelect onChange={handleSelect} options={options} value={selected} />
                </div>
            </div>
            {isLoading ? null : (
                // @ts-ignore
                <Chart
                    //@ts-ignore
                    options={graphData.options}
                    series={graphData.series}
                    type="bar"
                    width={width < 992 ? "100%" : "105%"}
                    height="94%"
                />
            )}
        </div>
    );
};

export default DataPerMonthGraph;
