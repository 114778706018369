import { TypeOS } from "../../../services/tools";

export const PLUGIN_FILES_BASE_URL =  `${process.env.REACT_APP_SELF_URL}/dist`;

export interface PluginEditorEntry {
    tag: string;
    installerSlug: string;
    docPath: string | undefined;
}

export interface PluginOsEntry {
    type: TypeOS;
    installerSlug: string;
    installerExt: string;
}

export const pluginEditors: PluginEditorEntry[] = [
    {
        tag: "adobe",
        installerSlug: "Adobe",
        docPath: "https://doc-plugin.blurit.io/adobe/after-effect"
    },
    {
        tag: "avid",
        installerSlug: "Avid",
        docPath: "https://doc-plugin.blurit.io/avid/media-composer"
    }
];

export const pluginOs: PluginOsEntry[] = [
    {
        type: TypeOS.MACOS,
        installerSlug: "MacOS",
        installerExt: "pkg"
    },
    {
        type: TypeOS.WINDOWS,
        installerSlug: "Windows",
        installerExt: "exe"
    }
];
