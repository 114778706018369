import { atom } from "recoil";

export const TASKS_UNAVAILABLE_TYPES = {
    CARD_EXPIRED: "CARD_EXPIRED",
    SERVICES_ERROR: "SERVICES_ERROR",
    INACTIVE_SERVICES: "INACTIVE_SERVICES"
} as const;
export type TASKS_UNAVAILABLE =
    (typeof TASKS_UNAVAILABLE_TYPES)[keyof typeof TASKS_UNAVAILABLE_TYPES];

type ServiceUsable = {
    status: boolean;
    reason?: TASKS_UNAVAILABLE;
};

export const serviceUsableState = atom<ServiceUsable>({
    key: "serviceUsable",
    default: {
        status: true
    }
});
