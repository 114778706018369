import { atom } from "recoil";

import { DetectionClassName } from "./framesDetectionsCoordinates.atom";

export type CanvasDrawMode = DetectionClassName | undefined;

export const canvasDrawModeAtom = atom<CanvasDrawMode>({
    key: "studioModeAtom",
    default: undefined
});
