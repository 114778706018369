import React, { useState, ReactNode, ReactElement } from "react";
import "../../static/scss/tip.scss";

interface TooltipProps {
    content: ReactNode;
    children?: ReactElement;
    disabled?: boolean;
    maxWidth?: number;
    height?: number;
}

const Tooltip: React.FC<TooltipProps> = ({
    content,
    children,
    disabled = false,
    maxWidth = 250,
    height = undefined
}) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
        if (!disabled) {
            setShowTooltip(true);
        }
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };
    return (
        <div
            className="tip-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {children}
            {showTooltip && (
                <div className="tip" style={{ maxWidth: maxWidth, height }}>
                    {content}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
