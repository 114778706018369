import { TFunction } from "i18next";

const errorCodes: { [key: string]: { [key2: string]: string } } = {
    unsupportedResolution: {
        title: "unsupportedResolution-title",
        message: "unsupportedResolution-message"
    },
    trialLimitation: {
        title: "trialLimitation-title",
        message: "trialLimitation-message"
    },
    cardExpired: {
        title: "cardExpired-title",
        message: "cardExpired-message"
    }
};

export const getError = (
    error: any,
    t: TFunction,
    customError?: string
): { message: string; title?: string } => {
    if (error?.response?.data?.code && errorCodes[error.response.data.code]) {
        return {
            message: t(`error.${errorCodes[error.response.data.code].message}`),
            title: t(`error.${errorCodes[error.response.data.code].title}`)
        };
    }

    if (
        error?.response?.data?.message === "unsupportedResolution" &&
        errorCodes[error.response.data.message]
    ) {
        return {
            message: t(`error.${errorCodes[error.response.data.message].message}`),
            title: t(`error.${errorCodes[error.response.data.message].title}`)
        };
    }

    if (
        error?.response?.data?.message === "trialLimitation" &&
        errorCodes[error.response.data.message]
    ) {
        return {
            message: t(`error.${errorCodes[error.response.data.message].message}`),
            title: t(`error.${errorCodes[error.response.data.message].title}`)
        };
    }

    if (customError) {
        if (errorCodes[customError]) {
            return {
                message: t(`error.${errorCodes[customError].message}`),
                title: t(`error.${errorCodes[customError].title}`)
            };
        }
        return { message: t(customError) };
    } else if (!error.response || error?.response?.status === 500) {
        return { message: t("error.internalError") };
    } else if (error.response.status === 401) {
        return { message: t("error.unauthorized") };
    } else if (error.response.status === 413) {
        return { message: t("error.fileTooLong") };
    } else if (error.response.status === 403) {
        if (error.response.data?.message) {
            return error.response.data;
        }
        return { message: t("error.forbidden") };
    } else {
        return { message: t("error.internalError") };
    }
};
