import React, { ChangeEventHandler } from "react";

import { useRecoilValue } from "recoil";

import { useControlVideo } from "../../../hooks/useControlVideo";
import { MediaVideo } from "../../../hooks/useMedia";
import { blurPreviewAtom } from "../../../recoil/blurPreview.atom";
import { framesDetectionsCoordinatesAtom } from "../../../recoil/framesDetectionsCoordinates.atom";
import { useVideoBuffer } from "../hooks/useVideoBuffer";

type Props = {
    media: MediaVideo;
};

const _getPositionInRange = (currPos: number, maxPos: number) => (currPos / maxPos) * 100;

// eslint-disable-next-line react/display-name
export const ProgressBar = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    const { nbFrames, currentFrame } = props.media;
    const [video, { goToFrame, pause }] = useControlVideo();
    const detectionsData = useRecoilValue(framesDetectionsCoordinatesAtom);
    const rangeBackground = `calc(${nbFrames ? Math.round(_getPositionInRange(currentFrame, nbFrames - 1)) : 0}%)`;
    const blurPreviewState = useRecoilValue(blurPreviewAtom);
    const [bufferState] = useVideoBuffer();

    const getBufferedPercentage = () => {
        if (!video) return 0;
        const duration = isNaN(video.duration) ? 0 : video.duration;
        const bufferedPercentage = (bufferState / duration) * 100;
        return bufferedPercentage;
    };

    const bufferedBackground = `calc(${getBufferedPercentage()}% - 40px)`;

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (!video || !detectionsData) return;
        const inputValue = parseInt(e.target.value);
        pause();
        goToFrame(inputValue);
    };

    return (
        <>
            <div className="media-controls__timeline__sliders__progress-bar">
                <input
                    disabled={blurPreviewState}
                    style={{ backgroundSize: rangeBackground }}
                    onChange={handleChange}
                    value={currentFrame}
                    max={isNaN(nbFrames) ? 0 : nbFrames - 1}
                    min={0}
                    step={1}
                    ref={ref}
                    type={"range"}
                />
                <div className="media-controls__timeline__sliders__progress-bar__range-base"></div>
                <div
                    className="media-controls__timeline__sliders__progress-bar__range-buffered"
                    style={{ width: `calc(${bufferedBackground} - 40px)` }}
                ></div>
            </div>
        </>
    );
});
