import { atom, useRecoilState } from "recoil";

export const invalidMediaAtom = atom({
    key: "invalidMediaAtom",
    default: false
});

export const useInvalidMedia = () => {
    const [mediaInvalid, setMediaInvalid] = useRecoilState(invalidMediaAtom);
    return { mediaInvalid, setMediaInvalid };
};
