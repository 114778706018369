import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { AxiosError, AxiosProgressEvent } from "axios";

import {
    askAnonymizationJobId,
    askAnonymizationRendering,
    updateAnonymizatioDetections
} from "../../api/anonymization.api";
import {
    AskAnonymizationRequest,
    AskAnonymizationResponse,
    FormAskRendering,
    FormUpdateDetections
} from "../../types";

export const useAskAnonymization = (
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void
): UseMutationResult<AskAnonymizationResponse, AxiosError, AskAnonymizationRequest> => {
    return useMutation({
        mutationFn: (params) => {
            return askAnonymizationJobId(params, onUploadProgress);
        }
    });
};

export const useAskAnonymizationRendering = (
    onUploadProgress: (progressEvent?: AxiosProgressEvent) => void
): UseMutationResult<unknown, AxiosError, FormAskRendering> => {
    return useMutation({
        mutationFn: (params) => askAnonymizationRendering(params, onUploadProgress)
    });
};

export const useUpdateDetections = (): UseMutationResult<
    unknown,
    AxiosError,
    FormUpdateDetections
> => {
    return useMutation({
        mutationFn: (params) => updateAnonymizatioDetections(params)
    });
};
