import { object, string, boolean } from "yup";

export const validatePayment = () => {
    const schema: Record<string, any> = {
        firstName: string().min(2).max(200).required(),
        lastName: string().min(2).max(200).required(),
        address: string().min(2).max(200).required(),
        city: string().min(2).max(200).required(),
        postalCode: string().min(2).max(200).required(),
        country: string().min(2).max(200).required(),
        type: string().oneOf(["COMPANY", "INDIVIDUAL"]).required(),
        conditions: boolean().isTrue().required(),
        optIn: boolean().required(),
        companyName: string().when("type", {
            is: "COMPANY",
            then: string().required(),
            otherwise: string().transform(() => undefined)
        })
    };
    return object().shape(schema).noUnknown();
};
