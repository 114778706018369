import { useTranslation } from "react-i18next";
import "../../static/scss/error.scss";

interface ErrorHeaderPluginTrialProps {
    endingDate: string;
}

export const ErrorHeaderPluginTrial: React.FC<ErrorHeaderPluginTrialProps> = ({ endingDate }) => {
    const { t } = useTranslation();
    return (
        <div className="error">
            <span className="color-error">{t("header.error-plugin-trial", { endingDate })}</span>
        </div>
    );
};
