import React, { useRef, useState } from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import useWindowDimensions from "../../../hook/useWindowDimensions";
import { DownloadButton } from "../../../pages/account/utils/downloadButton";
import { BadgeStatus } from "../../../pages/taskManager/helper/formatTable";
import { convertSize } from "../../../services/tools";

// Icons
import { ReactComponent as IconBack } from "../../../static/icons/icon-back-mobile.svg";
import { ReactComponent as IconEdit } from "../../../static/icons/icon_edit.svg";

// Style
import "./TaskManagerDetail.scss";
import {
    FormattedHistoryCall,
    HistoryCallServiceMode,
    ServiceSearchKey
} from "../../../types/historyCall";
import Modal, { IModal } from "../../modal/Modal";

type TTask = {
    title: string;
    data: string;
    status?: boolean;
};

const TaskManagerDetail: React.FC = () => {
    const { t } = useTranslation();
    // State from /task-manager
    const { state }: { state: { element: FormattedHistoryCall } } = useLocation();
    const { push } = useHistory();
    const { width } = useWindowDimensions();
    const [modalState, setModalState] = useState({} as IModal);
    const jsonAnchorRef = useRef<HTMLAnchorElement>(null);
    const fileAnchorRef = useRef<HTMLAnchorElement>(null);

    const task = state.element;
    // Badge status
    const getBadgeSatus = () => {
        switch (task.status as string) {
            case "Succeeded": {
                return BadgeStatus.SUCCESS;
            }
            case "Deleted": {
                return BadgeStatus.SUCCESS;
            }
            case "None": {
                return BadgeStatus.NONE;
            }
            case "Sent": {
                return BadgeStatus.PROCESSING;
            }
            case "Started": {
                return BadgeStatus.PROCESSING;
            }
            case "Uploading": {
                return BadgeStatus.PROCESSING;
            }
            case "Failed": {
                return BadgeStatus.FAILED;
            }
            case "Upload failed": {
                return BadgeStatus.FAILED;
            }
            default:
                return BadgeStatus.NONE;
        }
    };

    const formatFileName = (element: any) => {
        if (element.status === "Succeeded" && element.filename) {
            return `${element.filename.slice(0, element.filename.lastIndexOf("."))}_processed_${moment(
                element.date
            )
                .utc()
                .format(
                    "YYYYMMDDhhmmss"
                )}z${element.filename.slice(element.filename.lastIndexOf("."), element.filename.length)}`;
        } else {
            return element.filename;
        }
    };

    const isTaskSucceeded = (status: string) => {
        return status === "Succeeded";
    };

    const isTaskStudio = (mode: HistoryCallServiceMode) => {
        return mode === HistoryCallServiceMode.DETECTION || mode === HistoryCallServiceMode.RENDER;
    };

    const displayModal = () => {
        setModalState({
            show: true,
            message: t("task-management-detail.modal.studio"),
            onConfirm: () => setModalState({ ...modalState, show: false })
        });
    };

    const startDownloadCoordinates = () => {
        if (jsonAnchorRef && jsonAnchorRef.current) {
            jsonAnchorRef.current.click();
        }
    };

    const startDownloadFile = () => {
        if (fileAnchorRef && fileAnchorRef.current) {
            fileAnchorRef.current.click();
        }
    };

    // Task list to map
    const taskList: TTask[] = [
        {
            title: t("task-management-detail.list.status"),
            data: state.element.status,
            status: true
        },
        {
            title: t("task-management-detail.list.date"),
            data: moment(task.date).format("DD-MM-YYYY_HH:mm:ss")
        },
        {
            title: t("task-management-detail.list.service"),
            data: state.element.service_name
        },
        {
            title: t("task-management-detail.list.fileType"),
            data: state.element.file_type
        },
        {
            title: t("task-management-detail.list.fileName"),
            data: formatFileName(task)
        },
        {
            title: t("task-management-detail.list.fileSize"),
            data: convertSize(Number(task.size))
        }
    ];

    // Redirect if device width > 992px
    if (width > 992) {
        push("/task-manager");
    }

    return (
        <>
            <h2 className="title-up-container" onClick={() => push("/task-manager")}>
                <IconBack fill="#6B7897" />
                {t("task-management-detail.title")}
            </h2>

            <div className="middle-container middle-container-detail">
                <ul className="middle-container-detail-list">
                    {taskList.map((element, index: number) => (
                        <li key={index}>
                            <h5 className="list-title">{element.title}</h5>
                            {element.status ? (
                                <div className="task-status">
                                    <span className={`status ${getBadgeSatus()}`}></span>
                                    {element.data}
                                </div>
                            ) : (
                                element.data.split("/")[0]
                            )}
                        </li>
                    ))}
                    <li className="item-buttons">
                        <h5 className="list-title">{t("task-management-detail.list.downloads")}</h5>
                        <div className="list-grp">
                            {isTaskStudio(task.mode) ? (
                                <div
                                    className={`mobile-task ${!isTaskSucceeded(task.status) || task.mode === HistoryCallServiceMode.RENDER ? "disabled" : ""}`}
                                    onClick={displayModal}
                                >
                                    <IconEdit width={20} height={20} fill={"#000000"} />
                                    {t("task-management-detail.button.studio")}
                                </div>
                            ) : (
                                <div
                                    className={`mobile-task ${!isTaskSucceeded(task.status) || !task.parameters.output_detections_url ? "disabled" : ""}`}
                                    onClick={startDownloadCoordinates}
                                >
                                    <DownloadButton
                                        params={{
                                            searchKey: task.service_search_key,
                                            job_id: task.job_id,
                                            fileType: task.file_type,
                                            is_json: true,
                                            fileName: `${task.filename.slice(0, task.filename.lastIndexOf("."))}.json`
                                        }}
                                        url={`${process.env.REACT_APP_API_URL}/task-management/${task.fileurl}`}
                                        filename={`${task.filename.slice(0, task.filename.lastIndexOf("."))}.json`}
                                        buttonType="image"
                                        aRef={jsonAnchorRef}
                                    />
                                    {t("task-management-detail.button.coordinates")}
                                </div>
                            )}
                            <div
                                className={`mobile-task ${!isTaskSucceeded(task.status) ? "disabled" : ""}`}
                                onClick={startDownloadFile}
                            >
                                <DownloadButton
                                    params={{
                                        searchKey: task.service_search_key,
                                        job_id: task.job_id,
                                        fileType: task.file_type,
                                        fileName: formatFileName(task)
                                    }}
                                    url={`${process.env.REACT_APP_API_URL}/task-management/${task.fileurl}`}
                                    filename={formatFileName(task)}
                                    buttonType="image"
                                    aRef={fileAnchorRef}
                                />
                                {task.service_search_key === ServiceSearchKey.ANONYMIZATION
                                    ? t("task-management-detail.button.blurred-file")
                                    : t("task-management-detail.button.watermarked-file")}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <Modal {...modalState} />
        </>
    );
};

export default TaskManagerDetail;
