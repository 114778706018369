export enum ClientType {
    COMPANY = "COMPANY",
    INDIVIDUAL = "INDIVIDUAL"
}

export enum ClientSupport {
    STANDARD = "STANDARD",
    BASIC = "BASIC",
    PREMIUM = "PREMIUM",
    PRO = "PRO"
}

export enum ClientCurrency {
    EUR = "EUR",
    USD = "USD"
}

export type Client = {
    name?: string;
    contact?: string;
    mail: string;
    address?: string;
    phone?: number;
    date: {
        created: string;
        updated: string;
    };
    type?: ClientType;
    lastName: string;
    firstName: string;
    legalForm?: string;
    companyName?: string;
    companyId?: string;
    vatNumber?: string;
    city?: string;
    country?: string;
    postalCode?: string;
    invoicingContact?: string;
    bankName?: string;
    bankAddress?: string;
    BIC_SWIFT?: string;
    IBAN?: string;
    invoicingEmail?: string;
    isTrial: boolean;
    support?: ClientSupport;
    cardLast4?: string;
    creditCardExpirationDate?: string;
    optIn?: boolean;
    clientCurrency: ClientCurrency;
};

export type GetClientInput = Partial<{
    withId?: boolean;
}>;

export type GetClientResponse = Client;
