import { AxiosRequestConfig, AxiosResponse } from "axios";

import { instance } from "../api/axios";

export const getData = async <T>(params: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    const requestConfig: AxiosRequestConfig = {
        method: "get",
        ...params
    };
    return await instance.request<T>(requestConfig);
};

export const putData = async <T>(params: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    const requestConfig: AxiosRequestConfig = {
        method: "put",
        ...params
    };
    return await instance.request<T>(requestConfig);
};

export const postData = async <T>(params: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    const requestConfig: AxiosRequestConfig = {
        method: "post",
        ...params
    };
    return await instance.request<T>(requestConfig);
};

export const deleteData = async <T>(params: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    const requestConfig: AxiosRequestConfig = {
        method: "delete",
        ...params
    };
    return await instance.request<T>(requestConfig);
};
