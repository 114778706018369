import React, { createContext, useCallback, useEffect, useState } from "react";

import ioClient from "socket.io-client";

const socketClient: any = null;

type SocketState = {
    isConnected: boolean;
    socketClient: any;
    initSocket: (token: string) => void;
    disconnectSocket: () => Promise<void>;
    task: { [taskId: string]: string };
};

const initialState = {
    isConnected: false,
    socketClient,
    initSocket: async () => {
        return;
    },
    disconnectSocket: async () => {
        return;
    },
    task: {}
};

const socketContext = createContext<SocketState>(initialState);

const SocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [stateSocket, setStateSocket] = useState(initialState);
    const manageMessageSocket = useCallback(() => {
        stateSocket.socketClient.on("message", (message: any) => {
            if (message === "Welcome to your own channel") {
                console.log("Welcome to your own channel");
                setStateSocket({ ...stateSocket, isConnected: true });
            } else {
                //@ts-ignore
                if (!stateSocket.task[message.taskId] && message !== "ok") {
                    console.log("Task received");
                    setStateSocket({
                        ...stateSocket,
                        task: {
                            ...stateSocket.task,
                            [message.taskId]: message.status
                        }
                    });
                }
            }
        });
    }, [stateSocket]);

    const disconnectSocket = async () => {
        setStateSocket({
            ...stateSocket,
            isConnected: false
        });
        await stateSocket?.socketClient?.disconnect();
    };

    const initSocket = (token: string) => {
        const socketClient = ioClient(process.env.REACT_APP_API_URL as string, {
            query: {
                token
            }
        });
        setStateSocket({
            ...stateSocket,
            socketClient,
            isConnected: true
        });
    };

    useEffect(() => {
        if (stateSocket.isConnected) {
            manageMessageSocket();
        }
    }, [stateSocket.socketClient]);

    return (
        <socketContext.Provider value={{ ...stateSocket, initSocket, disconnectSocket }}>
            {children}
        </socketContext.Provider>
    );
};

export { SocketProvider, socketContext };
