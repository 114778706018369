import { useLoadingState } from "../../../hooks/useLoadingState";
import { MediaVideo, useMedia } from "../../../hooks/useMedia";
import { useNewTrackCreationPreview } from "../../../hooks/useNewTrackCreationPreview";
import { useTrackPreview } from "../../../hooks/useTrackPreview";

export const useButtonsDisabled = (): boolean => {
    const [loadingState] = useLoadingState();
    const [media] = useMedia<MediaVideo>();
    const [trackPreview] = useTrackPreview();
    const [trackCreation] = useNewTrackCreationPreview();
    return !!(loadingState === "init-studio" || media?.isPlaying || trackPreview || trackCreation);
};
