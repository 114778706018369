import { TFunction } from "i18next";
import moment from "moment";

import { ClientService } from "../../../context/clientServicesContext";
import { ConsumptionData, CallsPerPeriod } from "../../../context/consumptionContext";
import { Service } from "../../../context/servicesContext";
/********************
 *     CONSTANTS    *
 *********************/
// api services
export const listApiServices: { [name: string]: string } = {
    anonymization: "SRV_ANM",
    blurIt: "SRV_ANM",
    congestion: "SRV_CNG",
    facelytics: "SRV_FACE",
    soiling: "SRV_SOL",
    watermark: "SRV_WATERMARK",
    orientation: "SRV_ORIENTATION",
    vehiclesAndPedestriansDetection: "SRV_VHC_PDS_DETECT",
    identity: "SRV_IDENTITY"
};

// services display format
export const servicesConfig = {
    blurIt: {
        SRV_ANM: ({} as ClientService) || null, //api
        SRV_BLIT_SDK: ({} as ClientService) || null, //sdk
        SRV_BLIT_PREMISE: ({} as ClientService) || null //premise
    },
    facelytics: {
        SRV_FACE: ({} as ClientService) || null, //api
        SRV_FACE_SDK: ({} as ClientService) || null //sdk
    },
    congestion: {
        SRV_CNG: ({} as ClientService) || null //api
    },
    soiling: {
        SRV_SOL: ({} as ClientService) || null //api
    },
    watermark: {
        SRV_WATERMARK: ({} as ClientService) || null //api
    },
    orientation: {
        SRV_ORIENTATION: ({} as ClientService) || null //api
    },
    vehiclesAndPedestriansDetection: {
        SRV_VHC_PDS_DETECT: ({} as ClientService) || null //api
    },
    identity: {} as { [key: string]: ClientService | null }
};

//options services for blurit sdk
export const optionsServices = ["pic_only", "pic_coord", "vid_only", "vid_coord"];

/********************
 * PUBLIC FUNCTIONS *
 *********************/

/**
 * Format first block in services management
 * @param clientServices
 */
export const formatApiManagement = (clientServices: ClientService[]) => {
    const firstClientService = _getClientServicesInfoBySearchKey(clientServices, "SRV_ANM");

    const activationDate = firstClientService?.start || null;
    const support = firstClientService?.support || null;

    return {
        areApiActives: _areApiActive(clientServices),
        activationDate: activationDate ? moment(activationDate).format("DD/MM/YYYY") : null,
        support
    };
};

/**
 * Format second and third block in services management (api call / api consumption)
 * @param consumption
 */
export const formatApiCallAndConsumptionTable = (consumption: ConsumptionData) => {
    const totalCountPerPeriod = consumption?.totalCountsPerPeriod;

    const pictureData = consumption?.totalCounts?.find(({ type }) => type === "PICTURE") || {
        type: "PICTURE",
        size: "0",
        totalCount: "0"
    };
    const videoData = consumption?.totalCounts?.find(({ type }) => type === "VIDEO") || {
        type: "PICTURE",
        size: "0",
        totalCount: "0"
    };

    const { size: currentMonthConsumptionPictureSize, nbCall: currentMonthPictureApiCall } =
        _getDataPerDateAndType(moment().format("YYYY-MM"), "PICTURE", totalCountPerPeriod);

    const { size: currentMonthConsumptionVideoSize, nbCall: currentMonthVideoApiCall } =
        _getDataPerDateAndType(moment().format("YYYY-MM"), "VIDEO", totalCountPerPeriod);

    return {
        currentMonthConsumptionPictureSize,
        currentMonthPictureApiCall,
        currentMonthConsumptionVideoSize,
        currentMonthVideoApiCall,
        currentMonthConsumptionSize:
            currentMonthConsumptionPictureSize + currentMonthConsumptionVideoSize,
        currentMonthApiCall: currentMonthPictureApiCall + currentMonthVideoApiCall,
        totalConsumptionSize: parseInt(pictureData.size) + parseInt(videoData.size),
        totalConsumptionPictureSize: parseInt(pictureData.size),
        totalConsumptionVideoSize: parseInt(videoData.size),
        totalApiCall: parseInt(pictureData.totalCount) + parseInt(videoData.totalCount),
        totalPictureApiCall: parseInt(pictureData.totalCount),
        totalVideoApiCall: parseInt(videoData.totalCount)
    };
};

//format consumption/api call for the calendar
export const formatCalendarData = (
    totalCountsPerPeriod: CallsPerPeriod[],
    serviceKeyName?: string,
    typeOfCall?: string
) => {
    if (serviceKeyName) {
        serviceKeyName = listApiServices[serviceKeyName];
    }
    const consumptionPerYear: any[] = [];
    const apiCallsPerYear: any[] = [];
    const identityPerYear: any[] = [];
    //parcourt les années
    for (
        let startYear = 2020;
        startYear <= parseInt(moment(new Date()).format("YYYY"));
        startYear++
    ) {
        const consumptionPerMonths: any[] = [];
        const apiCallsPerMonths: any[] = [];
        const identityPerMonths: any[] = [];

        const currentYear = startYear.toString();
        const dateStart = moment(currentYear).startOf("year");
        const dateEnd = moment(dateStart).add(11, "month");

        //parcourt les mois
        while (dateEnd.diff(dateStart, "months") >= 0) {
            const currentMonth = dateStart.format("YYYY-MM");
            let totalApiCall = 0;
            let totalImageSize = 0;
            let totalVideoSize = 0;
            let imageApiCall = 0;
            let videoApiCall = 0;
            let totalSize = 0;
            let totalModelCreation = 0;
            let totalVector = 0;
            totalCountsPerPeriod.forEach((elem: any) => {
                if (elem.date === currentMonth) {
                    if (
                        elem.type === "PICTURE" &&
                        (serviceKeyName ? serviceKeyName === elem.searchKey : true)
                    ) {
                        if (serviceKeyName === "SRV_IDENTITY") {
                            if (typeOfCall === "SEARCH" && elem.typeOfCall === "SEARCH") {
                                totalImageSize += parseInt(elem.size);
                                imageApiCall += parseInt(elem.totalCount);
                            } else if (
                                typeOfCall === "RECOGNIZE" &&
                                elem.typeOfCall === "RECOGNITION"
                            ) {
                                totalImageSize += parseInt(elem.size);
                                imageApiCall += parseInt(elem.totalCount);
                            } else if (
                                typeOfCall === "UPSERT" &&
                                (elem.typeOfCall === "CREATE" || elem.typeOfCall === "UPDATE")
                            ) {
                                if (elem.typeOfCall === "CREATE") {
                                    totalModelCreation += parseInt(elem.totalCount);
                                }
                                totalImageSize += parseInt(elem.size);
                                imageApiCall += parseInt(elem.totalCount);
                                totalVector += parseInt(elem.nbFiles);
                            }
                        } else {
                            if (
                                !serviceKeyName &&
                                (elem.typeOfCall === "CREATE" || elem.typeOfCall === "UPDATE")
                            ) {
                                totalVector += parseInt(elem.nbFiles);
                            }
                            if (elem.typeOfCall === "CREATE") {
                                totalModelCreation += parseInt(elem.totalCount);
                            }
                            totalImageSize += parseInt(elem.size);
                            imageApiCall += parseInt(elem.totalCount);
                        }
                    }
                    if (
                        elem.type === "VIDEO" &&
                        (serviceKeyName ? serviceKeyName === elem.searchKey : true)
                    ) {
                        totalVideoSize += parseInt(elem.size);
                        videoApiCall += parseInt(elem.totalCount);
                    }
                    totalApiCall += parseInt(elem.totalCount);
                    totalSize += parseInt(elem.size);
                }
            });
            if (serviceKeyName) {
                totalApiCall = imageApiCall + videoApiCall;
                totalSize = totalImageSize + totalVideoSize;
            }
            consumptionPerMonths.push({
                month: currentMonth,
                total: totalSize,
                image: totalImageSize,
                video: totalVideoSize
            });
            apiCallsPerMonths.push({
                month: currentMonth,
                total: totalApiCall,
                image: imageApiCall,
                video: videoApiCall
            });
            identityPerMonths.push({
                month: currentMonth,
                model: totalModelCreation,
                vectors: totalVector
            });
            dateStart.add(1, "month");
        }
        consumptionPerYear.push({ year: startYear, months: consumptionPerMonths });
        apiCallsPerYear.push({ year: startYear, months: apiCallsPerMonths });
        identityPerYear.push({ year: startYear, months: identityPerMonths });
    }
    return { consumptionPerYear, apiCallsPerYear, identityPerYear };
};

//fill the service config with userData
export const formatServicesLayouts = (clientServices: ClientService[], allServices: Service[]) => {
    const response = { ...servicesConfig };
    if (clientServices && allServices) {
        Object.keys(servicesConfig).forEach((servicesKey) => {
            //@ts-ignore
            const serviceGroup = servicesConfig[servicesKey];
            if (servicesKey === "identity") {
                response[servicesKey] = _formatServiceIdentityConsumption(
                    clientServices,
                    allServices
                );
            } else {
                Object.keys(serviceGroup).forEach((serviceKey) => {
                    //@ts-ignore
                    response[servicesKey][serviceKey] = _formatServiceBySearchKey(
                        serviceKey,
                        clientServices,
                        allServices
                    );
                });
            }
        });
    }
    return response;
};

//get services group formatted for select
export const formatServicesListSelect = (t: TFunction) => {
    return [
        { label: t(`common.services-service-config.all`), value: "ALL" },
        ...Object.keys(servicesConfig)
            .map((serviceKey) => ({
                label: t(`common.services-service-config.${serviceKey}`),
                value: serviceKey
            }))
            .sort((a, b) => {
                if (a.label > b.label) {
                    return 1;
                }
                if (a.label < b.label) {
                    return -1;
                }
                return 0;
            })
    ];
};

/*********************
 * PRIVATE FUNCTIONS *
 **********************/

// get infos of clientService by its searchKey
const _getClientServicesInfoBySearchKey = (
    clientService: ClientService[],
    serviceSearchKey: string
) => {
    if (clientService) {
        const cs = clientService.find((cs) => cs.service.searchKey === serviceSearchKey);
        return cs || null;
    }
    return null;
};

// check if client has api services active
const _areApiActive = (clientService: ClientService[]) => {
    if (clientService) {
        //@ts-ignore
        const apiServicesKeys = Object.keys(listApiServices).map(
            (service: string) => listApiServices[service] as any
        );
        const clientServiceApiSearchKeys = clientService.filter((service) =>
            apiServicesKeys.includes(service.service.searchKey)
        );
        if (apiServicesKeys.length === clientServiceApiSearchKeys.length) {
            return true;
        }
    }
    return false;
};

//
const _getDataPerDateAndType = (date: string, type: string, array: CallsPerPeriod[]) => {
    const result = { size: 0, nbCall: 0 };
    array.forEach((elem) => {
        if (elem.date === date && elem.type === type) {
            result.size += parseInt(elem.size);
            result.nbCall += parseInt(elem.totalCount);
        }
    });
    return result;
};

const _formatServiceBySearchKey = (
    toFind: string,
    clientServices: ClientService[],
    allServices: Service[]
) => {
    const typeOfService = toFind.includes("SDK")
        ? "SDK"
        : toFind.includes("PREMISE")
          ? "PREMISE"
          : "API";

    let config = {
        end: moment().format("YYYY-MM-DD"),
        start: moment().format("YYYY-MM-DD"),
        machineId: null,
        uuid: null,
        name: null,
        services: [],
        isActive: false,
        searchKey: null,
        support: null,
        sdkType: null,
        OPsetup: null,
        OPLicenceType: null,
        typeOfService,
        title: typeOfService
    } as any;

    const deactivatedService = allServices.find((service) => service.searchKey === toFind);
    config = {
        ...config,
        searchKey: deactivatedService?.searchKey || null,
        uuid: deactivatedService?.uuid,
        name: deactivatedService?.name
    };

    const activatedService = clientServices.find((service) => service.service.searchKey === toFind);
    if (activatedService) {
        config = {
            ...config,
            end: activatedService?.end ? moment(activatedService.end).format("YYYY-MM-DD") : null,
            start: moment(activatedService.start).format("YYYY-MM-DD"),
            machineId: activatedService?.machineId,
            uuid: activatedService.service.uuid,
            name: activatedService.service.name,
            support: activatedService.support,
            sdkType: activatedService.sdkType,
            OPsetup: activatedService.OPsetup,
            OPLicenceType: activatedService.OPLicenceType,
            services: toFind.includes("PREMISE")
                ? (_setPremiseServicesFormat(activatedService?.services) as any)
                : [],
            isActive: true,
            searchKey: activatedService.service.searchKey
        };
    }

    return config;
};

const _setPremiseServicesFormat = (userServicesActivated?: string[]) => {
    return optionsServices
        .filter((option) => userServicesActivated?.find((service) => service === option))
        .map((option) => ({
            label: option,
            value: option,
            active: true
        }));
};

const _formatServiceIdentityConsumption = (
    clientServices: ClientService[],
    allServices: Service[]
) => {
    let config = {
        end: moment().format("YYYY-MM-DD"),
        start: moment().format("YYYY-MM-DD"),
        machineId: null,
        uuid: null,
        name: null,
        services: [],
        isActive: false,
        searchKey: null,
        support: null,
        sdkType: null,
        OPsetup: null,
        OPLicenceType: null,
        typeOfService: "API",
        title: null
    } as any;

    const identitiesServices = ["UPSERT", "SEARCH", "RECOGNIZE"];
    let identityGroup: { [key: string]: any } = {};

    for (const serviceIdentity of identitiesServices) {
        const deactivatedService = allServices.find(
            (service) => service.searchKey === "SRV_IDENTITY"
        );
        config = {
            ...config,
            searchKey: deactivatedService?.searchKey || null,
            uuid: deactivatedService?.uuid,
            name: deactivatedService?.name,
            title: serviceIdentity
        };

        const activatedService = clientServices.find(
            (service) => service.service.searchKey === "SRV_IDENTITY"
        );
        if (activatedService) {
            config = {
                ...config,
                end: activatedService?.end
                    ? moment(activatedService.end).format("YYYY-MM-DD")
                    : null,
                start: moment(activatedService.start).format("YYYY-MM-DD"),
                machineId: activatedService?.machineId,
                uuid: activatedService.service.uuid,
                name: activatedService.service.name,
                support: activatedService.support,
                sdkType: activatedService.sdkType,
                OPsetup: activatedService.OPsetup,
                OPLicenceType: activatedService.OPLicenceType,
                services: [],
                isActive: true,
                searchKey: activatedService.service.searchKey,
                title: serviceIdentity
            };
        }

        identityGroup = {
            ...identityGroup,
            [serviceIdentity]: {
                ...identityGroup[serviceIdentity],
                ...config
            }
        };
    }

    return identityGroup;
};
