import React from "react";

import "../../static/scss/sidebar.scss";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

import logoWis from "../../static/images/logo-Blurit.svg";

const Sidebar: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const navBarItems = [
        {
            linkTo: "/task-manager",
            title: t("navbar.task-manager"),
            icon: "icon-taskmanager",
            translation: t("navbar.task-manager")
        },
        {
            linkTo: "/dashboard",
            title: t("navbar.dashboard"),
            icon: "icon-dashboard",
            translation: t("navbar.dashboard")
        },
        {
            linkTo: "/account",
            title: t("navbar.account"),
            icon: "icon-account",
            translation: t("navbar.account")
        },
        {
            linkTo: "/developer",
            title: t("navbar.developer"),
            icon: "icon-developer",
            translation: t("navbar.developer")
        }
    ];

    if (location.pathname.includes("/task-manager/anonymization/edit")) return null;
    return (
        <aside className="sidebar-container d-none d-lg-block d-xl-block">
            <div className="wis-logo-container mb-3">
                <img className="w-100" src={logoWis} alt="logo App Blurit" />
            </div>
            <nav className="nav" role="navigation" aria-label="Aside navigation">
                <ul>
                    {navBarItems.map((item, key) => (
                        <li key={key}>
                            <NavLink
                                key={key}
                                to={item.linkTo}
                                title={item.title}
                                className={item.icon}
                                activeClassName="active"
                            >
                                <span>{item.translation}</span>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </nav>
        </aside>
    );
};

export default Sidebar;
