import { RefObject, createContext, useRef, useContext, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

type Refs = {
    videoRef: RefObject<HTMLVideoElement>;
    imageRef: RefObject<HTMLImageElement>;
    canvasDetectionsRef: RefObject<HTMLCanvasElement>;
    canvasMediaRef: RefObject<HTMLCanvasElement>;
};

type RefContextsValue = {
    isReady: Record<RefsName, boolean>;
    updateRefReadyState: (name: RefsName, value: boolean) => void;
} & Refs;

export const RefsContext = createContext<RefContextsValue | undefined>(undefined);

export const useRefsContext = () => {
    const context = useContext(RefsContext);
    if (!context) throw new Error(`Canvas context must be used in a RefsProvider`);
    return context;
};

type RefsName = keyof Refs;

export const RefsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasDetectionsRef = useRef<HTMLCanvasElement>(null);
    const canvasMediaRef = useRef<HTMLCanvasElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);
    const [isReady, setIsReady] = useState<Record<RefsName, boolean>>({
        canvasDetectionsRef: false,
        videoRef: false,
        imageRef: false,
        canvasMediaRef: false
    });
    const location = useLocation();
    useEffect(() => {
        setIsReady({
            videoRef: !!videoRef.current,
            canvasMediaRef: !!canvasMediaRef.current,
            imageRef: !!imageRef.current,
            canvasDetectionsRef: !!canvasDetectionsRef.current
        });
        const handleVisibilityChange = () => {
            setIsReady({
                videoRef: !!videoRef.current,
                canvasMediaRef: !!canvasMediaRef.current,
                imageRef: !!imageRef.current,
                canvasDetectionsRef: !!canvasDetectionsRef.current
            });
        };
        window.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            window.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        setIsReady({
            videoRef: false,
            canvasMediaRef: false,
            imageRef: false,
            canvasDetectionsRef: false
        });
        (videoRef as any).current = null;
        (canvasDetectionsRef as any).current = null;
        (canvasMediaRef as any).current = null;
        (imageRef as any).current = null;
    }, [location]);

    const updateRefReadyState = (name: RefsName, value: boolean) => {
        setIsReady((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <RefsContext.Provider
            value={{
                videoRef,
                canvasDetectionsRef,
                canvasMediaRef,
                imageRef,
                isReady,
                updateRefReadyState
            }}
        >
            {children}
        </RefsContext.Provider>
    );
};
