import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import "../../static/scss/plans.scss";
import CustomButton from "../../components/button/button";
import Modal, { IModal } from "../../components/modal/Modal";
import CustomSelect from "../../components/select/CustomSelect";
import { clientContext } from "../../context/clientContext";
import { ReactComponent as ArrowPlans } from "../../static/icons/plans/arrow-plans.svg";
import { ReactComponent as ListIcon } from "../../static/icons/plans/icon_check.svg";
import { ClientCurrency } from "../../types";

const PriceComponent: React.FC<{ type: "trial" | "pay-as-you-go"; currency: ClientCurrency }> = ({
    type,
    currency
}) => {
    return (
        <>
            {type === "trial" ? (
                <strong>{formatFractionPrice(currency, 0)}</strong>
            ) : (
                <>
                    <div>
                        <label>Image</label>
                        <span>
                            <strong>{formatFractionPrice(currency, 0.05)}</strong>
                            <small>/MB</small>
                        </span>
                    </div>
                    <ArrowPlans />
                    <div>
                        <label>Video</label>
                        <span>
                            <strong>{formatFractionPrice(currency, 0.02)}</strong>
                            <small>/MB</small>
                        </span>
                    </div>
                </>
            )}
        </>
    );
};

const SpecificationsComponent: React.FC<{
    type: "trial" | "basic" | "pro";
    currency: ClientCurrency;
}> = ({ type, currency }) => {
    const { t } = useTranslation();

    const getSpecifications = (): string[] => {
        if (type === "trial")
            return t(`plan.card.trial.specifications`, { returnObjects: true }) as string[];
        return t(`plan.card.basic.specifications`, { returnObjects: true }) as string[];
    };
    const getProSpecifications = (): string[] | undefined => {
        if (type === "pro")
            return t(`plan.card.basic.pro.specifications`, { returnObjects: true }) as string[];
    };

    const specifications = getSpecifications();
    const proSpecifications = getProSpecifications();
    return (
        <>
            <ul>
                {specifications.map((trad, index) => (
                    <li key={index}>
                        <ListIcon width={20} />
                        <span>{trad}</span>
                    </li>
                ))}
            </ul>
            {type === "pro" && proSpecifications && (
                <>
                    <div className="plans-container__card__specifications__pro">
                        <h2 className="color-primary mr-1">+</h2>
                        <h2 className="color-secondary">Support</h2>
                        <sup className="color-secondary">Pro</sup>
                        <h2 className="color-primary ml-1">{formatFractionPrice(currency, 15)}</h2>
                        <small className="color-secondary">/month</small>
                    </div>
                    <ul>
                        {proSpecifications.map((trad, key) => (
                            <li key={key}>
                                <ListIcon width={20} />
                                <span>{trad}</span>
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </>
    );
};

type PlanCard = {
    title: string;
    price: React.ReactNode;
    specifications: React.ReactNode;
    button: React.ReactNode;
    current: boolean;
};

const PlanCard: React.FC<PlanCard> = ({
    title,
    price: Price,
    specifications: Specifications,
    button: Button,
    current
}) => {
    return (
        <div className={`plans-container__card ${current && "pe-none"}`}>
            <span className="plans-container__card__title">{title}</span>
            <span className="plans-container__card__price">{Price}</span>
            <div className="plans-container__card__specifications">{Specifications}</div>
            <div className="plans-container__card__button">{Button}</div>
        </div>
    );
};

const currenciesOptions: Array<{ label: string; value: ClientCurrency }> = [
    {
        label: "Euros (EUR)",
        value: ClientCurrency.EUR
    },
    {
        label: "Dollars (USD)",
        value: ClientCurrency.USD
    }
];

const getClientCurrencyOption = (currency: ClientCurrency) => {
    if (currency === ClientCurrency.EUR) return currenciesOptions[0];
    else return currenciesOptions[1];
};

const formatFractionPrice = (currency: ClientCurrency, price: number): string => {
    return currency === "USD" ? `$${price}` : `${price}€`;
};

export const PlansComponent: React.FC = () => {
    const [modalState, setModalState] = useState({} as IModal);
    const [loading, setLoading] = useState(false);
    const { dataClient, updateClientSupport, refetchData } = useContext(clientContext);

    const [currency, setCurrency] = useState(
        getClientCurrencyOption(dataClient?.currency ?? currenciesOptions[0].value)
    );
    const { push } = useHistory();
    const location = useLocation();

    useEffect(() => {
        const { search } = location;
        const searchValues = search.split("=");
        if (searchValues[0] === "" || searchValues[0] !== "?status") return;
        const searchValue = searchValues[1];
        if (searchValue !== "success" && searchValue !== "failed") return;
        if (searchValue === "success" && !loading && dataClient?.firstName) {
            setModalState({
                show: true,
                title: `Thank you ${dataClient?.firstName}`,
                message: "Your new plan is now activated",
                confirmBtnText: "Back to manager",
                footerData: (
                    <i className="modal-confirmation-footer">
                        You should soon receive a confirmation email
                    </i>
                ),
                onConfirm: () => {
                    setModalState({ ...modalState, show: false });
                    push("/account");
                    refetchData();
                }
            });
        }
        if (searchValue === "failed") {
            setModalState({
                show: true,
                title: `Something went wrong`,
                message: "Please retry or contact an administrator",
                confirmBtnText: "Back to manager",
                onConfirm: () => {
                    setModalState({ ...modalState, show: false });
                    push("plan");
                    refetchData();
                }
            });
        }
    }, [location, dataClient?.firstName]);

    const submitPayableSupportUpdate = async (newPlan: "BASIC" | "PRO") => {
        const loadingModal = {
            title: "Update plan",
            show: true,
            showCancel: false,
            message: `You are about to change yout current plan to a "Pay as you Go" plan ${
                newPlan === "PRO" ? 'with "Support PRO" option' : ""
            }. Please wait...`
        } as IModal;

        try {
            setModalState(loadingModal);
            setLoading(true);
            await updateClientSupport(
                {
                    type: dataClient?.type,
                    address: dataClient?.address,
                    lastName: dataClient?.lastName,
                    firstName: dataClient?.firstName,
                    postalCode: dataClient?.postalCode,
                    city: dataClient?.city,
                    mail: dataClient?.mail,
                    country: dataClient?.country,
                    optIn: dataClient?.optIn,
                    conditions: true,
                    support: newPlan,
                    companyName: dataClient?.companyName
                },
                false
            );
            setLoading(false);
            push("plan?status=success");
        } catch (e) {
            setLoading(false);
            push("plan?status=failed");
        }
    };

    const handleClick = (newPlan: "BASIC" | "PRO") => {
        if (dataClient?.isTrial) {
            push(`plan/trial?pro=${newPlan === "PRO"}&currency=${currency.value}`);
            return;
        }
        setModalState({
            show: true,
            title: "Update plan",
            confirmBtnText: "Confirm",
            message: `You are about to change yout current plan to a "Pay as you Go" plan ${
                newPlan === "PRO" ? 'with "Support PRO" option' : ""
            }. Please confirm.`,
            showCancel: true,
            cancelBtnText: "Cancel",
            onCancel: () => setModalState({ ...modalState, show: false }),
            onConfirm: () => submitPayableSupportUpdate(newPlan)
        });
    };
    return (
        <div className="middle-container">
            <div className="d-flex justify-content-between">
                <h2 className="mb-4">Update your plan</h2>
                {dataClient?.isTrial && (
                    <div className="select-container">
                        <CustomSelect
                            options={currenciesOptions}
                            value={currency}
                            onChange={(v) => {
                                setCurrency(v);
                            }}
                        />
                    </div>
                )}
            </div>
            <div className="plans-container">
                {dataClient?.isTrial && (
                    <PlanCard
                        current={dataClient?.isTrial}
                        title="Trial"
                        price={<PriceComponent type={"trial"} currency={currency.value} />}
                        specifications={
                            <SpecificationsComponent type="trial" currency={currency.value} />
                        }
                        button={<CustomButton buttonText="Your current Plan" disabled={true} />}
                    />
                )}
                <PlanCard
                    current={!dataClient?.isTrial && dataClient?.support !== "PRO"}
                    title="Pay as you Go"
                    price={<PriceComponent type={"pay-as-you-go"} currency={currency.value} />}
                    specifications={
                        <SpecificationsComponent type="basic" currency={currency.value} />
                    }
                    button={
                        <CustomButton
                            buttonText={
                                !dataClient?.isTrial && dataClient?.support !== "PRO"
                                    ? "Your current Plan"
                                    : "Update Plan"
                            }
                            disabled={!dataClient?.isTrial && dataClient?.support !== "PRO"}
                            onClick={() => handleClick("BASIC")}
                        />
                    }
                />
                <PlanCard
                    current={!dataClient?.isTrial && dataClient?.support === "PRO"}
                    title="Pay as you Go"
                    price={<PriceComponent type={"pay-as-you-go"} currency={currency.value} />}
                    specifications={
                        <SpecificationsComponent type="pro" currency={currency.value} />
                    }
                    button={
                        <CustomButton
                            buttonText={
                                dataClient?.support === "PRO" ? "Your current Plan" : "Update Plan"
                            }
                            disabled={!dataClient?.isTrial && dataClient?.support === "PRO"}
                            onClick={() => handleClick("PRO")}
                        />
                    }
                />
            </div>
            <i>* All prices are shown excluding VAT</i>
            <Modal {...modalState} loading={loading} />
        </div>
    );
};
