import React from "react";

import Select from "react-select";

interface ISelect {
    onChange: (e: any, a?: any) => void;
    options: any[];
    value: any;
    placeholder?: string;
    isDisabled?: boolean;
    withLabel?: boolean;
    isInvalid?: boolean;
    labelTranslation?: string;
    isRequired?: boolean;
    id?: string;
    customClass?: string;
    order?: number | null;
}

const customStyle = {
    control: (base: any, state: any) => ({
        ...base,
        border: state.isFocused ? "1px solid #6b7897" : "1px solid #E1E3EA",
        boxShadow: state.isFocused ? "1px solid #6b7897" : "1px solid #E1E3EA",
        fontWeight: "200!important",
        fontSize: "14px",
        height: "40px",
        textTransform: "capitalize",
        "&:hover": {
            border: state.isFocused ? "1px solid #6b7897" : "1px solid #E1E3EA"
        }
    }),
    menu: (base: any, _state: any) => ({
        ...base,
        zIndex: 2
    }),
    option: (base: any, state: any) => ({
        ...base,
        color: state.isSelected ? "white" : "#6b7897",
        fontWeight: "200!important",
        fontSize: "14px",
        textTransform: "capitalize",
        backgroundColor: state.isSelected
            ? "rgba(107, 120, 151, 0.8)"
            : state.isFocused
              ? "rgba(107, 120, 151, 0.2)"
              : "none",
        ":active": {
            ...base[":active"],
            backgroundColor: state.isSelected ? "rgba(107, 120, 151, 0.8)" : "none"
        }
    }),
    singleValue: (base: any, _state: any) => ({
        ...base,
        fontSize: "14px",
        fontWeight: "200!important",
        textTransform: "capitalize",
        color: "#6b7897"
    }),
    placeholder: (base: any, _state: any) => ({
        ...base,
        fontSize: "12px",
        fontWeight: "200!important",
        textTransform: "capitalize",
        color: "#CED3DE",
        fontStyle: "italic"
    })
};

const CustomSelect: React.FC<ISelect> = ({
    onChange,
    options,
    value,
    placeholder,
    isDisabled = false,
    withLabel = false,
    isInvalid = false,
    labelTranslation,
    isRequired = false,
    customClass,
    id,
    order = null
}) => {
    return (
        <div className={`form-group${order ? " form-order-" + order : ""} ${customClass}`}>
            {withLabel && (
                <label className={`mb-2 ${isInvalid && "is-invalid"}`} htmlFor={id}>
                    {labelTranslation}
                    {isRequired && <sup className={`${isInvalid && "is-invalid"}`}>*</sup>}
                </label>
            )}
            <Select
                isSearchable={false}
                styles={customStyle}
                options={options}
                onChange={onChange}
                value={value}
                placeholder={placeholder}
                isDisabled={isDisabled}
            />
        </div>
    );
};

export default CustomSelect;
