import { Fragment, useRef, useEffect, useState } from "react";

import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import { Rect, Transformer, Group } from "react-konva";

import { useCanvasMedia } from "../../../../../hooks/useCanvasMedia";
import {
    Box,
    DetectionClassName,
    DetectionPlate
} from "../../../../../recoil/framesDetectionsCoordinates.atom";
import { DetectionState, rectStyle } from "../../types";

type Props = Konva.RectConfig &
    Omit<DetectionPlate, "trackId" | "src"> &
    DetectionState & {
        onEndCreation?: (data: { data: Box; cn: DetectionClassName }) => void;
        handleClick?: () => void;
        onDetectionHover?: () => void;
        onDetectionHoverLeave?: () => void;
        onMouseDown?: (data: { data: Box }) => void;
        onDragEnd?: (box: Box) => void;
        onTransformEnd?: (box: Box) => void;
    };

export const PlateDetection: React.FC<Props> = (props) => {
    const rectRef = useRef<Konva.Rect>(null!);
    const transformerRef = useRef<Konva.Transformer>(null!);
    const [isHover, setIsHover] = useState(false);
    const isTransforming = useRef(false);
    const [{ dimensionRatio }] = useCanvasMedia();
    useEffect(() => {
        if (!transformerRef.current && props.mode !== "creation") return;
        transformerRef.current.nodes([rectRef.current]);
    }, [props.box, isHover]);

    // https://codesandbox.io/s/github/konvajs/site/tree/master/react-demos/transformer?from-embed=&file=/src/index.js:3043-3053
    const handleTransformEnd = () => {
        isTransforming.current = false;
        const node = rectRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();
        if (props.onTransformEnd) {
            const coordinates = {
                x: node.x(),
                y: node.y(),
                width: Math.max(node.width() * scaleX),
                height: Math.max(node.height() * scaleY)
            };
            props.onTransformEnd(coordinates);
        }
        rectRef?.current?.setAttrs({ scaleX: 1, scaleY: 1 });
    };

    const handleDragEnd = (evt: KonvaEventObject<Event>) => {
        isTransforming.current = false;

        const node = rectRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();
        evt.target.setAttrs({
            width: node.width() * scaleX,
            height: node.height() * scaleY
        });
        if (props.onDragEnd) {
            const coordinates = {
                x: node.x(),
                y: node.y(),
                width: Math.max(node.width() * scaleX),
                height: Math.max(node.height() * scaleY)
            };
            props.onDragEnd(coordinates);
        }
        rectRef?.current?.setAttrs({ scaleX: 1, scaleY: 1 });
    };

    const handleMouseEnter = () => {
        if (props.disabled) return;
        if (props.onDetectionHover) props.onDetectionHover();
    };
    const handleMouseLeave = () => {
        if (props.disabled) return;
        if (props.onDetectionHoverLeave) props.onDetectionHoverLeave();
    };

    const handleMouseEnterGroup = () => {
        setIsHover(true);
    };

    const handleMouseLeaveGroup = () => {
        if (!isTransforming.current) setIsHover(false);
    };
    const handleDragAndTransform = (evt: KonvaEventObject<Event>) => {
        if (props.disabled) return;
        if (!dimensionRatio) return;
        const { x, y } = evt.currentTarget.position();
        if (x < dimensionRatio.centerShift_x)
            evt.currentTarget.setAttr("x", dimensionRatio.centerShift_x);
        else if (
            x + evt.currentTarget.width() >
            dimensionRatio.centerShift_x + dimensionRatio.frameWidth
        ) {
            evt.currentTarget.setAttr(
                "width",
                dimensionRatio.centerShift_x + dimensionRatio.frameWidth - x
            );
            evt.currentTarget.setAttr("x", x);
        } else if (y < dimensionRatio.centerShift_y)
            evt.currentTarget.setAttr("y", dimensionRatio.centerShift_y);
        else if (
            y + evt.currentTarget.height() >
            dimensionRatio.centerShift_y + dimensionRatio.frameHeight
        ) {
            evt.currentTarget.setAttr(
                "height",
                dimensionRatio.centerShift_y + dimensionRatio.frameHeight - y
            );
            evt.currentTarget.setAttr("y", y);
        }
        isTransforming.current = true;
    };

    const handleClick = () => {
        if (props.disabled) return;
        if (props.handleClick) props.handleClick();
    };

    return (
        <Fragment>
            <Group
                draggable={!props.disabled}
                onMouseEnter={handleMouseEnterGroup}
                onMouseLeave={handleMouseLeaveGroup}
            >
                <Rect
                    {...rectStyle[props.mode]}
                    x={props.box.x}
                    y={props.box.y}
                    height={props.box.height}
                    width={props.box.width}
                    ref={rectRef}
                    strokeScaleEnabled={false}
                    strokeWidth={1}
                    onMouseEnter={handleMouseEnter}
                    draggable={!props.disabled}
                    onMouseLeave={handleMouseLeave}
                    onDragMove={handleDragAndTransform}
                    onTransformEnd={handleTransformEnd}
                    onDragEnd={handleDragEnd}
                    onClick={handleClick}
                />
                {(props.transformerVisible || isHover || isTransforming.current === true) && (
                    <Transformer
                        ref={transformerRef}
                        anchorStroke={rectStyle[props.mode].stroke}
                        anchorFill={rectStyle[props.mode].fill}
                        anchorSize={4}
                        draggable={!props.disabled}
                        borderStrokeWidth={1}
                        flipEnabled={false}
                        rotateEnabled={false}
                        borderStroke="transparent"
                        onDragMove={handleDragAndTransform}
                        onTransform={handleDragAndTransform}
                        keepRatio={false}
                    />
                )}
            </Group>
        </Fragment>
    );
};
