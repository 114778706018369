import React, { useContext, useMemo, useState } from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { licensesContext } from "../../../context/licensesContext";
import { useHeader } from "../../../hook/useHeader";
import { TASKS_UNAVAILABLE_TYPES, serviceUsableState } from "../../../recoil/services.atom";
import { ReactComponent as LogoWIS } from "../../../static/images/logo-Blurit.svg";
import { ErrorHeaderCardExpired } from "../../error/ErrorHeaderCardExpired";

// Style
import "./menuMobile.scss";

// Logo
import { ErrorHeaderPluginDisabled } from "../../error/ErrorHeaderPluginDisabled";
import { ErrorHeaderPluginTrial } from "../../error/ErrorHeaderPluginTrial";
import Modal, { IModal } from "../../modal/Modal";

type Menu = {
    active: boolean; // Get
    setActive: React.Dispatch<React.SetStateAction<boolean>>; // Set
};

const MenuMobilePlugin: React.FC<Menu> = ({ active, setActive }) => {
    const { t } = useTranslation();
    const { handleLogout } = useHeader();
    const [modalState, setModalState] = useState({} as IModal);
    const serviceUsable = useRecoilValue(serviceUsableState);
    const { data: licenses } = useContext(licensesContext);
    const today = moment();

    const trialEndingDate = useMemo(() => {
        if (
            !licenses ||
            !licenses.length ||
            moment.utc(licenses[0].dateStart).add(7, "days") <= today
        ) {
            return null;
        }

        const startingDate = moment.utc(licenses[0].dateStart);
        const endOfTrial = startingDate.add(7, "days");
        const diffDays = endOfTrial.diff(today, "days") + 1;

        switch (diffDays) {
            case 0:
                return t("date.today");
            case 1:
                return t("date.tomorrow");
            default:
                return t("date.in", { count: diffDays });
        }
    }, [licenses, today]);

    const pluginUsable = useMemo(() => !licenses?.find((license) => license.blocked), [licenses]);

    const onClickLogout = () => {
        setModalState({
            show: true,
            title: t("logout.title"),
            message: t("logout.message"),
            cancelBtnText: t("logout.cancel"),
            confirmBtnText: t("logout.quit"),
            onCancel: () => setModalState({ ...modalState, show: false }),
            onConfirm: handleLogout,
            showCancel: true
        });
    };

    const navBarItems = [
        {
            linkTo: "/plugin/ressources",
            title: t("navbar.ressources"),
            icon: "icon-ressources",
            translation: t("navbar.ressources")
        },
        {
            linkTo: "/plugin/account",
            title: t("navbar.account"),
            icon: "icon-account",
            translation: t("navbar.account")
        }
    ];

    return (
        <div
            className={`menu-mobile ${active ? "show" : ""}`}
            aria-hidden={!active}
            tabIndex={active ? 0 : -1}
        >
            <div className="menu-mobile-header">
                <div className="logo">
                    <LogoWIS />
                </div>

                <button
                    type="button"
                    className="button btn-menu active"
                    onClick={() => setActive(!active)}
                ></button>
            </div>

            <div className="menu-mobile-body">
                <nav role="navigation">
                    <ul>
                        {navBarItems.map((element, key) => (
                            <li key={key}>
                                <NavLink
                                    to={element.linkTo}
                                    className={element.icon}
                                    activeClassName="active"
                                    onClick={() => setActive(!active)}
                                >
                                    {element.title}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </nav>

                {!serviceUsable.status &&
                    serviceUsable.reason === TASKS_UNAVAILABLE_TYPES.CARD_EXPIRED && (
                        <div className="trial trial-error">
                            <ErrorHeaderCardExpired />
                        </div>
                    )}

                {serviceUsable.status && !pluginUsable && (
                    <div className="trial">
                        <ErrorHeaderPluginDisabled />
                    </div>
                )}

                {/* We display service usable error before endingDate */}
                {serviceUsable.status && pluginUsable && trialEndingDate && (
                    <div className="mt-5">
                        <ErrorHeaderPluginTrial endingDate={trialEndingDate} />
                    </div>
                )}
            </div>

            <div className="menu-mobile-footer">
                <button type="button" className="button btn-deco" onClick={onClickLogout}>
                    {t("logout.quit")}
                </button>

                <p className="copyright">© {moment().year()} Wassa - All right reserved</p>
            </div>
            <Modal {...modalState} />
        </div>
    );
};

export default MenuMobilePlugin;
