import React, { createContext, useReducer, Dispatch, useRef } from "react";

type AcceptedFilesType = string | "*";

export type DragNDropState = {
    file?: File;
    dragging: boolean;
    accepts: AcceptedFilesType;
    maxSize?: number;
    error?: {
        title?: string;
        message: string;
    };
    inputRef: React.RefObject<HTMLInputElement> | null;
};

const initialState: DragNDropState = {
    dragging: false,
    accepts: "*",
    inputRef: null
};

export enum ACTION_TYPE {
    SET_FILE,
    SET_DRAG,
    SET_ERROR,
    CLEAR
}

export type ActionTypes =
    | { type: ACTION_TYPE.SET_FILE; payload: File }
    | { type: ACTION_TYPE.SET_DRAG; payload: boolean }
    | {
          type: ACTION_TYPE.SET_ERROR;
          payload: {
              title?: string;
              message: string;
          };
      }
    | { type: ACTION_TYPE.CLEAR; payload?: any };

export type DragNDropContext = {
    state: DragNDropState;
    dispatch: Dispatch<ActionTypes>;
};

const dragNDropContext = createContext<DragNDropContext>({
    state: initialState,
    dispatch: () => {}
});

const reducer = (state: DragNDropState, actions: ActionTypes) => {
    const { type } = actions;
    switch (type) {
        case ACTION_TYPE.SET_FILE: {
            return {
                ...state,
                file: actions.payload
            };
        }
        case ACTION_TYPE.SET_DRAG: {
            return {
                ...state,
                dragging: actions.payload
            };
        }
        case ACTION_TYPE.SET_ERROR: {
            return {
                ...state,
                error: actions.payload
            };
        }
        case ACTION_TYPE.CLEAR: {
            return initialState;
        }
        default:
            return state;
    }
};

type DragNDropProviderProps = {
    accepts?: AcceptedFilesType;
    maxSize?: number;
};

type PropsWithChildren<P> = P & { children?: React.ReactNode };
/**
 *
 *
 * @param accetps The mimeType of accepeted files to be dropped in the dropzone.
 * Default: '*'
 * Example: ['image/png', 'video/mp4']
 *
 * @returns
 */
const DragNDropProvider: React.FC<PropsWithChildren<DragNDropProviderProps>> = ({
    children,
    accepts = "*",
    maxSize
}) => {
    const [state, dispatch] = useReducer(reducer, { ...initialState, accepts, maxSize });
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <dragNDropContext.Provider value={{ state: { ...state, inputRef }, dispatch }}>
            {children}
        </dragNDropContext.Provider>
    );
};

export { dragNDropContext, DragNDropProvider };
