import { selectorFamily, useRecoilValue } from "recoil";

import { autoDetectionsAtom } from "./useAutoDetections";
import { trackPreviewAtom } from "./useTrackPreview";
import { DetectionCoordinatesRange, Track1, tracksAtom } from "./useTracks";
import { mediaAtom } from "../hooks/useMedia";
import { Box, Point } from "../recoil/framesDetectionsCoordinates.atom";

export type CurrentFrameDetectionState = {
    box: Box;
    ldm: Point[];
    cn: "head" | "plate" | "other";
    trackId: number;
    active: boolean;
};

export const frameDetectionsSelector = selectorFamily({
    key: "frameDetectionsSelector",
    get:
        (withPreviewDetection: boolean = true) =>
        ({ get }) => {
            const { currentFrame } = get(mediaAtom);
            const { tracks } = get(tracksAtom);
            const autoDetections = get(autoDetectionsAtom);
            const trackPreview = get(trackPreviewAtom);
            const currentAutoDetectionsFrame = autoDetections.frames[currentFrame];
            const tracksTmp = [...tracks];
            // function to find the correct range in the ranges array
            const _getCurrentRangeInTracks = (track: Track1) =>
                track.initialRange.find(
                    (d: DetectionCoordinatesRange) =>
                        currentFrame >= d.frameStart && currentFrame <= d.frameEnd
                );
            const _getSavedRangeInTracks = (track: Track1) =>
                track.savedRange.find(
                    (d: DetectionCoordinatesRange) =>
                        currentFrame >= d.frameStart && currentFrame <= d.frameEnd
                );
            return tracksTmp
                .filter((track) => {
                    const condition =
                        currentFrame >= track.frameStart && currentFrame <= track.frameEnd;
                    if (!withPreviewDetection && trackPreview)
                        return condition && trackPreview.trackId !== track.trackId;
                    return condition;
                })
                .reduce((acc, curr) => {
                    const frameInRange = _getCurrentRangeInTracks(curr);
                    const savedRange = _getSavedRangeInTracks(curr);
                    if (savedRange) {
                        return [
                            ...acc,
                            {
                                box: savedRange.box,
                                ldm: savedRange.ldm,
                                cn: curr.cn,
                                trackId: curr.trackId,
                                active: curr.active
                            }
                        ];
                    }
                    if (frameInRange) {
                        // if frame is in range return the data from the range
                        return [
                            ...acc,
                            {
                                box: frameInRange.box,
                                ldm: frameInRange.ldm,
                                cn: curr.cn,
                                trackId: curr.trackId,
                                active: curr.active
                            }
                        ];
                    }
                    // if frame is not in range and is in auto detection return auto detection data
                    const autoDetections = currentAutoDetectionsFrame?.find(
                        (detection) => detection.trackId === curr.trackId
                    );
                    if (autoDetections) {
                        return [
                            ...acc,
                            {
                                box: autoDetections.box,
                                ldm: autoDetections.ldm,
                                cn: curr.cn,
                                trackId: curr.trackId,
                                active: curr.active
                            }
                        ];
                    }
                    return acc;
                }, [] as CurrentFrameDetectionState[]);
        }
});

export const useCurrentFrameDetections = (withPreviewDetection: boolean = true) => {
    const frameDetections = useRecoilValue(frameDetectionsSelector(withPreviewDetection));
    return frameDetections;
};
