import { ForwardedRef, useCallback, useEffect, useState } from "react";

import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import { useRecoilValue } from "recoil";

import { canvasDrawModeAtom } from "../../../../recoil/canvasDrawMode.atom";
import {
    Box,
    DetectionClassName,
    Point
} from "../../../../recoil/framesDetectionsCoordinates.atom";

export type DrawBox = {
    isDrawing: true;
    shape: DetectionClassName.Head | DetectionClassName.Plate;
    box: Box;
};
export type DrawPoints = {
    isDrawing: true;
    shape: DetectionClassName.Other;
    points: Point[];
    cursorPosition: Point;
};

export const isDrawBox = (data: unknown): data is DrawBox => {
    return !!(data as DrawBox).box;
};
export const isDrawPoints = (data: unknown): data is DrawPoints => {
    return !!(data as DrawPoints).points;
};

type DrawTypeState = { isDrawing: false } | DrawBox | DrawPoints;

export const useStageDraw = (
    ref: ForwardedRef<Konva.Stage>
): [DrawTypeState, { removeShape: () => void }] => {
    const [drawingState, setDrawingState] = useState<DrawTypeState>({ isDrawing: false });
    const canvasDrawModeState = useRecoilValue(canvasDrawModeAtom);

    const handleMouseDown = useCallback(
        (e: KonvaEventObject<MouseEvent>) => {
            if (!canvasDrawModeState) {
                setDrawingState({ isDrawing: false });
                return;
            } else {
                const stage = e.target.getStage();
                const pointer = stage?.getPointerPosition();
                if (!pointer || !stage) return;
                const { x, y } = pointer;

                if (canvasDrawModeState === DetectionClassName.Head) {
                    setDrawingState({
                        isDrawing: true,
                        shape: DetectionClassName.Head,
                        box: { height: 0, width: 0, x: x / stage.scaleX(), y: y / stage.scaleY() }
                    });
                } else if (canvasDrawModeState === DetectionClassName.Plate) {
                    setDrawingState({
                        isDrawing: true,
                        shape: DetectionClassName.Plate,
                        box: { height: 0, width: 0, x: x / stage.scaleX(), y: y / stage.scaleY() }
                    });
                } else return;
            }
        },
        [canvasDrawModeState]
    );

    const handleMouseMove = useCallback(
        (e: KonvaEventObject<MouseEvent>) => {
            if (!canvasDrawModeState || !drawingState.isDrawing) return;
            else {
                const stage = e.target.getStage();
                const pointer = stage?.getPointerPosition();
                if (!stage || !pointer) return;
                const { x, y } = pointer;
                const scaledX = x / stage.scaleX();
                const scaledY = y / stage.scaleY();

                if (canvasDrawModeState === DetectionClassName.Head) {
                    setDrawingState((prev) => {
                        if (!isDrawBox(prev)) return prev;
                        const sx = prev.box.x;
                        const sy = prev.box.y;
                        return {
                            ...prev,
                            box: {
                                x: sx,
                                y: sy,
                                width: x / stage.scaleX() - sx,
                                height: y / stage.scaleY() - sy
                            }
                        };
                    });
                } else if (canvasDrawModeState === DetectionClassName.Plate) {
                    setDrawingState((prev) => {
                        if (!isDrawBox(prev)) return prev;
                        const sx = prev.box.x;
                        const sy = prev.box.y;
                        return {
                            ...prev,
                            box: {
                                x: sx,
                                y: sy,
                                width: x / stage.scaleX() - sx,
                                height: y / stage.scaleY() - sy
                            }
                        };
                    });
                } else if (canvasDrawModeState === DetectionClassName.Other) {
                    setDrawingState((prev) => {
                        return {
                            ...prev,
                            points: (prev as DrawPoints).points,
                            cursorPosition: { x: scaledX, y: scaledY }
                        };
                    });
                }
            }
        },
        [drawingState.isDrawing]
    );

    const handleClick = useCallback(
        (e: KonvaEventObject<MouseEvent>) => {
            if (!canvasDrawModeState || canvasDrawModeState !== DetectionClassName.Other) return;

            const stage = e.target.getStage();
            const pointer = stage?.getPointerPosition();
            if (!stage || !pointer) return;
            const { x, y } = pointer;
            const scaledCursorX = x / stage.scaleX();
            const scaledCursorY = y / stage.scaleY();
            if (canvasDrawModeState === DetectionClassName.Other) {
                setDrawingState((prev) => {
                    if (!prev.isDrawing) {
                        return {
                            points: [{ x: scaledCursorX, y: scaledCursorY }],
                            shape: DetectionClassName.Other,
                            isDrawing: true,
                            cursorPosition: { x: scaledCursorX, y: scaledCursorY }
                        };
                    } else {
                        const prevPoints = [...(prev as DrawPoints).points];
                        return {
                            ...prev,
                            points: [...prevPoints, { x: scaledCursorX, y: scaledCursorY }],
                            cursorPosition: { x: scaledCursorX, y: scaledCursorY }
                        };
                    }
                });
            }
        },
        [canvasDrawModeState]
    );

    useEffect(() => {
        if (!ref || typeof ref === "function") return;
        const instance: Konva.Stage | null = ref.current;
        if (!instance) return;
        instance.on("mousedown", handleMouseDown);
        instance.on("mousemove", handleMouseMove);
        instance.on("click", handleClick);
        return () => {
            instance.off("mousedown");
            instance.off("mousemove");
            instance.off("click");
        };
    }, [ref, handleMouseDown, handleMouseMove, handleClick, canvasDrawModeState]);

    useEffect(() => {
        const handleKeyDown = (ev: KeyboardEvent) => {
            if (drawingState.isDrawing && ev.key === "Escape") {
                removeShape();
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [drawingState.isDrawing]);

    const removeShape = () => {
        setDrawingState({ isDrawing: false });
    };
    return [drawingState, { removeShape }];
};
