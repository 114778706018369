import React from "react";

import { randomKeyGen } from "../../services/tools";
import "../../static/scss/toggle.scss";

interface IToggle {
    isChecked: boolean;
    onClick: () => void;
    disabled?: boolean;
}

const Toggle = ({ isChecked, onClick, disabled }: IToggle) => {
    const key = randomKeyGen();

    return (
        <label className="switch" htmlFor={"checkbox-" + key}>
            <input
                disabled={disabled}
                checked={isChecked}
                id={"checkbox-" + key}
                type="checkbox"
                onChange={onClick}
            />
            <div className="slider round"></div>
        </label>
    );
};

export default Toggle;
