import { AxiosProgressEvent } from "axios";
import FormData from "form-data";

import { postData, putData } from "../services/axios.services";
import {
    AskAnonymizationResponse,
    FormAskAnonymization,
    FormAskRendering,
    FormUpdateDetections
} from "../types";

export const askAnonymizationJobId = async (
    params: FormAskAnonymization,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void
) => {
    const form = new FormData();
    Object.keys(params).forEach((key: string) => {
        if ((params as Record<string, unknown>)[key] instanceof File) {
            form.append(key, (params as Record<string, unknown>)[key]);
        } else {
            if ((params as Record<string, unknown>)[key] !== undefined)
                form.append(key, (params as Record<string, unknown>)[key]);
        }
    });
    return (
        await postData<AskAnonymizationResponse>({
            url: "/task-management/anonymization",
            data: form,
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: onUploadProgress
        })
    ).data;
};

export const askAnonymizationRendering = async (
    params: FormAskRendering,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void
) => {
    return postData({
        url: `/task-management/anonymization/${params.jobId}/render`,
        data: { framesSorted: params.framesSorted, with_mail: params?.with_mail },
        headers: { "Content-Type": "application/json" },
        onUploadProgress: onUploadProgress
    });
};

export const updateAnonymizatioDetections = async (params: FormUpdateDetections) => {
    return putData({
        url: `/task-management/anonymization/${params.jobId}/save_detections`,
        data: { framesSorted: params.framesSorted },
        headers: { "Content-Type": "application/json" }
    });
};
