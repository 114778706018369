import React from "react";

import "../../static/scss/error.scss";
import { useTranslation } from "react-i18next";

import { ReactComponent as AlertSvg } from "../../static/icons/account/icon_alert.svg";

export const ErrorAccountCardExpired: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="error-card-expired-account">
            <AlertSvg height={20} className="me-2" />
            <span className="invalid">{t("account-management.expired-card")}</span>
        </div>
    );
};
