import { ReactComponent as RoundLoaderSvg } from "../../static/icons/round-loader.svg";
import "./loader.scss";

export const RoundedLoader = ({ isLoading = false, size = 30, margin = "auto" }) => {
    if (!isLoading) {
        return null;
    }

    return (
        <div className="rounded-loader-animated">
            <RoundLoaderSvg height={size} width={size} style={{ margin }} />
        </div>
    );
};
