/* eslint-disable react/jsx-key */
import { Trans } from "react-i18next";
import "../../static/scss/error.scss";

export const ErrorHeaderServiceDisabled = () => {
    return (
        <div className="error">
            <span className="color-error">
                <Trans
                    i18nKey="header.error-service-deactivated"
                    values={{ who: "support@blurit.io" }}
                    components={[<a href="mailto:support@blurit.io" className="underline" />]}
                />
            </span>
        </div>
    );
};
