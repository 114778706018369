import React, { createContext, useCallback, useEffect, useState } from "react";

import { useAxios } from "../services/hook/requestsService";
import { MyError, isMyError } from "../types/error";
import { License } from "../types/license";

export type LicenseState = {
    error: MyError | null;
    loading: boolean;
    data: License[] | null;
    refetch: boolean;
    getLicenses: () => void;
    updateLicenseAutomaticRenewal: (id: string, values: { automaticRenewal: boolean }) => void;
    refetchData: () => void;
};

const initialState: LicenseState = {
    error: null,
    loading: false,
    data: null,
    refetch: false,
    getLicenses: () => null,
    updateLicenseAutomaticRenewal: () => null,
    refetchData: () => null
};

const licensesContext = createContext<LicenseState>(initialState);

const LicensesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, setState] = useState(initialState);
    const axiosActions = useAxios();

    const getLicenses = useCallback(async () => {
        try {
            if (!state.data || state.refetch) {
                setState((prev) => ({
                    ...prev,
                    loading: true
                }));
                const { data } = await axiosActions.getData(null, "license");
                setState((prev) => ({
                    ...prev,
                    refetch: false,
                    loading: false,
                    data
                }));
            }
        } catch (e) {
            console.error(e);
            if (isMyError(e)) {
                setState((prev) => ({
                    ...prev,
                    loading: false,
                    error: e as MyError,
                    refetch: false
                }));
            }
        }
    }, [state.refetch, state.data]);

    const updateLicenseAutomaticRenewal = async (
        uuid: string,
        values: { automaticRenewal: boolean }
    ): Promise<void> => {
        try {
            setState((prev) => ({
                ...prev,
                loading: true
            }));
            await axiosActions.putData(null, `license/${uuid}/automatic-renewal`, values);
            setState((prev) => ({
                ...prev,
                loading: false
            }));
            refetchData();
        } catch (e) {
            console.error(e);
            if (isMyError(e)) {
                setState((prev) => ({
                    ...prev,
                    loading: false,
                    error: e as MyError,
                    refetch: false
                }));
            }
        }
    };

    useEffect(() => {
        getLicenses();
    }, [state.refetch, state.data]);

    const refetchData = () => {
        setState((prev) => ({ ...prev, refetch: true }));
    };

    return (
        <licensesContext.Provider
            value={{
                ...state,
                getLicenses,
                refetchData,
                updateLicenseAutomaticRenewal
            }}
        >
            {children}
        </licensesContext.Provider>
    );
};

export { licensesContext, LicensesProvider };
