import { RefObject, useEffect, useRef, useState } from "react";

import Konva from "konva";
import { Layer, Stage } from "react-konva";
import { useRecoilValue } from "recoil";

import { ShapesDrawer } from "./components/drawer/ShapesDrawer";
import { CreatingDetectionPreview } from "./components/renderDetections/CreatingDetectionPreview";
import { ReadableDetections } from "./components/renderDetections/ReadableDetections";
import { TrackPreviewDetection } from "./components/renderDetections/TrackPreviewDetection";
import { useHandleClickOutsideCanvasContainer } from "./hooks/useHandleClickOutsideCanvasContainer";
import { blurPreviewAtom } from "../../../recoil/blurPreview.atom";

type Size = {
    width: number;
    height: number;
};

const useGetStageDimension = (ref: RefObject<HTMLDivElement>) => {
    const stageWidth = 1920;
    const stageHeight = 1080;
    const [stageDimension, setStageDimension] = useState<Size>();
    const scaleX = (stageDimension?.width ?? 0) / stageWidth;
    const scaleY = (stageDimension?.height ?? 0) / stageHeight;

    useEffect(() => {
        const handleStageDimension = () => {
            if (ref.current?.offsetWidth && ref.current?.offsetHeight) {
                setStageDimension({
                    width: ref.current.offsetWidth,
                    height: ref.current.offsetHeight
                });
            }
        };
        handleStageDimension();
        window.addEventListener("resize", handleStageDimension);
        return () => {
            window.removeEventListener("resize", handleStageDimension);
        };
    }, []);

    return {
        scaleX,
        scaleY,
        ...stageDimension
    };
};

export const CanvasDetections = () => {
    const parentRef = useRef<HTMLDivElement>(null);
    const stageDimension = useGetStageDimension(parentRef);
    useHandleClickOutsideCanvasContainer(parentRef);
    const stageRef = useRef<Konva.Stage>(null!);
    const blurPreviewState = useRecoilValue(blurPreviewAtom);
    const [isDrawing, setIsDrawing] = useState(false);
    // const scaleBy = 1.01;

    // useEffect(() => {
    //     if (!stageRef.current) return;
    //     const stage = stageRef.current;

    //     const handleWeel: KonvaEventListener<Konva.Stage, any> = (e) => {
    //         e.evt.preventDefault();
    //         const oldScale = stage.scaleX();
    //         const pointer = stage.getPointerPosition();
    //         if (!pointer || !oldScale) return;
    //         var mousePointTo = {
    //             x: (pointer.x - stage.x()) / oldScale,
    //             y: (pointer.y - stage.y()) / oldScale,
    //         };
    //         let direction = e.evt.deltaY > 0 ? 1 : -1;
    //         if (e.evt.ctrlKey) {
    //             direction = -direction;
    //         }
    //         var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;
    //         stage.scale({ x: newScale, y: newScale });
    //         var newPos = {
    //             x: pointer.x - mousePointTo.x * newScale,
    //             y: pointer.y - mousePointTo.y * newScale,
    //         };
    //         stage.position(newPos);
    //     };

    //     stage.on('weel', handleWeel);
    //     return () => {
    //         stage.off('weel', handleWeel);
    //     };
    // }, [stageRef.current]);

    return (
        <div
            className={`canvas-detections`}
            style={{ opacity: blurPreviewState ? 0 : 1 }}
            ref={parentRef}
        >
            <Stage
                ref={stageRef}
                scale={{ x: stageDimension.scaleX, y: stageDimension.scaleY }}
                width={stageDimension.width}
                height={stageDimension.height}
            >
                <Layer>
                    {/* Draw shapes component */}
                    <ShapesDrawer
                        ref={stageRef}
                        isDrawing={isDrawing}
                        setIsDrawing={setIsDrawing}
                    />
                    {/* Detections on readable state */}
                    <ReadableDetections isDrawing={isDrawing} />
                    {/* Detection on creation preview */}
                    <CreatingDetectionPreview />
                    {/* Detection on track preview */}
                    <TrackPreviewDetection isDrawing={isDrawing} />
                </Layer>
            </Stage>
        </div>
    );
};
