export enum FileType {
    Video = "VIDEO",
    Picture = "PICTURE",
    Bulk = "BULK"
}

export enum TaskStatusEnum {
    SENT = "Sent",
    STARTED = "Started",
    SUCCEEDED = "Succeeded",
    FAILED = "Failed",
    UPLOAD_FAILED = "Upload failed",
    WRONG_INPUT = "Wrong input",
    EDITABLE = "Editable"
}

export enum HistoryCallServiceMode {
    AUTO = "auto",
    DETECTION = "detection",
    RENDER = "render"
}

export enum ServiceSearchKey {
    ANONYMIZATION = "SRV_ANM",
    WATERMARK = "SRV_WATERMARK",
    FACES_ATTRIBUTES = "SRV_FACE"
}

export enum ServiceMode {
    AUTO = "Automatic",
    MANUAL = "Studio"
}

export type MediaMetada = {
    nbFrames?: number;
    frameRate?: number;
};

export type FormattedHistoryCall = {
    job_id: string;
    fileurl: string;
    status: TaskStatusEnum | "Deleted";
    service_search_key: ServiceSearchKey;
    service_name: string;
    file_type: FileType;
    size: string;
    filename: string;
    archive_sucess_with_error: boolean;
    input_filename: string;
    client: string;
    date: Date;
    parameters: {
        activation_faces_blur: boolean | undefined;
        activation_plates_blur: boolean | undefined;
        output_detections_url: boolean | undefined;
        with_mail: boolean | undefined;
    };
    total_count: string;
    mode: HistoryCallServiceMode;
    metadata: MediaMetada;
};

export enum ListAPIServicesEnum {
    anonymization = "SRV_ANM",
    congestion = "SRV_CNG",
    facelytics = "SRV_FACE",
    soiling = "SRV_SOL",
    watermark = "SRV_WATERMARK",
    vehiclesPedestrianDetctn = "SRV_VHC_PDS_DETECT",
    orientation = "SRV_ORIENTATION",
    identity = "SRV_IDENTITY"
}

export type AllowSortOrder = "ASC" | "asc" | "DESC" | "desc" | string;

export type GetManyHistoryCallsRequest = {
    searchKey?: ListAPIServicesEnum[];
    fileType?: FileType;
    dateEnd?: Date;
    dateStart?: Date;
    sortOrder?: AllowSortOrder;
    sortBy?: string;
    jobId?: string;
};

export type GetManyHistoryCallsResponse = { elements: FormattedHistoryCall[] };
