import React, { useContext, useState } from "react";

import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import WatermarkTask from "./pages/watermarkTask";
import { DragNDropProvider } from "../../components/dragNDrop/DragNDropContext";
import Modal, { IModal } from "../../components/modal/Modal";
import { taskManagementContext } from "../../context/TaskManagementContext";
import { getError } from "../../services/errorsService";
import { convertSize } from "../../services/tools";
import { Anonymization } from "./pages/anonymization/Anonymization";

export type TaskComponentProps = {
    handleCancel: () => void;
    handleConfirm: (
        serviceName: string,
        fileSize: number,
        callBack: any,
        withMailSuggestion?: boolean
    ) => void;
    handleError: (e?: any, customError?: string) => void;
    setProgressUpload?: any;
};

const TaskFactory: React.FC = () => {
    const taskManagementStore = useContext(taskManagementContext);

    const { searchKey } = useParams<{ searchKey: string }>();
    const { push } = useHistory();
    const [progress, setProgress] = useState<number | null>(null);
    const [modalState, setModalState] = useState({} as IModal);
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();

    const handleCancel = () => {
        setModalState({
            show: true,
            showCancel: true,
            message: t("task-management.tasks.modal.cancel.message"),
            onConfirm: () => {
                setModalState({ ...modalState, show: false });
                push("/task-manager");
            },
            onCancel: () => setModalState({ ...modalState, show: false }),
            confirmBtnText: t("task-management.tasks.modal.cancel.accept-button"),
            cancelBtnText: t("task-management.tasks.modal.cancel.cancel-button")
        });
    };

    const handleError = (error?: AxiosError) => {
        setIsLoading(false);
        setProgress(null);
        if (error?.response?.status === 400 && (error?.response?.data as any).message) {
            setModalState({
                ...modalState,
                show: true,
                title:
                    (error.response.data as any).message ===
                    "Your zip file must not contain subdirectories."
                        ? "Unsupported zip"
                        : t("error.error-title"),
                message: (error.response.data as any).message,
                onConfirm: () => setModalState({ ...modalState, show: false, showCancel: false }),
                showCancel: false
            });
            return;
        } else if (error) {
            const { message, title } = getError(error, t);
            const isTrialLimitation = (error?.response?.data as any)?.message === "trialLimitation";

            const onConfirm = () => {
                isTrialLimitation && push("/account/plan");
                setModalState({ ...modalState, show: false, showCancel: false });
            };

            setModalState({
                ...modalState,
                show: true,
                title: title || t("error.error-title"),
                message: message,
                onConfirm: onConfirm,
                confirmBtnText: isTrialLimitation ? t("modal.update-account") : t("modal.confirm"),
                showCancel: isTrialLimitation,
                onCancel: () => setModalState({ ...modalState, show: false, showCancel: false })
            });
            return;
        } else {
            setModalState({
                ...modalState,
                show: true,
                message: t("error.internalError"),
                onConfirm: () => setModalState({ ...modalState, show: false }),
                showCancel: false
            });
            return;
        }
    };

    const handleConfirm = (serviceName: string, fileSize: number, callBack: any) => {
        setModalState({
            show: true,
            showCancel: true,
            message: t("task-management.tasks.modal.confirm.message", {
                serviceName,
                fileSize: convertSize(fileSize)
            }),
            onConfirm: async (props: any) => {
                try {
                    setModalState({ ...modalState, withCheckBox: false, show: true });
                    setIsLoading(true);
                    await callBack(props);
                    setModalState({
                        ...modalState,
                        show: false,
                        loading: false
                    });
                    taskManagementStore.handleRefetch(true);
                    push("/task-manager");
                } catch (e) {
                    //@ts-ignore
                    handleError(e);
                }
            },
            onCancel: () => setModalState({ ...modalState, show: false }),
            confirmBtnText: t("task-management.tasks.modal.confirm.accept-button"),
            cancelBtnText: t("task-management.tasks.modal.confirm.cancel-button"),
            withCheckBox: true
        });
    };

    return (
        <div className="task-manager__container">
            {
                {
                    anonymization: (
                        <DragNDropProvider
                            maxSize={2147483648} // 2GB
                            accepts={
                                "image/jpeg, image/jpg, image/png, image/webp, application/zip, video/mp4, application/x-zip-compressed, video/avi, .mkv, .MKV, video/quicktime, video/x-msvideo"
                            }
                        >
                            <Anonymization />
                        </DragNDropProvider>
                    ),
                    watermark: (
                        <WatermarkTask
                            handleCancel={handleCancel}
                            handleConfirm={handleConfirm}
                            handleError={handleError}
                            setProgressUpload={setProgress}
                        />
                    )
                }[searchKey]
            }
            <Modal
                {...modalState}
                progress={progress}
                loading={isLoading}
                message={
                    isLoading
                        ? t("task-management.tasks.modal.loading-message")
                        : modalState.message
                }
            />
        </div>
    );
};

export default TaskFactory;
