import "./i18next";
import App from "./App";

import "bootstrap/dist/css/bootstrap.css";
import "./static/scss/style.scss";
import { createRoot } from "react-dom/client";
import "./api/axios";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({ defaultOptions: { queries: { retry: 1 } } });

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);
root.render(
    <QueryClientProvider client={queryClient}>
        <App />
    </QueryClientProvider>
);
