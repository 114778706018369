import { useMemo, useState } from "react";

import { useSetRecoilState } from "recoil";

import { ArrowInput } from "./components/ArrowInput";
import { MiniModalDeleteConfirmation } from "./components/MiniModalDeleteConfirmation";
import { Checkbox } from "../../../../../../../../components/checkbox/checkbox";
import { useCanvasMedia } from "../../../../hooks/useCanvasMedia";
import { useFramesTimestamps } from "../../../../hooks/useFramesTimestamp";
import { isMediaVideo, MediaVideo, useMedia } from "../../../../hooks/useMedia";
import { useTrackPreview } from "../../../../hooks/useTrackPreview";
import { useTracks } from "../../../../hooks/useTracks";
import { trackEditorAtom } from "../../../../recoil/trackEditor.atom";
import { timestampToReadable } from "../../../mediaControls/controls/MediaData";
import { rectStyle } from "../../../mediaVisualizer/canvasDetections/types";
import { Thumb } from "../../Thumb";
import { useCards } from "../hook/useCards";

export const PreviewCard: React.FC<{ id: string }> = ({ id }) => {
    const [{ framesTimestamps }] = useFramesTimestamps();
    const [, { closeCard }] = useCards();
    const setTrackEditorState = useSetRecoilState(trackEditorAtom);
    const [openModal, setOpenModal] = useState(false);
    const [, { removeTrack, updateTrack }] = useTracks();
    const [p, { updateTrackPreview, removeTrackPreview }] = useTrackPreview();
    const [mediaState] = useMedia<MediaVideo>();
    const { nbFrames } = mediaState;
    const [, { cropImage }] = useCanvasMedia();

    const initialValues = useMemo(() => {
        if (!p) return undefined;
        else return p;
    }, []);

    if (!p) return null;

    const frameTsStart = framesTimestamps[p.frameStart];
    const frameTsEnd = framesTimestamps[p.frameEnd];

    const handleCancel = () => {
        if (initialValues) {
            setTrackEditorState((prev) =>
                prev
                    ? { ...prev, min: initialValues.frameStart, max: initialValues.frameEnd }
                    : undefined
            );
            updateTrackPreview(initialValues);
        }
    };

    const handleClickClose = () => {
        // close the card (and remove it from cards array)
        closeCard(id);
        // remove track editor from atom
        setTrackEditorState(undefined);
        // update the track and save it
        updateTrack();
        // remove track preview from atom
        removeTrackPreview();
    };

    const handleConfirmDeleteTrack = () => {
        // close the mini modal
        setOpenModal(false);
        // close the card (and remove it from cards array)
        closeCard(id);
        // remove track preview from atom
        removeTrackPreview();
        // remove the track from the tracks atom
        removeTrack(p.trackId);
        // remove track editor from atom
        setTrackEditorState(undefined);
    };

    const handleCancelDeleteTrack = () => {
        setOpenModal(false);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleChangeArrowInput =
        (data: "frameStart" | "frameEnd") => (action: "+" | "-", value: number) => {
            if (
                value < 0 ||
                value >= nbFrames ||
                (data === "frameStart" && value > p.frameEnd) ||
                (data === "frameEnd" && value < p.frameStart)
            )
                return;
            updateTrackPreview({
                [data]: value
            });
            if (data === "frameStart") {
                setTrackEditorState((prev) => (prev ? { ...prev, min: value } : undefined));
            } else {
                setTrackEditorState((prev) => (prev ? { ...prev, max: value } : undefined));
            }
        };

    // validates if we cancel or not the form
    const isCancelDisabled = () => {
        if (!initialValues) return false;
        else
            return (
                initialValues.frameEnd === p.frameEnd &&
                initialValues.frameStart === p.frameStart &&
                initialValues.active === p.active
            );
    };

    const renderReadableTime = (d: number) => {
        const { h: currentH, m: currentM, s: currentS } = timestampToReadable(d);
        return (
            <>
                {currentH}
                {":"}
                {currentM}
                {":"}
                {currentS}
            </>
        );
    };

    const handleClickActive = () => {
        const cropOptions = {
            strokeStyle: rectStyle[!p.active ? "active" : "inactive"].stroke,
            fillStyle: rectStyle[!p.active ? "active" : "inactive"].fill
        };
        const image = cropImage({ box: p.box, ldm: p.ldm }, cropOptions);
        if (!image) return;
        updateTrackPreview({ active: !p.active, thumbPreview: image });
    };

    const isVideo = isMediaVideo(mediaState);
    return (
        <>
            <div className="card-animated__head">
                <div className="card-animated__head__title">
                    <h4>{isVideo ? "Track " : "Detection "}details</h4>
                </div>
                <button className="close-btn" onClick={() => handleClickClose()} />
            </div>
            <div className="card-animated__body">
                <div className="card-animated__body__detection-section">
                    <div className="card-animated__body__detection-section__thumb">
                        {p.thumbPreview && <Thumb canvasSize={100} image={p.thumbPreview} />}
                    </div>
                    <div className="card-animated__body__detection-section__actions">
                        <div
                            onClick={handleOpenModal}
                            className="card-animated__body__detection-section__actions__delete-track"
                        >
                            <span>Delete {isVideo ? "track" : "detection"} </span>
                            <button></button>
                        </div>
                        <div className="card-animated__body__detection-section__actions__activation">
                            <span onClick={handleClickActive}>
                                Blur {isVideo ? "track" : "detection"}
                            </span>
                            <Checkbox
                                customClass="checkbox"
                                isChecked={p.active}
                                value={""}
                                onClick={handleClickActive}
                            />
                        </div>
                    </div>
                </div>
                {isVideo && (
                    <div className="card-animated__body__data-section">
                        <div className="card-animated__body__data-section__writable">
                            <div className="card-animated__body__data-section__writable__label">
                                Frame start:
                            </div>
                            <ArrowInput
                                onChange={handleChangeArrowInput("frameStart")}
                                value={p.frameStart}
                                limit={p.frameEnd}
                            />
                            <div className="card-animated__body__data-section__writable__timestamp">
                                {" "}
                                / {renderReadableTime(frameTsStart.timestamp)}
                            </div>
                            <div className="card-animated__body__data-section__writable__label">
                                Frame end:
                            </div>
                            <ArrowInput
                                onChange={handleChangeArrowInput("frameEnd")}
                                value={p.frameEnd}
                                limit={p.frameStart}
                            />
                            <div className="card-animated__body__data-section__writable__timestamp">
                                {" "}
                                / {renderReadableTime(frameTsEnd.timestamp)}
                            </div>
                        </div>
                    </div>
                )}

                <div className="card-animated__body__data-section">
                    <div className="card-animated__body__data-section__readable">
                        {isVideo && (
                            <>
                                <div className="card-animated__body__data-section__readable__label">
                                    Track id:
                                </div>
                                <div className="card-animated__body__data-section__readable__value cn">
                                    {p.trackId}
                                </div>
                            </>
                        )}

                        <div className="card-animated__body__data-section__readable__label">
                            Detection type:
                        </div>
                        <div className="card-animated__body__data-section__readable__value cn">
                            {p.cn}
                        </div>
                        {isVideo && (
                            <>
                                <div className="card-animated__body__data-section__readable__label">
                                    Track length:
                                </div>
                                <div className="card-animated__body__data-section__readable__value">
                                    {p.frameEnd - p.frameStart + 1}
                                </div>
                                <div className="card-animated__body__data-section__readable__label">
                                    Track duration:
                                </div>
                                <div className="card-animated__body__data-section__readable__value">
                                    {renderReadableTime(
                                        frameTsEnd.timestamp - frameTsStart.timestamp
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {isVideo && (
                    <div className="card-animated__body__buttons-section">
                        <button
                            className="cancel"
                            disabled={isCancelDisabled()}
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                    </div>
                )}
            </div>
            <MiniModalDeleteConfirmation
                open={openModal}
                onConfirm={handleConfirmDeleteTrack}
                onCancel={handleCancelDeleteTrack}
            />
        </>
    );
};
