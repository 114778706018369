import { ReactEventHandler, useEffect, useRef } from "react";

import { useRefsContext } from "../../../contexts/RefsContexts";
import { useCanvasMedia } from "../../../hooks/useCanvasMedia";
import { useFramesTimestamps } from "../../../hooks/useFramesTimestamp";
import { useInvalidMedia } from "../../../hooks/useInvalidMedia";
import { useLoadingState } from "../../../hooks/useLoadingState";
import { MediaVideo, useMedia } from "../../../hooks/useMedia";
import { useThumbsGrid } from "../../../hooks/useThumbsGrid";
import { useVideoBuffer } from "../../mediaControls/hooks/useVideoBuffer";

export const VideoPreview = () => {
    const { canvasMediaRef, videoRef, updateRefReadyState, isReady } = useRefsContext();
    const { setMediaInvalid } = useInvalidMedia();
    const animationFrameIndex = useRef<number>(null!);
    const [, { updateVideoBufferState }] = useVideoBuffer();
    const [{ framerate, nbFrames, src, currentFrame }, { updateCurrentFrame, updatePlayPause }] =
        useMedia<MediaVideo>();
    const [, { draw, clear }] = useCanvasMedia();
    const [{ framesTimestamps }] = useFramesTimestamps();
    const video = videoRef.current;
    const [, { updateLoading }] = useLoadingState();
    const [, { extractThumbs }] = useThumbsGrid();

    useEffect(() => {
        const video = videoRef.current;
        return () => {
            if (video) {
                video.pause();
                video.removeAttribute("src");
                video.load();
            }
        };
    }, [videoRef]);

    useEffect(() => {
        if (isReady.videoRef && video) {
            clear();
            draw(video);
        }
    }, [isReady]);

    useEffect(() => {
        if (videoRef.current && !isReady.videoRef) updateRefReadyState("videoRef", true);
        if (canvasMediaRef.current && !isReady.canvasMediaRef)
            updateRefReadyState("canvasMediaRef", true);
    }, [videoRef.current, canvasMediaRef.current]);

    // when data is loaded draw the video
    const handleLoadedData: ReactEventHandler<HTMLVideoElement> = () => {
        if (!video) return;
        // eslint-disable-next-line no-self-assign
        video.currentTime = video.currentTime; // trigger video to be sure to get the currentImage
        clear();
        draw(video);
    };

    // when video is playing, use animationFrame to draw the current video frame
    // we calculate an approximation of the currentFrame to draw detections smoothly
    const handlePlay: ReactEventHandler<HTMLVideoElement> = () => {
        if (!video) return;
        updatePlayPause(true);
        const animate = () => {
            if (video.paused || video.ended) return;
            const frameIndexAverage = Number(
                Math.min(nbFrames - 1, Math.max(0, Math.round(video.currentTime * framerate)))
            );
            clear();
            draw(video);
            updateCurrentFrame(frameIndexAverage);
            animationFrameIndex.current = requestAnimationFrame(animate);
        };
        animationFrameIndex.current = requestAnimationFrame(animate);
    };

    // when video is paused we have to find the correct current frame
    // to do this whe find in te framesTimestamps array the current time to go according to the currentFrame
    const handlePause = () => {
        if (!video) return;
        updatePlayPause(false);
        const currentTime = video.currentTime;
        let signe = -1;
        let newCurrentFrame = currentFrame;
        let newCurrentTime = video.currentTime;
        for (let i = 0; i < 20; i++) {
            newCurrentFrame += signe * i;
            signe *= -1;
            if (newCurrentFrame < 0) {
                newCurrentFrame = 0;
                break;
            }
            if (newCurrentFrame >= nbFrames - 1) {
                break;
            }
            const tsData = framesTimestamps[newCurrentFrame];
            if (
                currentTime >= tsData.timestamp &&
                currentTime <= tsData.timestamp + tsData.duration
            ) {
                newCurrentTime = tsData.timestamp + tsData.duration / 2 - 0.0001;
                break;
            }
        }
        if (newCurrentFrame === nbFrames - 1) {
            video.currentTime = video.duration;
        } else {
            video.currentTime = newCurrentTime;
        }
        cancelAnimationFrame(animationFrameIndex.current);
    };

    const hanldeCanPlayThrough = () => {
        if (!video) return;
        updateLoading({ cause: "media-loading", isLoading: false });
        const buffer = _handleBuffering(video);
        updateVideoBufferState(buffer);
        clear();
        draw(video);
        if (video.paused) {
            extractThumbs();
        }
    };

    const handleTimeUpdate = () => {
        if (!video) return;
        const buffer = _handleBuffering(video);
        updateVideoBufferState(buffer);
        clear();
        draw(video);
        if (video.paused) {
            extractThumbs();
        }
    };

    const _handleBuffering = (video: HTMLVideoElement): number => {
        const currentTime = video.currentTime;
        let bufferEnd = 0;
        for (let i = 0; i < video.buffered.length; i++) {
            if (currentTime >= video.buffered.start(i) && video.buffered.end(i)) {
                bufferEnd = video.buffered.end(i);
                break;
            }
        }
        return bufferEnd;
    };

    const handleWaiting = () => {
        const video = videoRef.current;
        if (!video) return;
        const buffer = _handleBuffering(video);
        updateVideoBufferState(buffer);
        updateLoading({ cause: "media-loading", isLoading: true });
    };

    const handleProgress = () => {
        const video = videoRef.current;
        if (!video) return;
        const buffer = _handleBuffering(video);
        updateVideoBufferState(buffer);
    };

    return (
        <>
            <canvas
                style={{ height: "100%", width: "100%" }}
                height={1080}
                width={1920}
                className="canvas-media"
                ref={canvasMediaRef}
            ></canvas>
            <video
                onError={(e) => setMediaInvalid(true)}
                onProgress={handleProgress}
                onSeeked={handleProgress}
                onLoadedData={handleLoadedData}
                onCanPlayThrough={hanldeCanPlayThrough}
                onPlay={handlePlay}
                onPause={handlePause}
                onWaiting={handleWaiting}
                onTimeUpdate={handleTimeUpdate}
                ref={videoRef}
                hidden={true}
                crossOrigin="anonymous"
                preload="auto"
                controls
                src={src}
            ></video>
        </>
    );
};
