import { useCanvasMedia } from "./useCanvasMedia";
import { useFramesTimestamps } from "./useFramesTimestamp";
import { useLoadingState } from "./useLoadingState";
import { useRefsContext } from "../contexts/RefsContexts";
import { useMedia, MediaVideo } from "../hooks/useMedia";

export const useControlVideo = (): [
    HTMLVideoElement | null,
    {
        play: () => void;
        pause: () => void;
        changeVolume: (volume: number) => void;
        goToFrame: (frame: number | "lastFrame" | "firstFrame") => void;
    }
] => {
    const { videoRef } = useRefsContext();
    const [{ nbFrames }, { updateCurrentFrame }] = useMedia<MediaVideo>();
    const [{ framesTimestamps }] = useFramesTimestamps();
    const [, { updateLoading }] = useLoadingState();
    const [, { draw, clear }] = useCanvasMedia();

    const _getVideo = (): HTMLVideoElement | null => {
        if (!videoRef.current) {
            return null;
        }
        const video = videoRef.current;
        return video;
    };

    const play = () => {
        const video = _getVideo();
        if (!video) return;
        video.play();
    };

    const pause = () => {
        const video = _getVideo();
        if (!video) return;
        video.pause();
    };

    const changeVolume = (volume: number) => {
        const video = _getVideo();
        if (!video) return;
        video.volume = volume;
    };

    const goToFrame = (frame: number | "lastFrame" | "firstFrame") => {
        const video = _getVideo();

        if (!video || !framesTimestamps) return;

        if ((typeof frame === "number" && frame < 0) || frame > nbFrames - 1) return;
        let newTime = video.currentTime;
        const newFrame = frame === "firstFrame" ? 0 : frame === "lastFrame" ? nbFrames - 1 : frame;
        if (frame === "firstFrame") {
            newTime = 0;
        } else if (frame === "lastFrame") {
            newTime = video.duration;
        } else {
            const frameData = framesTimestamps[frame];
            newTime = frameData.timestamp + frameData.duration / 2 - 0.0001;
        }
        clear();
        draw(video);
        updateCurrentFrame(newFrame);
        video.currentTime = newTime;
        let index: any = null;
        index = setTimeout(() => {
            if (video.readyState < HTMLMediaElement.HAVE_FUTURE_DATA) {
                updateLoading({ cause: "media-loading", isLoading: true });
            }
            clearTimeout(index);
        }, 50);
    };

    return [
        _getVideo(),
        {
            play,
            pause,
            changeVolume,
            goToFrame
        }
    ];
};
