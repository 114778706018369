import moment from "moment";

import { ClientService } from "../context/clientServicesContext";
import { ClientCurrency } from "../types";

export const isStrEmpty = (str: string): boolean =>
    str === undefined || str?.length === 0 || str === null;

export const isObjectEmpty = (obj: any) =>
    Object.keys(obj).length === 0 && obj.constructor === Object;

export const convertToMo = (data: any) => parseInt(Number(data / 1_048_576).toFixed());

export const randomKeyGen = () => {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < 15; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const replaceEmptyByNull = (myObj: any) => {
    Object.keys(myObj).forEach((k) => (myObj[k] = myObj[k] === "" ? null : myObj[k]));
};

export const convertSize = (a: number, b?: number) => {
    if (0 === a) {
        return "0 Byte";
    }
    const c = 1024;
    const d = b || 2;
    const e = ["Byte", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const f = Math.floor(Math.log(a) / Math.log(c));

    return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
};

export const convertToMb = (a: number) => {
    return Math.floor(a / (1024 * 1024));
};

export const convertSizeNumber = (a: number, b?: number) => {
    if (0 === a) {
        return "0 Byte";
    }
    const c = 1024;
    const d = b || 2;
    const f = Math.floor(Math.log(a) / Math.log(c));

    return parseFloat((a / Math.pow(c, f)).toFixed(d));
};

export const convertSizeType = (a: number) => {
    if (0 === a) {
        return "0 Byte";
    }
    const c = 1024;
    const e = ["Byte", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const f = Math.floor(Math.log(a) / Math.log(c));

    return e[f];
};

export const isCardExpired = (creditCardExpirationDate: number | undefined) => {
    if (!creditCardExpirationDate) return false;
    return moment(creditCardExpirationDate).isBefore(new Date());
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const areApiActive = (services: ClientService[]) => {
    return services?.some(
        (e: ClientService) =>
            !e?.service?.searchKey?.includes("SDK") &&
            !e?.service?.searchKey?.includes("PREMISE") &&
            !e.serviceEnd
    );
};

export const capitalize = (str: string) => {
    // Check if the string is empty
    if (str.length === 0) {
        return str;
    }
    // Capitalize the first character and append the rest of the string
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const formatCurrency = (
    price: number,
    currency: ClientCurrency = ClientCurrency.EUR
): string => {
    const formattedPrice = new Intl.NumberFormat(
        currency === ClientCurrency.EUR ? "fr-FR" : "en-US",
        {
            style: "currency",
            currency: currency,
            minimumFractionDigits: price % 1 === 0 ? 0 : 2,
            maximumFractionDigits: 2
        }
    ).format(price);
    return currency === "USD" ? formattedPrice : `${formattedPrice.replace("€", "")}€`;
};

export enum TypeOS {
    WINDOWS = "win",
    MACOS = "mac",
    LINUX = "linux",
    UNKNOWN = "unknown"
}

export const getUserTypeOS = () => {
    const platform = navigator.userAgent.toLowerCase();
    if (platform.includes("win")) {
        return TypeOS.WINDOWS;
    } else if (platform.includes("mac")) {
        return TypeOS.MACOS;
    } else if (platform.includes("linux")) {
        return TypeOS.LINUX;
    } else {
        return TypeOS.UNKNOWN;
    }
}
