export const ShapeMode = ["creation", "active", "inactive"] as const;
export type ShapeModeName = (typeof ShapeMode)[number];

export type DetectionState = {
    mode: ShapeModeName;
    disabled: boolean;
};

export const rectStyle: Record<
    ShapeModeName,
    {
        fill: string;
        stroke: string;
    }
> = {
    creation: {
        fill: "rgba(245, 10, 107, 0.3)",
        stroke: "rgba(245, 10, 107, 1)"
    },
    active: {
        fill: "rgba(42, 166, 8, 0.3)",
        stroke: "rgba(42, 166, 8, 1)"
    },
    inactive: {
        fill: "rgba(217, 200, 11, 0.3)",
        stroke: "rgba(217, 200, 11, 1)"
    }
};
