import { useSetRecoilState } from "recoil";

import { useCards } from "./cards/hook/useCards";
import { Thumb } from "./Thumb";
import { trackEditorAtom } from "../../recoil/trackEditor.atom";
import { PreviewCard } from "./cards/components/PreviewCard";
import { Checkbox } from "../../../../../../components/checkbox/checkbox";
import { RoundedLoader } from "../../../../../../components/loader/RoundedLoader";
import { useInvalidMedia } from "../../hooks/useInvalidMedia";
import { useLoadingState } from "../../hooks/useLoadingState";
import { useThumbsGrid } from "../../hooks/useThumbsGrid";
import { useTrackPreview } from "../../hooks/useTrackPreview";
import { useTracks } from "../../hooks/useTracks";

export const ThumbsGrid = () => {
    const [, { addCard }] = useCards();
    const setTrackEditorState = useSetRecoilState(trackEditorAtom);
    const [data, { updateThumbActivation, extractThumbs }] = useThumbsGrid();
    const [, { addTrackPreview, updateTrackPreview }] = useTrackPreview();
    const [tracks, { updateTrackActive }] = useTracks();
    const [loadingState] = useLoadingState();
    const { mediaInvalid } = useInvalidMedia();

    const handleClickCheckbox = (trackId: number, value: boolean) => () => {
        extractThumbs();
        updateTrackActive(trackId);
        updateThumbActivation(trackId, value);
    };

    const isDetectionHover = (trackId: number) =>
        !!data.elements.find((e) => e.trackId === trackId)?.isHover;

    const handleClickPreviewThumb = (trackId: number, active: boolean) => {
        const currentTrack = tracks.tracks.find((d) => d.trackId === trackId);
        if (!currentTrack) {
            console.error(
                `FC ReadableDetections: method "handleClickDetection" No track found with id ${trackId}`
            );
            return;
        }
        // add track preview to atom
        addTrackPreview(trackId, { isActive: active });
        // create a random id for the card
        const id = `${Math.random()}`;
        // add card to cards array
        addCard({
            id: id,
            closeOnNewEntry: true,
            element: <PreviewCard id={id} />,
            open: true
        });
        setTrackEditorState({
            min: currentTrack.frameStart,
            max: currentTrack.frameEnd,
            onChangeMin: (value) => {
                updateTrackPreview({
                    frameStart: value
                });
            },
            onChangeMax: (value) => {
                updateTrackPreview({
                    frameEnd: value
                });
            },
            onReleaseMax: () => {
                // handleRangeUpdate(value, 'max');
            },
            onReleaseMin: () => {
                // handleRangeUpdate(value, 'min');
            }
        });
    };
    return (
        <div className="sidebar__thumbs-grid-container">
            {mediaInvalid ||
                (data.elements.length === 0 && !loadingState && (
                    <div className="sidebar__thumbs-grid-container__no-data text-center">
                        <span>No detection in this frame</span>
                    </div>
                ))}
            {!mediaInvalid && loadingState === "init-studio" && (
                <div className="sidebar__thumbs-grid-container__loading">
                    <div className="sidebar__thumbs-grid-container__loader">
                        <RoundedLoader isLoading={true} size={50} />
                    </div>
                    <span>Frames detections are currently downloading.</span>
                </div>
            )}
            {!mediaInvalid && loadingState === "media-loading" && (
                <div className="sidebar__thumbs-grid-container__loader">
                    <RoundedLoader isLoading={true} size={50} />
                </div>
            )}

            {data.elements.map((element, index) => {
                return (
                    <div
                        className={`sidebar__thumbs-grid-container__thumb-wrapper ${
                            isDetectionHover(element.trackId) ? "hover" : ""
                        }`}
                        key={index}
                    >
                        {element.image && (
                            <Thumb
                                onClick={() =>
                                    handleClickPreviewThumb(element.trackId, element.active)
                                }
                                image={element.image}
                                canvasSize={100}
                            />
                        )}
                        <Checkbox
                            isChecked={element.active}
                            onClick={handleClickCheckbox(element.trackId, !element.active)}
                            value={`${element.trackId}`}
                            customClass="checkbox-activation"
                        />
                    </div>
                );
            })}
        </div>
    );
};
