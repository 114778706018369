import { useEffect } from "react";

import { useRecoilValue } from "recoil";

import { useControlVideo } from "../../../hooks/useControlVideo";
import { mediaAtom, MediaVideo } from "../../../hooks/useMedia";
import { useControlsDisabled } from "../hooks/useControlsDisabled";

export const ActionButtons = () => {
    const [video, { play, pause, goToFrame }] = useControlVideo();
    const media = useRecoilValue(mediaAtom) as MediaVideo;
    const disabled = useControlsDisabled();

    useEffect(() => {
        const handleKeyDown = (ev: KeyboardEvent) => {
            if (ev.key === "ArrowRight") {
                goToFrame(media.currentFrame + 1);
            }
            if (ev.key === "ArrowLeft") {
                goToFrame(media.currentFrame - 1);
            }
            if (ev.code === "Space") {
                if (!video) return;
                if (!video.paused) pause();
                else play();
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [media]);

    const handleClickGoToFrame = (frame: number | "lastFrame" | "firstFrame") => {
        goToFrame(frame);
    };

    const handlePlay = () => {
        if (!video) return;
        if (!video.paused) pause();
        else play();
    };

    const getPlayIcon = () => (video?.paused || !video ? "play" : "pause");

    return (
        <div className="media-controls__controls__buttons">
            <button
                disabled={disabled}
                data-icon="go-to-max"
                onClick={() => handleClickGoToFrame("firstFrame")}
                className="rotate"
            />
            <button
                disabled={disabled}
                data-icon="go-to-frame"
                onClick={() => handleClickGoToFrame(media.currentFrame - 1)}
            />
            <button disabled={disabled} data-icon={`${getPlayIcon()}`} onClick={handlePlay} />
            <button
                disabled={disabled}
                data-icon="go-to-frame"
                onClick={() => handleClickGoToFrame(media.currentFrame + 1)}
                className="rotate"
            />
            <button
                disabled={disabled}
                data-icon="go-to-max"
                onClick={() => handleClickGoToFrame("lastFrame")}
            />
        </div>
    );
};
