import { useRecoilValue } from "recoil";

import { useRefsContext } from "../../../contexts/RefsContexts";
import { mediaAtom, MediaVideo } from "../../../hooks/useMedia";

export const timestampToReadable = (ts: number) => {
    const addZero = (v: number) => {
        if (v < 10) {
            return `0${v}`;
        }
        return `${v}`;
    };
    const h = addZero(Math.floor(ts / 3600));
    const m = addZero(Math.floor((ts % 3600) / 60));
    const s = addZero(Math.floor(ts % 60));
    return {
        h: isNaN(parseInt(h)) ? "-" : h,
        m: isNaN(parseInt(m)) ? "-" : m,
        s: isNaN(parseInt(s)) ? "-" : s
    };
};

export const MediaData = () => {
    const { currentFrame, nbFrames } = useRecoilValue(mediaAtom) as MediaVideo;
    const { videoRef } = useRefsContext();
    const video = videoRef.current;

    const renderReadableTime = () => {
        if (!video) return;
        const { h: currentH, m: currentM, s: currentS } = timestampToReadable(video.currentTime);
        const { h: durationH, m: durationM, s: durationS } = timestampToReadable(video.duration);
        return `${currentH}:${currentM}:${currentS}/${durationH}:${durationM}:${durationS}`;
    };

    return (
        <div className="media-controls__controls__data">
            <span>{renderReadableTime()}</span>
            <span>
                Frame: {currentFrame} / {!isNaN(nbFrames - 1) ? nbFrames - 1 : "-"}
            </span>
        </div>
    );
};
