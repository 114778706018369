import { atom, useRecoilState } from "recoil";

import { AutoFrameDetection, useAutoDetections } from "./useAutoDetections";
import { useCanvasMedia } from "./useCanvasMedia";
import { DetectionCoordinatesRange, useTracks } from "./useTracks";
import { insertFrameInRange } from "../components/mediaVisualizer/canvasDetections/components/renderDetections/TrackPreviewDetection";
import { rectStyle } from "../components/mediaVisualizer/canvasDetections/types";
import { useMedia } from "../hooks/useMedia";
import { Box, Point } from "../recoil/framesDetectionsCoordinates.atom";

export type FrameRange = {
    frameStart: number;
    frameEnd: number;
    box: Box;
    ldm: Point[];
};

export type TrackPreview = {
    box: Box;
    ldm: Point[];
    cn: "plate" | "head" | "other";
    trackId: number;
    active: boolean;
    frameStart: number;
    frameEnd: number;
    thumbPreview: ImageData;
    initialRange: FrameRange[];
    previousFrameEnd: number;
    previousFrameStart: number;
};

export type DetectionPosition = {
    box: Box;
    ldm: Point[];
    _type: "detection-position";
};

const isDetectionPosition = (data: unknown): data is DetectionPosition => {
    return !!((data as DetectionPosition)?._type === "detection-position");
};

const isActivationData = (data: unknown): data is { isActive: boolean } => {
    return !!(typeof (data as { isActive: boolean })?.isActive === "boolean");
};

export const trackPreviewAtom = atom<TrackPreview | undefined>({
    key: "trackPreviewAtom",
    default: undefined
});

export const useTrackPreview = (): [
    TrackPreview | undefined,
    {
        addTrackPreview: <T>(trackId: number, otherData?: T) => void;
        updateTrackPreview: (trackUpdate: Partial<TrackPreview>) => void;
        removeTrackPreview: () => void;
        updateRangesPreview: (action: "min" | "max", value: number) => void;
    }
] => {
    const [{ tracks }] = useTracks();
    const [autoDetectionsFramesState] = useAutoDetections();
    const [{ currentFrame }] = useMedia();
    const [trackPreviewState, setTrackPreviewState] = useRecoilState(trackPreviewAtom);
    const [, { cropImage }] = useCanvasMedia();

    function addTrackPreview<T>(trackId: number, otherData?: T) {
        const findTrack = tracks.find((track) => track.trackId === trackId);
        if (!findTrack)
            throw new Error(
                `Hook useTrackPreview: method "addTrackPreview" No track whith trackId: ${trackId}. Tracks: ${tracks}`
            );
        let track = { ...findTrack };
        // udpate saved range at currentIndex with the position of the preview otherData
        if (otherData && isDetectionPosition(otherData)) {
            let savedRangeTmp = [...track.savedRange];
            if (savedRangeTmp.length === 0) {
                track = {
                    ...track,
                    savedRange: [
                        {
                            box: otherData.box,
                            ldm: otherData.ldm,
                            frameStart: currentFrame,
                            frameEnd: currentFrame,
                            src: 0.9
                        }
                    ]
                };
            } else {
                savedRangeTmp = insertFrameInRange<DetectionCoordinatesRange>(
                    savedRangeTmp,
                    { ...otherData, src: 0.9 },
                    currentFrame
                );
                track = {
                    ...track,
                    savedRange: savedRangeTmp
                };
            }
        }

        const range = track.initialRange.find(
            (range) => currentFrame >= range.frameStart && currentFrame <= range.frameEnd
        );

        let cropOptions = {
            strokeStyle: rectStyle[!track.active ? "active" : "inactive"].stroke,
            fillStyle: rectStyle[!track.active ? "active" : "inactive"].fill
        };
        if (otherData && isActivationData(otherData)) {
            const oData = otherData;
            cropOptions = {
                strokeStyle: rectStyle[oData.isActive ? "active" : "inactive"].stroke,
                fillStyle: rectStyle[oData.isActive ? "active" : "inactive"].fill
            };
        }
        if (track.initialRange.length === 0) {
            // check if track is auto (not modified)
            if (!autoDetectionsFramesState?.frames[currentFrame])
                throw new Error(
                    `Hook useTrackPreview: method "addTrackPreview" No auto detection at this frame.`
                );
            const currentFrameAutoDetections = autoDetectionsFramesState?.frames[currentFrame];
            let currentDetection: AutoFrameDetection | DetectionCoordinatesRange | undefined =
                currentFrameAutoDetections.find((dtc) => dtc.trackId === trackId);

            if (track.savedRange.length > 0) {
                const range = track.savedRange.findIndex(
                    (r) => currentFrame >= r.frameStart && currentFrame <= r.frameEnd
                );
                currentDetection = track.savedRange[range];
            }

            if (!currentDetection)
                throw new Error(
                    `Hook useTrackPreview: method "addTrackPreview" No auto detection for the trackId: ${trackId} at the frame ${currentFrame}. Auto detections: ${currentFrameAutoDetections}`
                );
            const image = cropImage(
                isDetectionPosition(otherData)
                    ? { box: otherData.box, ldm: otherData.ldm }
                    : { box: currentDetection.box, ldm: currentDetection.ldm },
                cropOptions
            );
            if (!image) return;
            // detection exists
            setTrackPreviewState({
                box: currentDetection.box,
                ldm: currentDetection.ldm,
                cn: track.cn,
                trackId: track.trackId,
                frameStart: track.frameStart,
                frameEnd: track.frameEnd,
                thumbPreview: image,
                active: track.active,
                initialRange: track.savedRange,
                previousFrameEnd: track.frameEnd,
                previousFrameStart: track.frameStart,
                ...otherData
            });
        }
        // not a auto detection (at least it is a detection wich has been auto then modified / or a new detection added)
        else {
            if (!range)
                throw new Error(
                    `Hook useTrackPreview: method "addTrackPreview" No detection in the range for this currentFrame ${currentFrame}. Ranges: ${track.initialRange}`
                );
            // crop detection in canvas
            const image = cropImage(
                isDetectionPosition(otherData)
                    ? { box: otherData.box, ldm: otherData.ldm }
                    : { box: range.box, ldm: range.ldm },
                cropOptions
            );
            if (!image) return;
            setTrackPreviewState({
                box: range.box,
                ldm: range.ldm,
                cn: track.cn,
                trackId: trackId,
                frameStart: track.frameStart,
                frameEnd: track.frameEnd,
                active: track.active,
                thumbPreview: image,
                initialRange: track.savedRange,
                previousFrameEnd: track.frameEnd,
                previousFrameStart: track.frameStart,
                ...otherData
            });
        }
    }

    const updateTrackPreview = (trackUpdate: Partial<TrackPreview>) => {
        setTrackPreviewState((prev) => (!prev ? prev : { ...prev, ...trackUpdate }));
    };

    const removeTrackPreview = () => {
        setTrackPreviewState(undefined);
    };

    // TODO Gérer la gestion des ranges
    const updateRangesPreview = (action: "min" | "max", value: number) => {
        // return;
        setTrackPreviewState((prev) => {
            if (!prev) return prev;
            const copy = { ...prev };
            // case detection has not been modified yet
            if (prev.initialRange.length === 0) {
                if (action === "max") {
                    copy.previousFrameEnd = value;
                } else {
                    copy.previousFrameStart = value;
                }
            } else {
                // case max range changed
                const rangeIndex = copy.initialRange.findIndex(
                    (range) => value >= range.frameStart && value <= range.frameEnd
                );
                const tmpInitialRange = [...copy.initialRange];
                if (action === "max") {
                    // max goes to the right from its last position
                    if (value < copy.previousFrameEnd) {
                        if (rangeIndex >= 0) {
                            // remove all elements after the index
                            tmpInitialRange.splice(rangeIndex + 1);
                            // update the frameRange
                            tmpInitialRange[rangeIndex] = {
                                ...tmpInitialRange[rangeIndex],
                                frameEnd: value
                            };
                            copy.previousFrameEnd = value;
                        } else {
                            // supprime tous les elements qui sont au dessus du dernier framestart
                            const index = copy.initialRange.reduceRight((acc, curr, index) => {
                                return acc === -1 && value > curr.frameStart ? index : acc;
                            }, -1);
                            tmpInitialRange.splice(index + 1);
                            copy.previousFrameEnd = value;
                        }
                    }
                    // max goes to the left from it last position
                    else {
                        if (rangeIndex >= 0) {
                            tmpInitialRange[tmpInitialRange.length - 1] = {
                                ...tmpInitialRange[tmpInitialRange.length - 1],
                                frameEnd: value
                            };
                        }
                        const previousWasInRangeIndex = copy.initialRange.findIndex(
                            (range) =>
                                copy.previousFrameEnd >= range.frameStart &&
                                copy.previousFrameEnd <= range.frameEnd
                        );
                        if (previousWasInRangeIndex >= 0)
                            tmpInitialRange[previousWasInRangeIndex] = {
                                ...tmpInitialRange[previousWasInRangeIndex],
                                frameEnd: value
                            };
                        copy.previousFrameEnd = value;
                    }
                    copy.initialRange = [...tmpInitialRange];
                }
                // case min range changed
                else {
                    // min goes right from its last position
                    if (value > copy.previousFrameStart) {
                        // if we overlap an existing detection we remove all items before index in array and update the first frame
                        if (rangeIndex >= 0) {
                            // remove all elements before the index
                            tmpInitialRange.slice(0, rangeIndex);
                            // update the frameRange
                            tmpInitialRange[rangeIndex] = {
                                ...tmpInitialRange[rangeIndex],
                                frameStart: value
                            };
                            copy.previousFrameStart = value;
                        }
                        // else we only remove all element with lower upper than current value
                        else {
                            const index = copy.initialRange.findIndex(
                                (value) => value.frameEnd > currentFrame
                            );
                            tmpInitialRange.slice(0, index);
                            copy.previousFrameStart = value;
                        }
                    }
                    // min goes to the left from it last position
                    else {
                        if (rangeIndex >= 0)
                            tmpInitialRange[0] = {
                                ...tmpInitialRange[0],
                                frameStart: value
                            };
                        const previousWasInRangeIndex = copy.initialRange.findIndex(
                            (range) =>
                                copy.previousFrameStart >= range.frameStart &&
                                copy.previousFrameStart <= range.frameEnd
                        );
                        if (previousWasInRangeIndex >= 0)
                            tmpInitialRange[previousWasInRangeIndex] = {
                                ...tmpInitialRange[previousWasInRangeIndex],
                                frameStart: value
                            };
                        copy.previousFrameStart = value;
                    }
                }
                copy.initialRange = [...tmpInitialRange];
            }
            copy.initialRange.sort((a, b) => a.frameStart - b.frameStart);
            return {
                ...prev,
                ...copy
            };
        });
    };

    return [
        trackPreviewState,
        { addTrackPreview, updateTrackPreview, removeTrackPreview, updateRangesPreview }
    ];
};
