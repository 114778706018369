import { useEffect, useMemo } from "react";

import { useParams } from "react-router-dom";

import { useLoadingState } from "./useLoadingState";
import { getStorageMediaUrl } from "../../../../../api";
import { useGetHistoryCall } from "../../../../../hook/queries/useHistoryCall";
import { useGetMediaMetadata } from "../../../../../hook/queries/useStorage";
import { GetMediaMetadataResponse } from "../../../../../types";
import { FormattedHistoryCall } from "../../../../../types/historyCall";

export type GetStudioDataLoading = { isLoading: boolean };
export type GetStudioDataError = { error: boolean; type: any; message?: string };
export type GetStudioDataSuccess = {
    task: FormattedHistoryCall;
    framesInfo: GetMediaMetadataResponse;
    mediaUrl: string;
};

export const isStudioDataSuccess = (data: unknown): data is GetStudioDataSuccess => {
    return !!(data as GetStudioDataSuccess).framesInfo;
};

export const isStudioDataLoading = (data: unknown): data is GetStudioDataLoading => {
    return !!(data as GetStudioDataLoading).isLoading;
};

type UseGetStudioDataState = GetStudioDataError | GetStudioDataLoading | GetStudioDataSuccess;

export const useFetchStudioData = (): UseGetStudioDataState => {
    const { jobId } = useParams() as { jobId: string };

    // first get history call associated to the jobId passed in url parameter
    const {
        data: historyCalls,
        isLoading: historyCallLoading,
        error: historyCallError
    } = useGetHistoryCall({ jobId: jobId }, !!jobId);
    // prepare filenam
    const filename = historyCalls?.elements[0].input_filename;
    // the get the frames and timestamps detections data from historyCall file name
    const {
        data: detectionsData,
        isLoading: detectionsLoading,
        error: detectionsError
    } = useGetMediaMetadata({ filename: filename }, !!filename);
    // loader handler
    const [, { updateLoading }] = useLoadingState();
    const loadingState = historyCallLoading || detectionsLoading;
    const errorState = historyCallError || detectionsError;
    const task = historyCalls?.elements[0];
    const framesInfo = detectionsData;
    useEffect(() => {
        if (loadingState) {
            updateLoading({ cause: "fetch-data", isLoading: true });
        } else {
            updateLoading({ cause: "fetch-data", isLoading: false });
        }
    }, [loadingState, errorState, jobId]);

    return useMemo(() => {
        if (loadingState) {
            return { isLoading: loadingState };
        } else if (!loadingState && (!task || !framesInfo) && !errorState) {
            return {
                error: true,
                type: "No data for this element in history call :/"
            };
        } else if (errorState) {
            return {
                error: true,
                type: historyCallError ?? detectionsError
            };
        } else {
            const mediaUrl = getStorageMediaUrl({
                filename: (task as FormattedHistoryCall).input_filename
            });
            return {
                task: task as FormattedHistoryCall,
                framesInfo: framesInfo as GetMediaMetadataResponse,
                mediaUrl
            };
        }
    }, [loadingState, errorState, jobId, task, framesInfo]);
};
