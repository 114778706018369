import { useRecoilValue } from "recoil";

import { _getArrayFromBox, _getBoxFromPointArray } from "../../../../../hooks/helper";
import { mediaAtom } from "../../../../../hooks/useMedia";
import { useNewTrackCreationPreview } from "../../../../../hooks/useNewTrackCreationPreview";
import {
    Box,
    DetectionClassName,
    isBox,
    Point
} from "../../../../../recoil/framesDetectionsCoordinates.atom";
import { useShapeDisabled } from "../../hooks/useShapeDisabled";
import { HeadDetection } from "../shapes/HeadDetection";
import { OtherDetection } from "../shapes/OtherDetection";
import { PlateDetection } from "../shapes/PlateDetection";

export const CreatingDetectionPreview = () => {
    const [newTrackCreationPreviewState, { updateNewTrackCreationPreview, getCurrentFrameData }] =
        useNewTrackCreationPreview();
    const mediaState = useRecoilValue(mediaAtom);
    const shapeDisabled = useShapeDisabled();
    const currentDetectionFrame = getCurrentFrameData();
    if (!currentDetectionFrame || !newTrackCreationPreviewState) return null;

    const handleGestureEnd = (cn: DetectionClassName, data: Box | Point[]) => {
        let ldm: Point[] = [];
        let box = data;

        if (!isBox(data)) {
            ldm = data;
            box = _getBoxFromPointArray(data);
        } else {
            box = data;
        }
        if (cn === DetectionClassName.Plate && isBox(data)) {
            ldm = _getArrayFromBox(data);
        }
        let initialRangeTmp = [...newTrackCreationPreviewState.initialRange];
        const currentFrame = mediaState.currentFrame;
        const rangeOverlapIndex = initialRangeTmp.findIndex(
            (range) => currentFrame >= range.frameStart && currentFrame <= range.frameEnd
        );
        if (rangeOverlapIndex >= 0) {
            // si on est sur la premiere frame on remplace TOUT par la nouvelle detection pour l'entiereté de la range
            if (newTrackCreationPreviewState.frameStart === currentFrame) {
                initialRangeTmp = [
                    {
                        ...initialRangeTmp[rangeOverlapIndex],
                        frameEnd: newTrackCreationPreviewState.frameEnd,
                        ldm,
                        box
                    }
                ];
            } else {
                // on supprime tous les ranges supérieur
                initialRangeTmp.splice(rangeOverlapIndex + 1);
                // si on est sur un range qui COMMENCE a la frame actuelle
                if (initialRangeTmp[rangeOverlapIndex].frameStart === currentFrame) {
                    initialRangeTmp[rangeOverlapIndex] = {
                        ...initialRangeTmp[rangeOverlapIndex],
                        frameStart: initialRangeTmp[rangeOverlapIndex].frameStart,
                        frameEnd: newTrackCreationPreviewState.frameEnd,
                        box,
                        ldm
                    };
                } else {
                    // sinon on met a jour l'élément précédent et on ajoute le nouvelle range
                    initialRangeTmp[rangeOverlapIndex] = {
                        ...initialRangeTmp[rangeOverlapIndex],
                        frameEnd: currentFrame - 1
                    };
                    initialRangeTmp.push({
                        frameStart: currentFrame,
                        frameEnd: newTrackCreationPreviewState.frameEnd,
                        ldm,
                        box
                    });
                }
            }
        }
        updateNewTrackCreationPreview({ initialRange: initialRangeTmp });
    };

    return (
        <>
            {
                {
                    [DetectionClassName.Head]: (
                        <HeadDetection
                            cn={DetectionClassName.Head}
                            box={currentDetectionFrame.box}
                            active={newTrackCreationPreviewState.active}
                            src={0.9}
                            mode={"creation"}
                            disabled={shapeDisabled}
                            onDragEnd={(d) => handleGestureEnd(DetectionClassName.Head, d)}
                            onTransformEnd={(d) => handleGestureEnd(DetectionClassName.Head, d)}
                            transformerVisible={true}
                        />
                    ),
                    [DetectionClassName.Plate]: (
                        <PlateDetection
                            cn={DetectionClassName.Plate}
                            box={currentDetectionFrame.box}
                            ldm={currentDetectionFrame.ldm}
                            active={newTrackCreationPreviewState.active}
                            src={0.9}
                            mode={"creation"}
                            disabled={shapeDisabled}
                            onTransformEnd={(d) => handleGestureEnd(DetectionClassName.Plate, d)}
                            onDragEnd={(d) => handleGestureEnd(DetectionClassName.Plate, d)}
                            transformerVisible={true}
                        />
                    ),
                    [DetectionClassName.Other]: (
                        <OtherDetection
                            cn={DetectionClassName.Other}
                            box={currentDetectionFrame.box}
                            ldm={currentDetectionFrame.ldm}
                            active={newTrackCreationPreviewState.active}
                            src={0.9}
                            mode={"creation"}
                            disabled={shapeDisabled}
                            onDragEnd={(d) => handleGestureEnd(DetectionClassName.Other, d)}
                            transformerVisible={true}
                            keepRatio={false}
                        />
                    )
                }[newTrackCreationPreviewState.cn]
            }
        </>
    );
};
