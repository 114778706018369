import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil";

import { autoDetectionsAtom } from "./useAutoDetections";
import { canvasMediaAtom2 } from "./useCanvasMedia";
import { framesTimestampsAtom } from "./useFramesTimestamp";
import { tracksAtom } from "./useTracks";
import { mediaAtom } from "../hooks/useMedia";

// those are used to detail the loading (can be a lot of things)
const LoadingStateCausesName = ["fetch-data", "media-loading"] as const;
type LoadingStateCauses = (typeof LoadingStateCausesName)[number];

// those are what to render when using loaders in studio
const RenderedLoadingStateName = ["init-studio", "media-loading"] as const;
type RenderedLoadingState = (typeof RenderedLoadingStateName)[number];

type LoadingState = {
    cause: LoadingStateCauses;
    isLoading: boolean;
};

export const loadingStateAtom = atom<LoadingState[]>({
    key: "loadingStateAtom",
    default: []
});

export const loadingSelector = selector<RenderedLoadingState | undefined>({
    key: "loadingSelector",
    get: ({ get }) => {
        const loadingState = get(loadingStateAtom);
        const { isLoading: mediaAtomLoading } = get(mediaAtom);
        const { isLoading: trackAtomLoading } = get(tracksAtom);
        const { isLoading: framesTimestampsAtomLoading } = get(framesTimestampsAtom);
        const { isLoading: canvasMediaAtomLoading } = get(canvasMediaAtom2);
        const { isLoading: autoDetectionsAtomLoading } = get(autoDetectionsAtom);
        if (
            loadingState.find((l) => l.cause === "fetch-data") ||
            mediaAtomLoading ||
            trackAtomLoading ||
            framesTimestampsAtomLoading ||
            canvasMediaAtomLoading ||
            autoDetectionsAtomLoading
        )
            return "init-studio";
        else if (loadingState.length > 0) return "media-loading";
        return undefined;
    },
    set: () => {}
});

export const useLoadingState = (): [
    RenderedLoadingState | undefined,
    { updateLoading: (loadingState: LoadingState) => void }
] => {
    const loadingState = useRecoilValue(loadingSelector);
    const setLoadingState = useSetRecoilState(loadingStateAtom);

    const updateLoading = (value: LoadingState) => {
        // remove if loading state is false
        if (!value.isLoading)
            setLoadingState((prev) => [...prev].filter((d) => d.cause !== value.cause));
        // add if loading state is true
        else setLoadingState((prev) => [...prev, value]);
    };

    return [loadingState, { updateLoading }];
};
