import React from "react";

import useWindowDimensions from "../hook/useWindowDimensions";
import "../static/scss/page.scss";
import SidebarPlugin from "../components/sidebar/SidebarPlugin";
import HeaderPlugin from "../components/header/HeaderPlugin";

const PluginPageLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { width } = useWindowDimensions();

    return (
        <>
            <SidebarPlugin />
            <HeaderPlugin />
            <div className={`page-container py-3 ${width >= 991 ? "px-4" : "task-container"}`}>
                <div className="container-fluid">{children}</div>
            </div>
        </>
    );
};

export default PluginPageLayout;
