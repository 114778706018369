import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import { useRecoilState, useRecoilValue } from "recoil";

import { mediaAtom, MediaVideo } from "../../../hooks/useMedia";
import { blurPreviewAtom } from "../../../recoil/blurPreview.atom";
import { trackEditorAtom } from "../../../recoil/trackEditor.atom";

export const TrackEditor = () => {
    const [trackEditorState, setTrackEditorState] = useRecoilState(trackEditorAtom);
    const containerRef = useRef<HTMLDivElement>(null!);
    const mediaState = useRecoilValue(mediaAtom);
    const minRef = useRef<HTMLInputElement>(null!);
    const maxRef = useRef<HTMLInputElement>(null!);
    const blurPreviewState = useRecoilValue(blurPreviewAtom);
    const [rangeLeft, setRangeLeft] = useState(0);
    const [rangeRight, setRangeRight] = useState(0);

    useEffect(() => {
        if (!trackEditorState) return;
        const minValue = 0;
        const maxValue = (mediaState as MediaVideo).nbFrames - 1;
        const leftPercent = ((trackEditorState.min - minValue) / (maxValue - minValue)) * 100;
        const rightPercent =
            100 - ((trackEditorState.max - minValue) / (maxValue - minValue)) * 100;
        setRangeLeft(leftPercent);
        setRangeRight(rightPercent);
    }, [trackEditorState]);

    if (!trackEditorState) return null;

    const handleChangeValue = (action: "min" | "max") => (e: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);
        if (action === "min") {
            if (value < trackEditorState.max) {
                setTrackEditorState((prev) => (prev ? { ...prev, min: value } : undefined));
                trackEditorState.onChangeMin(value);
            }
        } else {
            if (value > trackEditorState.min) {
                setTrackEditorState((prev) => (prev ? { ...prev, max: value } : undefined));
                trackEditorState.onChangeMax(value);
            }
        }
    };

    const handleMouseUp = (action: "min" | "max") => (e: React.MouseEvent<HTMLInputElement>) => {
        const value = parseInt(e.currentTarget.value);
        if (action === "min") {
            if (trackEditorState.onReleaseMin) trackEditorState.onReleaseMin(value);
        } else {
            if (trackEditorState.onReleaseMax) trackEditorState.onReleaseMax(value);
        }
    };

    return (
        <div ref={containerRef} className={`track-editor`}>
            <div className="track-editor__range-container">
                <div
                    id="range"
                    style={{
                        left: `${rangeLeft}%`,
                        right: `${rangeRight}%`
                    }}
                ></div>
            </div>
            <input
                ref={minRef}
                type={"range"}
                id="min"
                value={trackEditorState.min}
                min={0}
                max={(mediaState as MediaVideo).nbFrames - 1}
                step={1}
                disabled={(mediaState as MediaVideo)?.isPlaying || blurPreviewState}
                onChange={handleChangeValue("min")}
                onMouseUp={handleMouseUp("min")}
            ></input>
            <input
                ref={maxRef}
                type={"range"}
                id="max"
                disabled={(mediaState as MediaVideo)?.isPlaying || blurPreviewState}
                value={trackEditorState.max}
                min={0}
                max={(mediaState as MediaVideo).nbFrames - 1}
                step={1}
                onChange={handleChangeValue("max")}
                onMouseUp={handleMouseUp("max")}
            ></input>
        </div>
    );
};
