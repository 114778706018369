import { useContext } from "react";

import { ACTION_TYPE, dragNDropContext } from "./DragNDropContext";
const zipFormats = [
    "application/zip",
    "application/x-zip-compressed",
    "application/zip-compressed"
];

export const useDragNDrop = () => {
    const store = useContext(dragNDropContext);

    const clearDropZone = () => {
        store.dispatch({ type: ACTION_TYPE.CLEAR });
    };

    const isFile = () => {
        return !!store.state.file;
    };

    const isZip = (file: File) => {
        return zipFormats.includes(file.type);
    };

    const isDirtyVideoAcceptedFormat = (file: File, formats: string[]) => {
        const extension = file.name.toLowerCase().split(".").at(-1) as string;
        return formats.includes(extension);
    };

    return {
        ...store.state,
        clearDropZone,
        isFile,
        isZip,
        isDirtyVideoAcceptedFormat
    };
};
