import React, { useContext, useEffect, useState } from "react";

import Cookies from "js-cookie";
import { Route, RouteProps, Redirect, useHistory } from "react-router-dom";

import { addRefreshInterceptor } from "../api/authApi";
import { ClientProvider } from "../context/clientContext";
import { socketContext } from "../context/socketContext";
import PageLayout from "../layouts/pageLayout";
import { useQuery } from "../services/hook/useQuery";

interface IPrivateRoute extends RouteProps {
    providers?: Array<
        React.FC<{
            children: React.ReactNode;
        }>
    > | null;
    layout?: React.FC<{ children: React.ReactNode }> | null;
}

const PrivateRoute: React.FC<IPrivateRoute> = ({
    component: Component,
    providers = [ClientProvider],
    layout: Layout = PageLayout,
    ...rest
}) => {
    const auth = Cookies.getJSON("Auth_manager");
    const [isConnected, setIsConnected] = useState(false);
    const socketStore = useContext(socketContext);
    const query = useQuery();
    const { push } = useHistory();
    useEffect(() => {
        addRefreshInterceptor(push);
        if (auth && auth.token) {
            setIsConnected(true);
            if (!socketStore.isConnected) {
                socketStore.initSocket(auth.token);
            }
        } else {
            setIsConnected(false);
            socketStore?.disconnectSocket();
        }
    }, [rest.location]);

    if (auth && !isConnected) {
        return null; // Or a loading indicator while the connection is being established
    }

    const redirection = query.get("redirect");
    if (redirection && isConnected) {
        return <Redirect to={redirection} />;
    }

    if (!isConnected) {
        let login = "/login";
        if (redirection) {
            login = `${login}?redirect=${redirection}`;
        }
        return <Redirect to={login} />;
    }

    let CustomRoute = <Route component={Component} {...rest} />;

    if (Layout) {
        CustomRoute = <Layout>{CustomRoute}</Layout>;
    }

    if (providers) {
        CustomRoute = providers.reduceRight((acc, Provider) => {
            return <Provider>{acc}</Provider>;
        }, CustomRoute);
    }

    return <>{CustomRoute}</>;
};

export default PrivateRoute;
