import React, { createContext, useCallback, useEffect, useState } from "react";

import { UpdateClient } from "../pages/account/pages/editAccount";
import { useAxios } from "../services/hook/requestsService";
import { ClientCurrency } from "../types";

export type Client = {
    mail: string;
    name: string;
    address?: string;
    phone?: string;
    date: {
        created: string;
        updated?: string;
    };
    type?: string;
    lastName: string;
    firstName: string;
    legalForm?: string;
    companyName?: string;
    companyId?: string;
    vatNumber?: string;
    city?: string;
    country?: string;
    postalCode?: string;
    invoicingContact?: string;
    invoicingEmail?: string;
    bankName?: string;
    bankAddress?: string;
    BIC_SWIFT?: string;
    IBAN?: string;
    isTrial: boolean;
    support?: string;
    cardLast4?: string;
    cardExpMonth?: number;
    creditCardExpirationDate?: number;
    optIn?: boolean;
    currency: ClientCurrency;
};

export type ClientState = {
    error: any;
    loading: boolean;
    dataClient: Client | null;
    getClientData: () => Client | null;
    refetchData: () => void;
    updateClient: (values: UpdateClient) => Promise<void>;
    updateClientSupport: (values?: any, withRefetch?: boolean) => Promise<void>;
    refetch: boolean;
    axiosLoading: boolean;
};

const initialState: ClientState = {
    error: null,
    loading: true,
    dataClient: null,
    getClientData: () => null,
    refetchData: () => 1,
    updateClient: async (values: any) => {
        return;
    },
    updateClientSupport: async (values: any) => {
        return;
    },
    refetch: false,
    axiosLoading: true
};

const clientContext = createContext<ClientState>(initialState);

const ClientProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [clientState, setClientState] = useState(initialState);
    const axiosActions = useAxios();
    const [refetch, setRefetch] = useState(false);

    const fetchUser = useCallback(async () => {
        try {
            if (!clientState.dataClient || refetch) {
                const { data } = await axiosActions.getData(null, "client");
                if (JSON.stringify(data) !== JSON.stringify(clientState.dataClient)) {
                    setClientState({
                        ...clientState,
                        loading: false,
                        error: false,
                        dataClient: data
                    });
                }

                setRefetch(false);
            }
        } catch (e) {
            setClientState({
                ...clientState,
                loading: false,
                error: e,
                dataClient: null
            });
            setRefetch(false);
        }
    }, [refetch, clientState]);

    useEffect(() => {
        fetchUser();
    }, [fetchUser, refetch]);

    const getClientData = () => {
        return clientState.dataClient;
    };

    const refetchData = () => {
        setRefetch(true);
    };

    const updateClient = async (values: UpdateClient): Promise<void> => {
        const valuesWithMail = { ...values, sendMail: true };
        try {
            const { data } = await axiosActions.putData(null, "client", valuesWithMail);
            setClientState({
                ...clientState,
                loading: false,
                error: false,
                dataClient: data
            });
            setRefetch(true);
        } catch (e) {
            setClientState({
                ...clientState,
                loading: false,
                error: e,
                dataClient: null
            });
            setRefetch(true);
            throw e;
        }
    };

    const updateClientSupport = async (values: any, withRefetch: boolean = true): Promise<void> => {
        try {
            const { data } = await axiosActions.putData(null, "client/support", values, {
                headers: { "Content-Type": "application/json" }
            });

            if (withRefetch) setRefetch(true);
            return data;
        } catch (e) {
            if (withRefetch) {
                setClientState({
                    ...clientState,
                    loading: false,
                    error: e,
                    dataClient: null
                });
                setRefetch(true);
            }
            throw e;
        }
    };
    return (
        <clientContext.Provider
            value={{
                ...clientState,
                getClientData,
                refetchData,
                refetch,
                updateClient,
                axiosLoading: axiosActions.loading,
                updateClientSupport
            }}
        >
            {children}
        </clientContext.Provider>
    );
};

export { clientContext, ClientProvider };
