import { useContext, useEffect, useState } from "react";

import { BlurOptions, optionsSettingsState } from "./components/BlurOptions";

import { useResetRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import { AxiosProgressEvent } from "axios";
import { Formik, useFormikContext } from "formik";
import "../../../../static/scss/anonymization.scss";
import { useTranslation } from "react-i18next";
import { boolean, mixed, object } from "yup";

import CustomButton from "../../../../components/button/button";
import { dragNDropContext } from "../../../../components/dragNDrop/DragNDropContext";
import Modal, { IModal } from "../../../../components/modal/Modal";
import { taskManagementContext } from "../../../../context/TaskManagementContext";
import { useGetClient } from "../../../../hook/api/client";
import { useAskAnonymization } from "../../../../hook/queries/useAnonymization";
import { useHeader } from "../../../../hook/useHeader";
import { convertSize } from "../../../../services/tools";
import { FormAskAnonymization } from "../../../../types";

const defaultValues = {
    activation_faces_blur: true,
    activation_plates_blur: true,
    output_detections_url: false,
    with_mail: false,
    input_media: undefined,
    anonymization_mode: undefined
};

type ActionButtonsProps = {
    onConfirmTask: (data: FormAskAnonymization) => void;
    onCancelTask: () => void;
};

const ActionButtons: React.FC<ActionButtonsProps> = ({ onConfirmTask, onCancelTask }) => {
    const formContext = useFormikContext<FormAskAnonymization>();
    const { dirty, errors } = formContext;
    const canSubmit = dirty && Object.keys(errors).length === 0;
    const onSubmit = () => {
        onConfirmTask(formContext.values);
    };

    return (
        <div className="d-flex justify-content-center py-2">
            <CustomButton
                classNameType="mainWhite"
                buttonText="Cancel"
                customClass="mr-2"
                onClick={onCancelTask}
            />
            <CustomButton
                classNameType="main"
                buttonText="Create"
                customClass="ml-2"
                disabled={!canSubmit}
                onClick={onSubmit}
            />
        </div>
    );
};

// see https://github.com/jquense/yup/issues/176#issuecomment-367352042 to explain implementation
const taskValidationSchema = () =>
    object().shape(
        {
            activation_faces_blur: boolean()
                .required()
                .when("activation_plates_blur", {
                    is: (activation_plates_blur: boolean) => !activation_plates_blur,
                    then: boolean().oneOf([true], "At least one needs to be checked")
                }),
            activation_plates_blur: boolean()
                .required()
                .when("activation_faces_blur", {
                    is: (activation_faces_blur: boolean) => !activation_faces_blur,
                    then: boolean().oneOf([true], "At least one needs to be checked")
                }),
            with_mail: boolean().notRequired(),
            input_media: mixed().required(),
            output_detections_url: boolean().notRequired()
        },
        [
            ["activation_faces_blur", "activation_plates_blur"],
            ["activation_plates_blur", "activation_faces_blur"]
        ]
    );

const initialModalState: IModal = {
    show: false
};

export const Anonymization = () => {
    const { t } = useTranslation();
    const reseStepFormState = useResetRecoilState(optionsSettingsState);
    const history = useHistory();
    const [modalState, setModal] = useState<IModal>({ show: false });
    const { getTrialState } = useHeader();
    const { data: clientData } = useGetClient();
    const [progress, setProgress] = useState<number>();
    const [loading, setLoading] = useState(false);
    const context = useContext(dragNDropContext);
    const tasksContext = useContext(taskManagementContext);
    // reset recoil state when we leave the page
    useEffect(() => {
        return () => {
            reseStepFormState();
        };
    }, []);

    useEffect(() => reseStepFormState, []);

    useEffect(() => {
        if (!context.state.error) return;
        setModal({
            ...modalState,
            show: true,
            title: context.state.error?.title,
            message: context.state.error.message,
            onConfirm: () => setModal({ ...modalState, show: false }),
            showCancel: false,
            progress: null,
            loading: undefined
        });
    }, [context.state.error]);

    const handleProgressUpload = (progressEvent: AxiosProgressEvent) => {
        const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / (progressEvent as any).total
        );
        setProgress(percentCompleted);
    };

    const mutation = useAskAnonymization(handleProgressUpload);

    const handleSubmit = (data: FormAskAnonymization) => {
        setLoading(true);
        setModal({ ...modalState, withCheckBox: false, show: true });
        mutation.mutate(
            { ...data },
            {
                onSuccess: () => {
                    setLoading(false);
                    tasksContext.handleRefetch(true);
                    history.push("/task-manager");
                },
                onError: () => {
                    setLoading(false);
                    setProgress(undefined);
                    setModal({
                        ...modalState,
                        show: true,
                        message: t("error.internalError"),
                        onConfirm: () => setModal({ ...modalState, show: false }),
                        showCancel: false,
                        progress: null,
                        loading: undefined
                    });
                }
            }
        );
    };

    const onConfirmTask = (data: FormAskAnonymization) => {
        let modalParams = {
            show: true,
            confirmBtnText: t("task-management.tasks.modal.confirm.accept-button")
        } as IModal;
        const file = data.input_media as File;
        if (clientData?.isTrial && getTrialState() - file.size < 0) {
            modalParams = {
                ...modalParams,
                showCancel: false,
                message: t("error.trialLimitation-message"),
                title: t("error.trialLimitation-title"),
                onConfirm: () => setModal({ ...modalState, show: false })
            };
        } else {
            modalParams = {
                ...modalParams,
                showCancel: true,
                message: t("task-management.tasks.modal.confirm.message", {
                    serviceName: "Anonymization",
                    fileSize: convertSize(data.input_media?.size ?? 0)
                }),
                onCancel: () => setModal({ ...modalState, show: false }),
                onConfirm: (checkboxValue: boolean) => {
                    handleSubmit({ ...data, with_mail: checkboxValue });
                },
                confirmBtnText: t("task-management.tasks.modal.confirm.accept-button"),
                cancelBtnText: t("task-management.tasks.modal.confirm.cancel-button"),
                withCheckBox: true
            };
        }
        setModal({ ...modalParams, progress, loading });
    };

    const onCancelTask = () => {
        setModal({
            show: true,
            showCancel: true,
            message: t("task-management.tasks.modal.cancel.message"),
            onConfirm: () => {
                setModal({ ...initialModalState, progress: null, loading: undefined, show: false });
                history.push("/task-manager");
            },
            onCancel: () => setModal({ ...initialModalState, show: false }),
            confirmBtnText: t("task-management.tasks.modal.cancel.accept-button"),
            cancelBtnText: t("task-management.tasks.modal.cancel.cancel-button")
        });
    };

    return (
        <Formik
            initialValues={defaultValues}
            onSubmit={handleSubmit}
            validationSchema={taskValidationSchema}
            validateOnMount={true}
            enableReinitialize={true}
        >
            <>
                <BlurOptions />
                <Modal
                    progress={progress}
                    loading={loading}
                    message={
                        loading
                            ? t("task-management.tasks.modal.loading-message")
                            : modalState.message
                    }
                    {...modalState}
                />
                <div className="row d-flex align-items-center justify-content-center py-3">
                    <p className="col-12 col-md-8 offset-md-2 laius text-center m-0 color-clear-blue-grey">
                        {t("task-management.tasks.image-processing")}
                    </p>
                </div>
                <ActionButtons onConfirmTask={onConfirmTask} onCancelTask={onCancelTask} />
            </>
        </Formik>
    );
};
