import { Fragment, useRef, useEffect, useState } from "react";

import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import { Ellipse, Group, Rect, Transformer } from "react-konva";

import { useCanvasMedia } from "../../../../../hooks/useCanvasMedia";
import {
    Box,
    DetectionClassName,
    DetectionHead
} from "../../../../../recoil/framesDetectionsCoordinates.atom";
import { DetectionState, rectStyle } from "../../types";

type Props = Konva.RectConfig &
    Omit<DetectionHead, "trackId" | "src"> &
    DetectionState & {
        onEndCreation?: (data: { data: Box; cn: DetectionClassName }) => void;
        handleClick?: () => void;
        onDetectionHover?: () => void;
        onDetectionHoverLeave?: () => void;
        onDragEnd?: (box: Box) => void;
        onTransformEnd?: (box: Box, evt?: KonvaEventObject<Event>) => void;
        transformerVisible?: boolean;
    };

export const HeadDetection: React.FC<Props> = (props) => {
    const rectRef = useRef<Konva.Rect>(null!);
    const transformerRef = useRef<Konva.Transformer>(null!);
    const ellipseRef = useRef<Konva.Ellipse>(null!);
    const [isHover, setIsHover] = useState(false);
    const isTransforming = useRef(false);
    const [{ dimensionRatio }] = useCanvasMedia();
    useEffect(() => {
        if (!transformerRef.current || props.disabled) return;
        rectRef.current.zIndex(1);
        transformerRef.current.zIndex(2);
        ellipseRef.current.zIndex(0);
        transformerRef.current.nodes([rectRef.current, ellipseRef.current]);
    }, [props.box, props.disabled, isHover]);

    const handleMouseEnter = () => {
        if (props.disabled) return;
        if (props.onDetectionHover) props.onDetectionHover();
    };

    const handleMouseLeave = () => {
        if (props.disabled) return;
        if (props.onDetectionHoverLeave) props.onDetectionHoverLeave();
    };

    // https://codesandbox.io/s/github/konvajs/site/tree/master/react-demos/transformer?from-embed=&file=/src/index.js:3043-3053
    const handleTransformEnd = (evt: KonvaEventObject<Event>) => {
        isTransforming.current = false;
        const node = rectRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();
        if (props.onTransformEnd) {
            const coordinates = {
                x: node.x(),
                y: node.y(),
                width: Math.max(node.width() * scaleX),
                height: Math.max(node.height() * scaleY)
            };
            props.onTransformEnd(coordinates, evt);
        }
        rectRef?.current?.setAttrs({ scaleX: 1, scaleY: 1 });
        ellipseRef.current.setAttrs({ scaleX: 1, scaleY: 1 });
    };

    const handleDragEnd = (evt: KonvaEventObject<Event>) => {
        isTransforming.current = false;
        const node = rectRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();
        evt.target.setAttrs({
            width: node.width() * scaleX,
            height: node.height() * scaleY
        });
        if (props.onDragEnd) {
            const coordinates = {
                x: node.x(),
                y: node.y(),
                width: Math.max(node.width() * scaleX),
                height: Math.max(node.height() * scaleY)
            };
            props.onDragEnd(coordinates);
        }
        rectRef?.current?.setAttrs({ scaleX: 1, scaleY: 1 });
        ellipseRef.current.setAttrs({ scaleX: 1, scaleY: 1 });
    };

    const handleMouseEnterGroup = () => {
        if (props.disabled) return;
        setIsHover(true);
    };

    const handleMouseLeaveGroup = () => {
        if (props.disabled) return;
        if (!isTransforming.current) setIsHover(false);
    };

    const handleDragAndTransform = (evt: KonvaEventObject<Event>) => {
        if (props.disabled) return;
        if (!dimensionRatio) return;
        const { x, y } = evt.currentTarget.position();
        const newWidth = dimensionRatio.centerShift_x + dimensionRatio.frameWidth - x;
        const newHeight = dimensionRatio.centerShift_y + dimensionRatio.frameHeight - y;
        if (x < dimensionRatio.centerShift_x)
            evt.currentTarget.setAttr("x", dimensionRatio.centerShift_x);
        else if (
            x + evt.currentTarget.width() >
            dimensionRatio.centerShift_x + dimensionRatio.frameWidth
        ) {
            evt.currentTarget.setAttr("width", newWidth);
            evt.currentTarget.setAttr("x", x);
        } else if (y < dimensionRatio.centerShift_y)
            evt.currentTarget.setAttr("y", dimensionRatio.centerShift_y);
        else if (
            y + evt.currentTarget.height() >
            dimensionRatio.centerShift_y + dimensionRatio.frameHeight
        ) {
            evt.currentTarget.setAttr("height", newHeight);
            evt.currentTarget.setAttr("y", y);
        }
        isTransforming.current = true;
    };

    const handleClick = () => {
        if (props.disabled) return;
        if (props.handleClick) props.handleClick();
    };

    return (
        <Fragment>
            <Group onMouseEnter={handleMouseEnterGroup} onMouseLeave={handleMouseLeaveGroup}>
                <Rect
                    stroke={isHover ? rectStyle[props.mode].stroke : undefined}
                    x={props.box.x}
                    y={props.box.y}
                    height={props.box.height}
                    width={props.box.width}
                    ref={rectRef}
                    strokeScaleEnabled={false}
                    strokeWidth={1}
                    onMouseEnter={handleMouseEnter}
                    draggable={!props.disabled}
                    onMouseLeave={handleMouseLeave}
                    onTransformEnd={handleTransformEnd}
                    onDragEnd={handleDragEnd}
                    onDragMove={handleDragAndTransform}
                    onClick={handleClick}
                />
                {(props.transformerVisible || isHover || isTransforming.current === true) && (
                    <Transformer
                        draggable={!props.disabled}
                        ref={transformerRef}
                        anchorStroke={isHover ? rectStyle[props.mode].stroke : "transparent"}
                        anchorFill={isHover ? rectStyle[props.mode].fill : "transparent"}
                        anchorSize={4}
                        borderStrokeWidth={1}
                        flipEnabled={false}
                        rotateEnabled={false}
                        onDragMove={handleDragAndTransform}
                        onTransform={handleDragAndTransform}
                        borderStroke="transparent"
                        keepRatio={false}
                    />
                )}
                <Ellipse
                    ref={ellipseRef}
                    x={props.box.x + props.box.width / 2}
                    y={props.box.y + props.box.height / 2}
                    height={props.box.height}
                    width={props.box.width}
                    radiusX={Math.abs(props.box.width) / 2}
                    radiusY={Math.abs(props.box.height) / 2}
                    strokeWidth={1}
                    {...rectStyle[props.mode]}
                />
            </Group>
        </Fragment>
    );
};
