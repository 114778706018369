import React, { useState, useEffect, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// Style
import "./taskButton.scss";

// Icon
import { useRecoilValue } from "recoil";

import { TASKS_UNAVAILABLE_TYPES, serviceUsableState } from "../../../recoil/services.atom";
import { ReactComponent as IconAnomyzation } from "../../../static/icons/tasks/anonymization.svg";
import Modal, { IModal } from "../../modal/Modal";

type TTaskList = {
    url: string;
    classes: string;
    icon?: any;
    title: string;
};

const TaskButton: React.FC = () => {
    const [y, setY] = useState(window.scrollY);
    const [scrollDirection, setScrollDirection] = useState<string>("up");
    const { t } = useTranslation();
    const canUseTask = useRecoilValue(serviceUsableState);
    const [modalState, setModal] = useState<IModal>({
        show: false,
        showCancel: false,
        onConfirm: () => setModal({ ...modalState, show: false })
    });
    const { push } = useHistory();

    const handleNavigation = useCallback(
        (e: Event): void => {
            const window = e.currentTarget as Window;

            if (y > window.scrollY) {
                setScrollDirection("up");
            } else if (y < window.scrollY) {
                setScrollDirection("down");
            }

            setY(window.scrollY);
        },
        [y]
    );

    const handleModal = () => {
        if (canUseTask.reason === TASKS_UNAVAILABLE_TYPES.INACTIVE_SERVICES) {
            setModal((prev) => ({
                ...prev,
                show: true,
                title: t("error.servicesDeactivated-title"),
                message: t("error.servicesDeactivated-message")
            }));
        }
        if (canUseTask.reason === TASKS_UNAVAILABLE_TYPES.CARD_EXPIRED) {
            setModal((prev) => ({
                ...prev,
                show: true,
                title: t("error.cardExpired-title"),
                message: t("error.cardExpired-message")
            }));
        }
    };

    const handleButtonService = (goTo: string) => {
        if (!canUseTask.status) {
            handleModal();
        } else {
            push(goTo);
        }
    };

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation, true);

        return () => {
            window.removeEventListener("scroll", handleNavigation, true);
        };
    }, [handleNavigation]);

    return (
        <div className="task-actions-container">
            <button
                type="button"
                className={`button btn-task scroll-${scrollDirection}`}
                title={t("taskButton.create")}
                onClick={() => handleButtonService("/task-manager/anonymization")}
            >
                <div className="icon">
                    <IconAnomyzation />
                </div>
                <span className="button-text">{t("taskButton.create")}</span>
            </button>
            <Modal {...modalState} />
        </div>
    );
};

export default TaskButton;
