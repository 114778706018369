import { Suspense } from "react";

import { RecoilRoot } from "recoil";

import Routes from "./routing/router";

const App = () => {
    return (
        <RecoilRoot>
            <Suspense fallback={<></>}>
                <div className="App">
                    <Routes />
                </div>
            </Suspense>
        </RecoilRoot>
    );
};

export default App;
