import { useRecoilState } from "recoil";

import { Card, cardsAtom } from "./cards.atom";

type Methods = {
    addCard: (data: Card) => void;
    removeCard: (id: string) => void;
    updateCard: (data: Card) => void;
    removeAllCards: () => void;
    closeCard: (id: string) => void;
};

export const useCards = (): [Card[], Methods] => {
    const [cardsState, setCardsState] = useRecoilState(cardsAtom);

    const addCard = ({ closeOnNewEntry = true, ...rest }: Card) => {
        let tmp = [...cardsState];
        tmp = tmp.map((card) => ({ ...card, open: card.closeOnNewEntry ? false : card.open }));
        tmp.push({ closeOnNewEntry, ...rest });
        setCardsState(tmp);
    };

    const removeCard = (id: string) => {
        const index = cardsState.findIndex((d) => id === d.id);
        const tmp = [...cardsState];
        tmp.splice(index, 1);
        setCardsState(tmp);
    };

    const removeAllCards = () => {
        const tmp = cardsState.map((d) => ({
            ...d,
            open: false
        }));
        setCardsState(tmp);
    };

    const updateCard = (data: Card) => {
        const cardIndex = cardsState.findIndex((c) => c.id === data.id);
        if (!cardIndex && cardIndex !== 0) return;
        const tmp = [...cardsState];
        tmp[cardIndex] = data;
        setCardsState(tmp);
    };

    const closeCard = (id: string) => {
        setCardsState((prev) => {
            return prev.map((d) => {
                if (d.id === id) {
                    return {
                        ...d,
                        open: false
                    };
                } else {
                    return d;
                }
            });
        });
    };

    return [cardsState, { addCard, removeCard, updateCard, removeAllCards, closeCard }];
};
