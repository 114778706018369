import React, { useContext, useEffect, useState } from "react";

import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { lazy } from "yup";

import CustomButton from "../../components/button/button";
import { Checkbox } from "../../components/checkbox/checkbox";
import Input from "../../components/input/Input";
import Modal, { IModal } from "../../components/modal/Modal";
import CustomSelect from "../../components/select/CustomSelect";
import { clientContext } from "../../context/clientContext";
import { isObjectEmpty } from "../../services/tools";
import { validatePayment } from "../../services/validations/paymentValidation";
import { Client } from "../../types";

export const UpdateTrial: React.FC = () => {
    const { dataClient, updateClientSupport } = useContext(clientContext);
    const { t } = useTranslation();
    const { push } = useHistory();
    const location = useLocation();
    const [modalState, setModalState] = useState({} as IModal);
    const [loading, setIsLoading] = useState(false);
    const { search } = location;
    const isPro = new URLSearchParams(search).get("pro") === "true";
    const currency = new URLSearchParams(search).get("currency") ?? dataClient?.currency;

    useEffect(() => {
        if (!isPro) push("?pro=false");
        return;
    }, []);

    const getClientInitialValues = (client: Client) => {
        return {
            type: client.type ?? "",
            address: client.address ?? "",
            lastName: client.lastName ?? "",
            firstName: client.firstName ?? "",
            postalCode: client.postalCode ?? "",
            city: client.city ?? "",
            mail: client.mail ?? "",
            country: client.country ?? "",
            optIn: client.optIn || false,
            conditions: true,
            companyName: client.companyName ?? ""
        };
    };

    const handleChangeSelect = (values: Record<string, any>, setValues: any) => (value: any) => {
        setValues({
            ...values,
            type: value.value
        });
    };

    const getValueSelect = (valueType?: string) => {
        if (valueType === "COMPANY") {
            return { label: "Company", value: "COMPANY" };
        } else {
            return { label: "Individual", value: "INDIVIDUAL" };
        }
    };

    const handleChangeCheckbox = (values: Record<string, any>, setValues: any) => (e: any) => {
        setValues({
            ...values,
            [e.target.value]: !values[e.target.value]
        });
    };

    const submitUpdatePlan = async (values: any) => {
        try {
            setIsLoading(true);
            const response: any = await updateClientSupport({
                ...values,
                support: isPro ? "PRO" : "BASIC",
                currency: currency
            });
            if (response.url) {
                window.location = response.url;
            }
        } catch (e) {
            setIsLoading(false);
            push("/account/plan?status=failed");
        }
    };

    const handleFormSubmit = (values: any) => {
        setModalState({
            show: true,
            title: "Update plan",
            confirmBtnText: "Confirm",
            message: `You are about to change yout current plan to a "Pay as you Go" plan ${
                isPro ? 'with "Support PRO" option' : ""
            }. Please confirm.`,
            showCancel: true,
            cancelBtnText: "Cancel",
            onCancel: () => setModalState({ ...modalState, show: false }),
            onConfirm: () => submitUpdatePlan(values)
        });
    };

    if (!dataClient || loading) {
        return null;
    } else {
        return (
            <div className="middle-container">
                <h2 className="mb-4">Update your plan</h2>
                <div className="plan-trial-container">
                    <h3>
                        Please complete your informations to subscribe to
                        <strong>Pay as you Go</strong> plan
                    </h3>
                    <Formik
                        validateOnMount={true}
                        onSubmit={handleFormSubmit}
                        initialValues={getClientInitialValues(dataClient as unknown as Client)}
                        validationSchema={() => lazy((_) => validatePayment())}
                    >
                        {({ handleChange, values, errors, setValues, handleSubmit }) => {
                            return (
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    className="form-trial"
                                >
                                    <div className="d-flex flex-wrap">
                                        <div className="d-flex flex-wrap justify-content-between col-12 pe-lg-4 mb-3">
                                            <CustomSelect
                                                isRequired={true}
                                                isDisabled={!dataClient.isTrial}
                                                onChange={handleChangeSelect(values, setValues)}
                                                options={[
                                                    { label: "Company", value: "COMPANY" },
                                                    { label: "Individual", value: "INDIVIDUAL" }
                                                ]}
                                                value={getValueSelect(values.type)}
                                                placeholder={t(
                                                    "payment.step-2.you-are-placeholder"
                                                )}
                                                customClass="mb-3"
                                                withLabel={true}
                                                labelTranslation={t("payment.step-2.you-are")}
                                                order={0}
                                            />
                                            {getValueSelect(values.type).value === "COMPANY" ? (
                                                <Input
                                                    withLabel={true}
                                                    inputType="text"
                                                    id="companyName"
                                                    onChange={handleChange}
                                                    labelTranslation={t(
                                                        "payment.step-2.companyName"
                                                    )}
                                                    placeholder={t(
                                                        "payment.step-2.companyName-placeholder"
                                                    )}
                                                    customClassInput="pl-1"
                                                    value={values.companyName}
                                                    isRequired={true}
                                                    order={1}
                                                />
                                            ) : (
                                                <div className="form-group form-order-5 mb-3"></div>
                                            )}
                                            <Input
                                                withLabel={true}
                                                inputType="text"
                                                id="lastName"
                                                onChange={handleChange}
                                                labelTranslation={t("payment.step-2.lastname")}
                                                placeholder={t(
                                                    "payment.step-2.lastname-placeholder"
                                                )}
                                                customClassInput="pl-1"
                                                value={values.lastName}
                                                isRequired={true}
                                                order={2}
                                            />
                                            <Input
                                                withLabel={true}
                                                inputType="text"
                                                id="firstName"
                                                onChange={handleChange}
                                                labelTranslation={t("payment.step-2.firstname")}
                                                placeholder={t(
                                                    "payment.step-2.firstname-placeholder"
                                                )}
                                                customClassInput="pl-1"
                                                value={values.firstName}
                                                isRequired={true}
                                                order={3}
                                            />
                                            <Input
                                                withLabel={true}
                                                inputType="text"
                                                id="address"
                                                onChange={handleChange}
                                                labelTranslation={t("payment.step-2.address")}
                                                placeholder={t(
                                                    "payment.step-2.address-placeholder"
                                                )}
                                                customClassInput="pl-1"
                                                value={values.address}
                                                isRequired={true}
                                                order={5}
                                            />
                                            <Input
                                                withLabel={true}
                                                inputType="text"
                                                id="postalCode"
                                                onChange={handleChange}
                                                labelTranslation={t("payment.step-2.postal-code")}
                                                placeholder={t(
                                                    "payment.step-2.postal-code-placeholder"
                                                )}
                                                customClassInput="pl-1"
                                                value={values.postalCode}
                                                isRequired={true}
                                                order={6}
                                            />
                                            <Input
                                                withLabel={true}
                                                inputType="text"
                                                id="city"
                                                onChange={handleChange}
                                                labelTranslation={t("payment.step-2.city")}
                                                placeholder={t("payment.step-2.city-placeholder")}
                                                customClassInput="pl-1"
                                                value={values.city}
                                                isRequired={true}
                                                order={7}
                                            />
                                            <Input
                                                withLabel={true}
                                                inputType="text"
                                                id="country"
                                                onChange={handleChange}
                                                labelTranslation={t("payment.step-2.country")}
                                                placeholder={t(
                                                    "payment.step-2.country-placeholder"
                                                )}
                                                customClassInput="pl-1"
                                                value={values.country}
                                                isRequired={true}
                                                order={8}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column mb-3">
                                        <Checkbox
                                            value="conditions"
                                            onClick={handleChangeCheckbox(values, setValues)}
                                            isChecked={values.conditions}
                                            labelText={t("payment.step-2.conditions")}
                                            isRequired={true}
                                        />
                                        <Checkbox
                                            value="optIn"
                                            onClick={handleChangeCheckbox(values, setValues)}
                                            isChecked={values.optIn}
                                            labelText={t("payment.step-2.opt-in")}
                                        />
                                    </div>
                                    <div className="d-flex w-100 justify-content-center pe-4 pb-4 pb-lg-0 align-items-end">
                                        <CustomButton
                                            onClick={() => push("/account/plan")}
                                            buttonType="button"
                                            buttonText={t("payment.step-2.button-back")}
                                            classNameType="mainWhite"
                                            customClass="mr-1"
                                        />
                                        <CustomButton
                                            customClass={!isObjectEmpty(errors) ? "grey-dark" : ""}
                                            buttonType="submit"
                                            buttonText={t("payment.step-2.button-submit")}
                                            disabled={!isObjectEmpty(errors)}
                                        />
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
                <div className="d-flex justify-content-center">
                    <i>You will be redirected to a secured payment page</i>
                </div>
                <Modal {...modalState} loading={loading} />
            </div>
        );
    }
};
