import { atom, useRecoilState, useRecoilValue } from "recoil";

import { newTrackCreationPreviewAtom } from "./useNewTrackCreationPreview";
import { trackPreviewAtom } from "./useTrackPreview";
import { GetMediaMetadataResponse } from "../../../../../types";
import { Box, DetectionClassName, Point } from "../recoil/framesDetectionsCoordinates.atom";

export type DetectionCoordinatesRange = {
    frameStart: number;
    frameEnd: number;
    box: Box;
    ldm: Array<Point>;
    src: number;
};

export type Track1 = {
    trackId: number;
    frameStart: number;
    frameEnd: number;
    cn: DetectionClassName;
    initialRange: DetectionCoordinatesRange[];
    savedRange: DetectionCoordinatesRange[];
    active: boolean;
    type: "auto" | "new";
};

export type TracksState = {
    isLoading: boolean;
    tracks: Track1[];
};

export const tracksAtom = atom<TracksState>({
    key: "tracksAtom1",
    default: {
        isLoading: true,
        tracks: []
    }
});

export const useTracks = (): [
    TracksState,
    {
        initTracks: (data: GetMediaMetadataResponse) => void;
        updateTrackActive: (trackId: number) => void;
        updateTrack: () => void;
        removeTrack: (trackId: number) => void;
        addTrack: () => void;
    }
] => {
    const [tracksState, setTracksState] = useRecoilState(tracksAtom);
    const trackPreviewState = useRecoilValue(trackPreviewAtom);
    const newTrackState = useRecoilValue(newTrackCreationPreviewAtom);
    /**
     * This function MUST be called JUST ONCE when all get api calls are finished
     * It prepares the formatted tracks and detections to fit with the required frames range format
     * @param detectionsData // detections from api
     * @param dimensions // the canvas of the user calculated
     */
    const initTracks = (data: GetMediaMetadataResponse) => {
        const apiTracks = data.framesSorted.tracks;
        const apiFrames = data.framesSorted.frames;
        const formattedTracks: Track1[] = [];

        const getCnFromFramesByTrackId = (trackId: number) => {
            const frame = Object.values(apiFrames).find((f) =>
                f.dtcs.find((dtc) => dtc.trk === trackId)
            );
            const dtc = frame?.dtcs.find((d) => d.trk === trackId);
            return dtc?.cn;
        };

        for (const [id, trackData] of Object.entries(apiTracks)) {
            const trackId = parseInt(id);
            const cn = getCnFromFramesByTrackId(trackId);
            if (cn) {
                const track: Track1 = {
                    trackId: trackId,
                    frameStart: trackData.ffrm,
                    frameEnd: trackData.lfrm,
                    active: true,
                    savedRange: [],
                    initialRange: [],
                    cn: cn as unknown as DetectionClassName,
                    type: "auto"
                };
                formattedTracks.push(track);
            }
        }
        setTracksState({
            isLoading: false,
            tracks: formattedTracks
        });
    };

    const addTrack = () => {
        if (!newTrackState) return;
        const tracksIds = tracksState.tracks.map((t) => t.trackId);
        const newTrackId = tracksIds.length > 0 ? Math.max(...tracksIds) + 1 : 0;
        const newTrack: Track1 = {
            trackId: newTrackId,
            frameStart: newTrackState.frameStart,
            frameEnd: newTrackState.frameEnd,
            cn: newTrackState.cn,
            active: true,
            initialRange: newTrackState.initialRange.map((ranges) => ({ ...ranges, src: 0.9 })),
            savedRange: [],
            type: "new"
        };
        setTracksState((prev) => ({ ...prev, tracks: [...prev.tracks, newTrack] }));
    };

    const updateTrackActive = (trackId: number) => {
        const tracksTmp = [...tracksState.tracks];
        const trackIndex = tracksTmp.findIndex((track) => track.trackId === trackId);
        if (trackIndex < 0)
            throw new Error(
                `Hook useTracks: method "updateTrackActive" No track with id ${trackId}`
            );
        tracksTmp[trackIndex] = { ...tracksTmp[trackIndex], active: !tracksTmp[trackIndex].active };
        setTracksState((prev) => ({ ...prev, tracks: tracksTmp }));
    };

    const updateTrack = () => {
        if (!trackPreviewState) return;
        const trackToUpdateIndex = tracksState.tracks.findIndex(
            (t) => t.trackId === trackPreviewState.trackId
        );
        if (trackToUpdateIndex < 0) return;

        const newFrameStart = trackPreviewState.frameStart;
        const newFrameEnd = trackPreviewState.frameEnd;
        const newRanges = [...trackPreviewState.initialRange.map((r) => ({ ...r, src: 0.9 }))];
        const newActive = trackPreviewState.active;

        const tracksTmp = [...tracksState.tracks];
        tracksTmp[trackToUpdateIndex] = {
            ...tracksTmp[trackToUpdateIndex],
            frameStart: newFrameStart,
            frameEnd: newFrameEnd,
            active: newActive,
            savedRange: newRanges
        };
        setTracksState((prev) => ({ ...prev, tracks: tracksTmp }));
    };

    const removeTrack = (trackId: number) => {
        const tracksTmp = [...tracksState.tracks];
        const trackToDeleteIndex = tracksTmp.findIndex((track) => track.trackId === trackId);
        if (trackToDeleteIndex < 0) {
            console.error(`Hook useTracks: method "removeTrack" No track  with id ${trackId}`);
        }
        tracksTmp.splice(trackToDeleteIndex, 1);
        setTracksState((prev) => ({ ...prev, tracks: tracksTmp }));
    };

    return [tracksState, { initTracks, updateTrackActive, updateTrack, removeTrack, addTrack }];
};
