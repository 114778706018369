import { FrameTimestamp } from "./storage";

export enum AnonymizationModeEnum {
    AUTO = "auto",
    MANUAL = "manual"
}

export enum AnonymizationType {
    Head = "head",
    Plate = "plate",
    Other = "other"
}

const BlurOptionsKeys = [
    "activation_plates_blur",
    "activation_faces_blur",
    "output_detections_url"
] as const;
export type BlurOptionsKey = (typeof BlurOptionsKeys)[number];
type BlurOptions = { [K in BlurOptionsKey]: boolean };

export type FormAskAnonymization = {
    with_mail: boolean;
    input_media?: File;
} & BlurOptions;

export type AskAnonymizationRequest = Omit<FormAskAnonymization, "anonymization_mode">;

export type AskAnonymizationResponse = { message: string };

export type DetectionBox = { l: number; t: number; r: number; b: number };

export type Detection = {
    cn: AnonymizationType;
    src: number;
    box: DetectionBox;
    trk: number;
    active?: boolean;
};

export type DetectionsFrame = {
    ts: number;
    dtcs: Detection[];
};
export type DetectionsTrack = object;

export type Detections = {
    frames: Record<string, DetectionsFrame>;
    tracks: DetectionsTrack[];
};

export namespace AnonymizationDetection {
    export type DetectionBox = { l: number; t: number; r: number; b: number };

    export type Detection = {
        cn: AnonymizationType;
        src: number;
        box: DetectionBox;
        trk: number;
        active?: boolean;
        ldm?: { x: number; y: number }[];
    };

    export type DetectionsFrame = {
        ts: number;
        dtcs: Detection[];
    };
    export type DetectionsTrack = {
        ffrm: number; // first frame of the track
        lfrm: number; // last frame of the track
        active?: boolean;
    };

    export type Detections = {
        frames: Record<string, DetectionsFrame>;
        tracks: Record<string, DetectionsTrack>;
    };
}

export type FormAskRendering = {
    framesSorted: AnonymizationDetection.Detections;
    jobId: string;
    with_mail?: boolean;
};

export type FormUpdateDetections = {
    framesSorted: AnonymizationDetection.Detections;
    jobId: string;
};

export type LandMarkPosition = { x: number; y: number };

export type DetectionsState = AnonymizationDetection.Detections & { timestamps: FrameTimestamp[] };
