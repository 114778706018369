import axios from "axios";

export const readFileContent = async (path: string): Promise<string | null> => {
    try {
        const response = await axios.get<string>(path, {
            headers: { "Content-Type": "text/plain" },
            responseType: "text"
        });
        const content = response.data.trim(); // Trim to check if the file is empty
        return (content.length > 0) ? content : null;
    } catch (e) {
        return null;
    }
};

export const checkFileExists = async (url: string): Promise<boolean> => {
    try {
        const response = await axios.head(url);
        return response.status === 200;
    } catch (e) {
        return false;
    }
};
