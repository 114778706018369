import "../../styles/sidebar.scss";
import { CardsContainer } from "./cards/CardsContainer";
import { ThumbsGrid } from "./ThumbsGrid";

export const Sidebar = () => {
    return (
        <div className="sidebar">
            <div className="sidebar__header">
                <div className="sidebar__header__title active">
                    <span>Detections</span>
                </div>
                <div className="sidebar__header__title inactive">
                    <span>Audio track</span>
                    <div className="sidebar__header__title__badge">
                        <small>Soon</small>
                    </div>
                </div>
            </div>

            <ThumbsGrid />
            <CardsContainer />
        </div>
    );
};
