import React from "react";

import { useTranslation } from "react-i18next";

import { ReactComponent as LogoSvg } from "../../../static/images/logo-Blurit.svg";

const LoginPluginSuccess: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="row h-100" style={{ marginRight: 0 }}>
            <div className="col-12">
                <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex flex-column flex-start auth-container plugin-container">
                        <div>
                            <div className="mb-5 d-flex justify-content-center">
                                <LogoSvg />
                            </div>
                            <div className="mb-2">
                                <h1 className="mb-3">{t(`login-plugin.success.title`)}</h1>
                                <p id="login-laius">{t(`login-plugin.success.laius`)}</p>
                            </div>
                        </div>
                        <p className="login-success">{t(`login-plugin.success.content`)}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default LoginPluginSuccess;
