import { atom, useRecoilState } from "recoil";

import { FrameTimestamp as FTapi } from "../../../../../types/storage";

export type FrameTimestamp = {
    duration: number;
    timestamp: number;
};

type FramesTimestampsObject = {
    isLoading: boolean;
    framesTimestamps: FrameTimestamp[];
};

export const framesTimestampsAtom = atom<FramesTimestampsObject>({
    key: "framesTimestampsAtom",
    default: {
        isLoading: true,
        framesTimestamps: []
    }
});

export const useFramesTimestamps = (): [
    FramesTimestampsObject,
    {
        initFramesTimestamps: (initialValue: FTapi[] | undefined) => void;
        updateFramesTimestampsLoading: (value: boolean) => void;
    }
] => {
    const [framesTimestampsState, setFramesTimestampsState] = useRecoilState(framesTimestampsAtom);

    const initFramesTimestamps = (initialValue: FTapi[] | undefined) => {
        let newFramesTimestamps: FrameTimestamp[] = [];
        if (!initialValue) {
            newFramesTimestamps = [
                {
                    duration: 0,
                    timestamp: 0
                }
            ];
        } else {
            for (let i = 0; i < initialValue.length; i++) {
                const frameTimestamp: FrameTimestamp = {
                    duration: parseFloat(initialValue[i].pkt_duration_time),
                    timestamp: parseFloat(initialValue[i].pkt_pts_time)
                };
                newFramesTimestamps.push(frameTimestamp);
            }
        }
        setFramesTimestampsState({
            isLoading: false,
            framesTimestamps: newFramesTimestamps
        });
    };

    const updateFramesTimestampsLoading = (value: boolean) => {
        setFramesTimestampsState((prev) => ({ ...prev, isLoading: value }));
    };

    return [framesTimestampsState, { initFramesTimestamps, updateFramesTimestampsLoading }];
};
