import React, { useContext, useEffect, useState } from "react";

import { clientContext, Client } from "../../../context/clientContext";
import "../../../static/scss/account.scss";
import { AccountInformation } from "./components/accountInformation";
import { ClientInformation } from "./components/clientInformation";
import { PaymentInformation } from "./components/paymentInformation";
import { licensesContext } from "../../../context/licensesContext";
import { servicesContext } from "../../../context/servicesContext";

const Account: React.FC = () => {
    const [clientValues, setClientValues] = useState<Client | null>(null);
    const { dataClient, refetchData, error } = useContext(clientContext);
    const { data: dataLicenses } = useContext(licensesContext);
    const { getData: getServiceData } = useContext(servicesContext);

    useEffect(() => {
        if (!clientValues || !dataClient || !error) {
            refetchData();
            setClientValues(dataClient);
            getServiceData();
        }
    }, [dataClient]);

    return (
        <>
            <AccountInformation clientValues={clientValues} />
            <div className="mb-3" />
            <ClientInformation clientValues={clientValues} />
            <div className="mb-3" />
            <PaymentInformation clientValues={clientValues} licensesValues={dataLicenses} />
        </>
    );
};

export default Account;
