import { useSetRecoilState } from "recoil";

import { _getArrayFromBox, _getBoxFromPointArray } from "../../../../../hooks/helper";
import { useNewTrackCreationPreview } from "../../../../../hooks/useNewTrackCreationPreview";
import { useReadDetections, ReadableDetection } from "../../../../../hooks/useReadDetections";
import { DetectionPosition, useTrackPreview } from "../../../../../hooks/useTrackPreview";
import { useTracks } from "../../../../../hooks/useTracks";
import {
    Point,
    Box,
    DetectionClassName,
    isBox
} from "../../../../../recoil/framesDetectionsCoordinates.atom";
import { trackEditorAtom } from "../../../../../recoil/trackEditor.atom";
import { PreviewCard } from "../../../../sidebar/cards/components/PreviewCard";
import { useCards } from "../../../../sidebar/cards/hook/useCards";
import { useShapeDisabled } from "../../hooks/useShapeDisabled";
import { HeadDetection } from "../shapes/HeadDetection";
import { OtherDetection } from "../shapes/OtherDetection";
import { PlateDetection } from "../shapes/PlateDetection";

export const ReadableDetections: React.FC<{ isDrawing: boolean }> = (props) => {
    const readableDetections = useReadDetections();
    const [, { addCard }] = useCards();
    const [trackPreview, { addTrackPreview, updateTrackPreview, updateRangesPreview }] =
        useTrackPreview();
    const [t, { updateTrackActive, updateTrack, addTrack }] = useTracks();
    const [newTrack, { removeNewTrackCreationPreview }] = useNewTrackCreationPreview();
    const setTrackEditorState = useSetRecoilState(trackEditorAtom);
    const shapeDisabled = useShapeDisabled() || props.isDrawing;

    const handleClickDetection = (data: ReadableDetection) => {
        if (props.isDrawing) return;
        const currentTrack = t.tracks.find((d) => d.trackId === data.trackId);
        if (!currentTrack)
            throw new Error(
                `FC ReadableDetections: method "handleClickDetection" No track found with id ${data.trackId}`
            );

        // if we click on the current preview detection / activate deactivate preview
        if (trackPreview && trackPreview.trackId === data.trackId) {
            updateTrackPreview({ active: !trackPreview.active });
            return;
        }

        // deactive clicked track
        updateTrackActive(currentTrack.trackId);

        // update track preview if we click on a other detections
        if (trackPreview) {
            updateTrack();
        }

        // if there was already a creation card opened we register it
        if (newTrack) {
            addTrack();
            removeNewTrackCreationPreview();
        }
        // add track preview
        addTrackPreview(currentTrack.trackId, { active: !currentTrack.active });

        // create a random id for the card
        const id = `${Math.random()}`;
        // add card to cards array
        addCard({
            id: id,
            closeOnNewEntry: true,
            element: <PreviewCard id={id} />,
            open: true
        });
        // open track editor ranges in controls view
        setTrackEditorState({
            min: currentTrack.frameStart,
            max: currentTrack.frameEnd,
            onChangeMin: (value) => {
                updateTrackPreview({
                    frameStart: value
                });
            },
            onChangeMax: (value) => {
                updateTrackPreview({
                    frameEnd: value
                });
            },
            onReleaseMax: (value) => {
                updateRangesPreview("max", value);
            },
            onReleaseMin: (value) => {
                updateRangesPreview("min", value);
            }
        });
    };

    const handleGestureEnd = (trackData: ReadableDetection) => (newPosition: Box | Point[]) => {
        const currentTrack = t.tracks.find((d) => d.trackId === trackData.trackId);
        if (!currentTrack)
            throw new Error(
                `FC ReadableDetections: method "handleClickDetection" No track found with id ${trackData.trackId}`
            );
        // if there was a track preview we update the track with the preview data
        if (trackPreview) {
            updateTrack();
        }
        // add detection handled to preview atom
        if (isBox(newPosition)) {
            let ldm: Point[] = [];
            if (trackData.cn === DetectionClassName.Plate) {
                ldm = _getArrayFromBox(newPosition);
            }
            const positions: DetectionPosition = {
                _type: "detection-position",
                box: newPosition,
                ldm
            };
            addTrackPreview<DetectionPosition & { isActive: boolean }>(trackData.trackId, {
                ...positions,
                isActive: currentTrack.active
            });
        } else {
            const box = _getBoxFromPointArray(newPosition);
            const positions: DetectionPosition = {
                _type: "detection-position",
                box: box,
                ldm: newPosition
            };
            addTrackPreview<DetectionPosition & { isActive: boolean }>(trackData.trackId, {
                ...positions,
                isActive: currentTrack.active
            });
        }
        // create a random id for the card
        const id = `${Math.random()}`;
        // add card to cards array
        addCard({
            id: id,
            closeOnNewEntry: true,
            element: <PreviewCard id={id} />,
            open: true
        });
        // open track editor ranges in controls view
        setTrackEditorState({
            min: currentTrack.frameStart,
            max: currentTrack.frameEnd,
            onChangeMin: (value) => {
                updateTrackPreview({
                    frameStart: value
                });
            },
            onChangeMax: (value) => {
                updateTrackPreview({
                    frameEnd: value
                });
            },
            onReleaseMax: (value) => {
                updateRangesPreview("max", value);
            },
            onReleaseMin: (value) => {
                updateRangesPreview("min", value);
            }
        });
    };
    const renderHeadDetection = (dtc: ReadableDetection, index: number) => {
        return (
            <HeadDetection
                disabled={shapeDisabled}
                cn={DetectionClassName.Head}
                box={dtc.box}
                key={index}
                mode={dtc.active ? "active" : "inactive"}
                onDragEnd={handleGestureEnd(dtc)}
                onTransformEnd={handleGestureEnd(dtc)}
                handleClick={() => handleClickDetection(dtc)}
                // onDetectionHoverLeave={() => handleDetectionHoverLeave(dtc.trackId)}
                // onDetectionHover={() => handleDetectionHover(dtc.trackId)}
            />
        );
    };

    const renderPlateDetection = (dtc: ReadableDetection, index: number) => {
        return (
            <PlateDetection
                mode={dtc.active ? "active" : "inactive"}
                disabled={shapeDisabled}
                cn={DetectionClassName.Plate}
                box={dtc.box}
                ldm={dtc.ldm}
                key={index}
                onTransformEnd={handleGestureEnd(dtc)}
                onDragEnd={handleGestureEnd(dtc)}
                handleClick={() => handleClickDetection(dtc)}
                // onDetectionHoverLeave={() => handleDetectionHoverLeave(dtc.trackId)}
                // onDetectionHover={() => handleDetectionHover(dtc.trackId)}
            />
        );
    };

    const renderOtherDetection = (dtc: ReadableDetection, index: number) => {
        return (
            <OtherDetection
                key={index}
                ldm={dtc.ldm}
                cn={DetectionClassName.Other}
                mode={dtc.active ? "active" : "inactive"}
                disabled={shapeDisabled}
                handleClick={() => handleClickDetection(dtc)}
                // onDetectionHover={() => handleDetectionHover(dtc.trackId)}
                onDragEnd={handleGestureEnd(dtc)}
            />
        );
    };

    return (
        <>
            {readableDetections.map((dtc, index) => {
                switch (dtc.cn) {
                    case "head":
                        return renderHeadDetection(dtc, index);
                    case "plate":
                        return renderPlateDetection(dtc, index);
                    case "other":
                        return renderOtherDetection(dtc, index);
                }
            })}
        </>
    );
};
