import { ReactComponent as RoundLoaderAnimated } from "../../static/icons/round-loader-animated.svg";

const RoundedLoaderAnimated = ({ isLoading = false, size = 30, margin = "auto" }) => {
    if (!isLoading) {
        return null;
    }

    return (
        <RoundLoaderAnimated
            className="rounded-loader-animated"
            height={size}
            width={size}
            style={{ margin }}
        />
    );
};

export default RoundedLoaderAnimated;
