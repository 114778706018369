import { useContext, useState } from "react";

import { AxiosRequestConfig, AxiosResponse } from "axios";
import { useHistory } from "react-router";

import { instance } from "../../api/axios";
import { socketContext } from "../../context/socketContext";

const apiUrl = process.env.REACT_APP_API_URL as string;

interface IResponse {
    loading: boolean;
    error: any;
    data: any;
}

//Hook to use axios
export const useAxios = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [response, setResponse] = useState<AxiosResponse | null>(null);
    const { push } = useHistory();
    const socketStore = useContext(socketContext);

    //get data
    const getData = async (
        url: string | null,
        route: string,
        config?: AxiosRequestConfig
    ): Promise<IResponse> => {
        if (!config) {
            config = {};
        }
        if (!url) {
            url = apiUrl;
        }
        try {
            setLoading(true);
            setError(false);
            setResponse(null);
            const { data } = await instance.get(`${url}/${route}`, config);
            setLoading(false);
            setError(false);
            setResponse(data);
            return {
                data,
                error,
                loading
            };
        } catch (e) {
            setLoading(false);
            setError(true);
            setResponse(null);

            if ((e as any)?.response?.status === 401) {
                await socketStore?.disconnectSocket();
                push("/login");
            }
            return {
                data: null,
                error: e,
                loading
            };
        }
    };

    //post data
    const postData = async (
        url: string | null,
        route: string,
        params?: any,
        config?: AxiosRequestConfig
    ): Promise<IResponse> => {
        if (!config) {
            config = {};
        }
        if (!url) {
            url = apiUrl;
        }
        try {
            setLoading(true);
            setError(false);
            setResponse(null);
            const { data } = await instance.post(`${url}/${route}`, params, config);
            setLoading(false);
            setError(false);
            setResponse(data);
            return {
                data,
                error,
                loading
            };
        } catch (e) {
            if ((e as any)?.response?.status === 401) {
                await socketStore?.disconnectSocket();
                push("/login");
            }
            setLoading(false);
            setError(true);
            setResponse(null);
            throw e;
        }
    };

    //post data
    const putData = async (
        url: string | null,
        route: string,
        params?: any,
        config?: AxiosRequestConfig
    ): Promise<IResponse> => {
        if (!config) {
            config = {};
        }
        if (!url) {
            url = apiUrl;
        }

        try {
            setLoading(true);
            setError(false);
            setResponse(null);
            const { data } = await instance.put(`${url}/${route}`, params, config);
            setLoading(false);
            setError(false);
            setResponse(data);
            return {
                data,
                error,
                loading
            };
        } catch (e) {
            if ((e as any)?.response?.status === 401) {
                await socketStore?.disconnectSocket();
                push("/login");
            }
            setLoading(false);
            setError(true);
            setResponse(null);
            throw e;
        }
    };
    return { error, loading, response, postData, getData, putData };
};
